<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-row class="base-content-top-margin">
        <ion-col size-md="8" offset-md="2">
          <div class="center-element">
            <ion-card>
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Datenschutzerklärung für die HUBGrade App</h2>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col>
                    <h3><strong>VERANTWORTLICHKEIT</strong></h3>
                    <p>Im Folgenden möchten wir Sie über den Umgang mit Ihren Daten im Zusammenhang mit der Nutzung der
                      HUBGrade
                      App
                      (nachfolgend „App“ genannt) informieren. Wir haben technische und organisatorische Maßnahmen
                      getroffen, die
                      sicherstellen, dass die Vorschriften über den Datenschutz sowohl von uns als auch von externen
                      Dienstleistern
                      beachtet werden. Verantwortlich für die Datenerhebung, Datenverarbeitung und Datennutzung ist die
                    </p>
                    <p><strong>Zentralstelle für die Weiterbildung im Handwerk e.V. (ZWH)</strong></p>
                    <p>Geschäftsführer: Sebastian Knobloch</p>
                    <p>Sternwartstraße 27–29</p>
                    <p>40223 Düsseldorf</p>
                    <p><a aria-label="ZWH anrufen" href="tel:+492113020090">+49 211 302009-0</a></p>
                    <p><a aria-label="ZWH mailen" href="mailto:info@zwh.de">info&#64;zwh.de</a></p>
                    <p>(nachfolgend „wir“ genannt).</p>
                    <h3><strong>DATENSCHUTZBEAUFTRAGTE</strong></h3>
                    <p>Stefanie Gloede</p>
                    <p><a aria-label="Stefanie Gloede anrufen" href="tel:+4921130200914">+49 211 302009-14</a></p>
                    <p><a aria-label="Stefanie Gloede mailen" href="mailto:datenschutz@zwh.de">datenschutz&#64;zwh.de</a>
                    </p>
                    <h3><strong>DATENVERARBEITUNG BEI NUTZUNG DER HUBGRADE APP</strong></h3>
                    <p>Bei Zugriff auf die App und bei Abruf einer Datei/ der Daten werden über diesen Vorgang Daten
                      erhoben
                      und in einer Protokolldatei gespeichert, was ausschließlich internen systembezogenen und
                      statistischen
                      Zwecken dient.</p>
                    <p>Der Inhalt der Protokolldatei wird für 5 Tage gespeichert. In der Protokolldatei werden folgende
                      Daten erfasst:</p>
                    <ul>
                      <li>IP-Adresse des anfragenden Endgeräts</li>
                      <li>Datum und Uhrzeit der Serveranfrage</li>
                      <li>Größe der übertragenen Daten</li>
                      <li>Ziel-Domain</li>
                    </ul>
                    <p>Die Erhebung der Zugriffsdaten ist technisch notwendig, um Ihrem Endgerät den Aufruf unserer App
                      zu
                      ermöglichen. Die Datenverarbeitung beruht auf Artikel 6 Abs. 1 f) DSGVO. Unser berechtigtes
                      Interesse
                      besteht
                      darin, Ihnen eine technisch einwandfrei lauffähige App bereitzustellen. Eine Weitergabe der Daten
                      an
                      Dritte
                      findet nicht statt.</p>
                    <p>Nutzende können folgende personenbezogene Daten hinterlegen:</p>
                    <ul>
                      <li>Vorname</li>
                      <li>Nachname</li>
                      <li>E-Mail-Adresse</li>
                      <li>ggf. Dokumente, die personenbezogene Daten enthalten</li>
                    </ul>
                    <p>Bei Dokumentenverifizierung und Kursbuchungen können die von Ihnen autorisierten Kammern bzw.
                      anderen
                      Organisationen auf die hinterlegten personenbezogenen Daten zurückgreifen. Das Hinterlegen der
                      Daten
                      erfolgt
                      auf freiwilliger Basis, diese können jederzeit beliebig von der jeweiligen nutzenden Person
                      angepasst
                      oder
                      gelöscht werden.</p>
                    <h3><strong>COOKIES</strong></h3>
                    <p>Wir verwenden auf unserer App Session Storage (Sitzungsspeicher) und Local Storage (Lokaler
                      Speicher).
                      Diese
                      sind kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Der Session Storage leert sich
                      nach
                      jeder
                      Sitzung (Schließung des Browserfensters) und der Local Storage leert sich, sobald Sie Ihren
                      Browser-Cache
                      leeren. Eine Zusammenführung mit etwaigen von Ihnen zur Verfügung gestellten personenbezogenen
                      Daten
                      erfolgt
                      nicht.</p>
                    <p>Wenn Sie ein Profil anlegen, werden die von Ihnen angegebenen personenbezogenen Daten in unserer
                      Datenbank
                      für den Existenzzeitraum Ihres Profils sowie der App gespeichert.
                      Bei der Nichtannahme von Local Storage sowie Nichtanlegen eines Profils ist eine Nutzung unserer
                      App
                      dennoch
                      möglich – allerdings eingeschränkt.</p>
                    <h3><strong>RECHT AUF AUSKUNFT, LÖSCHUNG, SPERRUNG</strong></h3>
                    <p>Sie haben jederzeit das Recht, kostenfrei Auskunft über die bei uns über Sie gespeicherten Daten
                      zu
                      erhalten,
                      unrichtige Daten korrigieren sowie Daten sperren oder löschen zu lassen. Die Kontodaten werden
                      nach
                      Löschung
                      der nutzenden Person nach 30 Tagen aus unserer Datenbank gelöscht. Ferner haben Sie das Recht,
                      Ihre
                      Daten in
                      einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und Ihre Daten durch uns
                      auf
                      jemand
                      anderen übertragen zu lassen.</p>
                    <p>Bitte richten Sie Ihr jeweiliges Anliegen per E-Mail an</p>
                    <p>Herrn Michael Trommen</p>
                    <p><a aria-label="Michael Trommen mailen" href="mailto:mtrommen@zwh.de">mtrommen&#64;zwh.de</a></p>
                    <p><a aria-label="Michael Trommen anrufen" href="tel:+49211302009758">+49 211 302009-758</a></p>
                    <p>Neben den zuvor genannten Rechten haben Sie außerdem das Recht, sich bei einer
                      Datenschutzaufsichtsbehörde
                      zu
                      beschweren.</p>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
