import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-web',
  templateUrl: './header-web.component.html',
  styleUrls: ['./header-web.component.scss'],
})
export class HeaderWebComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
