<ion-img src="./../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation" *ngIf="
isPhotoEditLoading === true ||
areInstitutesLoading === true"></ion-img>
<div (click)="cancel($event)" class="modal-div">
  <ion-row class="background-color-bright-blue-2">
    <ion-col class="margin-padding-0" size="2" offset="10">
      <ion-button expand="block" (click)="cancel($event)" class="close-button margin-padding-0">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-item *ngIf="hasRequestFailed === true" lines="none" class="error-text">
    {{standardErrorText}}
  </ion-item>
  <ion-row class="ion-margin-bottom ion-margin-top">
    <ion-col>
      <ion-card-content class="ion-text-start margin-padding-0">
        <ion-row>
          <ion-row class="width-100-percent height-100-percent ion-margin-start ion-margin-end">
            <ion-img id="document-image" [src]="base64Image">
            </ion-img>
          </ion-row>
        </ion-row>
      </ion-card-content>
    </ion-col>
  </ion-row>
  <ion-footer class="text-white">
    <ion-row class="ion-margin-top" *ngIf="previewImage?.verificationStatus !== verificationStatus.none">
      <ion-col offset="1" size="10">
        <strong>Verifizierung durch:</strong>&nbsp;{{ previewImage?.verificationInstitute.name }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col offset="1" size="10">
        <ion-img *ngIf="previewImage?.verificationStatus === verificationStatus.none"
          src="./../../../assets/ui-icons/icon-verification-none-timeline-v1-0-0.svg"></ion-img>
        <ion-img class="verification-icon" *ngIf="previewImage?.verificationStatus === verificationStatus.declined"
          src="./../../../assets/ui-icons/icon-verification-denied-timeline-v1-0-0.svg"></ion-img>
        <ion-img class="verification-icon" *ngIf="previewImage?.verificationStatus === verificationStatus.verified"
          src="./../../../assets/ui-icons/icon-verification-accepted-timeline-v1-0-0.svg"></ion-img>
        <ion-img class="verification-icon" *ngIf="previewImage?.verificationStatus === verificationStatus.pending"
          src="./../../../assets/ui-icons/icon-verification-wip-timeline-v1-0-0.svg"></ion-img>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-bottom">
      <ion-col offset="1" size="5">
        <ion-button class="verify-button" *ngIf="previewImage?.verificationStatus === verificationStatus.none"
          (click)="requestVerificationClick(previewImage)">
          Verifizieren
        </ion-button>
        <ion-button class="verify-button" *ngIf="previewImage?.verificationStatus === verificationStatus.pending"
          (click)="undoSendButtonClick()">
          Zurückziehen
        </ion-button>
      </ion-col>
      <ion-col size="5" class="display-grid ion-justify-content-end">
        <ion-button class="delete-button" (click)="deleteImageButtonClick(previewImage)">
          <strong>Löschen</strong>&nbsp;<ion-icon name="trash-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</div>
