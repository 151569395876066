<ion-row *ngIf="courseHelper.canCourseTemplateBeRated(courseTemplate, userCourseRegistrations)"
  class="ion-margin-start ion-align-items-center">
  <ng-container *ngIf="courseTemplate.wasRatingSent === true">
    <ion-text class="highlighted-bold-text ion-margin-start">Vielen Dank für deine Bewertung!</ion-text>
  </ng-container>
  <ng-container *ngIf="courseTemplate.wasRatingSent !== true">
    <ion-img aria-label="1 Stern Bewertung setzen" class="rating-icon icon-color-blue {{
      courseTemplate.temporaryRating >= 1 ?
      'icon-star-filled' :
      'icon-star-outline'}}" (mouseenter)="onMouseEnterRatingStar(courseTemplate, 1)"
      (mouseleave)="onMouseLeaveRatingStar(courseTemplate)"
      (click)="onClickRatingStar(courseTemplate, 1)"></ion-img>
    <ion-img aria-label="2 Stern Bewertung setzen" class="rating-icon icon-color-blue {{courseTemplate.temporaryRating >= 2 ?
      'icon-star-filled' :
      'icon-star-outline'}}" (mouseenter)="onMouseEnterRatingStar(courseTemplate, 2)"
      (mouseleave)="onMouseLeaveRatingStar(courseTemplate)"
      (click)="onClickRatingStar(courseTemplate, 2)"></ion-img>
    <ion-img aria-label="3 Stern Bewertung setzen" class="rating-icon icon-color-blue {{courseTemplate.temporaryRating >= 3 ?
      'icon-star-filled' :
      'icon-star-outline'}}" (mouseenter)="onMouseEnterRatingStar(courseTemplate, 3)"
      (mouseleave)="onMouseLeaveRatingStar(courseTemplate)"
      (click)="onClickRatingStar(courseTemplate, 3)"></ion-img>
    <ion-img aria-label="4 Stern Bewertung setzen" class="rating-icon ion-margin-end icon-color-blue {{courseTemplate.temporaryRating >= 4 ?
      'icon-star-filled' :
      'icon-star-outline'}}" (mouseenter)="onMouseEnterRatingStar(courseTemplate, 4)"
      (mouseleave)="onMouseLeaveRatingStar(courseTemplate)"
      (click)="onClickRatingStar(courseTemplate, 4)"></ion-img>
    <ion-button [disabled]="courseTemplate.rating === undefined" class="margin-padding-0"
      (click)="onClickSendRatingButton(courseTemplate)">
      <ion-icon aria-label="Absenden der Bewertung" name="send"></ion-icon>
    </ion-button> <ion-text class="beta-icon ion-margin-start">beta</ion-text>
  </ng-container>
</ion-row>
