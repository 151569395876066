import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Directive({
  selector: '[appScrollPosition]',
})
export class ScrollPositionDirective {
  @Input() content: IonContent;
  @Input() hasSearched: boolean;
  @Output() isScrollerAtBottom = new EventEmitter<boolean>();

  private isAtBottom = false;

  constructor() {}

  @HostListener('ionScroll', ['$event'])
  async checkForScrollerAtBottom(event): Promise<void> {
    if (this.hasSearched === false) {
      return;
    }
    const fu = await this.content.getScrollElement();
    const bar = fu.scrollHeight;

    if (event.detail.scrollTop + window.innerHeight >= bar) {
      this.isScrollerAtBottom.emit(true);
    }
    return;
  }
}
