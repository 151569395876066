import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appKeypress]',
})
export class ArrowkeyPressDirective {
  @Output() arrowKey = new EventEmitter<string>();

  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        this.arrowKey.emit('ArrowUp');
        break;
      case 'ArrowDown':
        this.arrowKey.emit('ArrowDown');
        break;
      case 'ArrowLeft':
        this.arrowKey.emit('ArrowLeft');
        break;
      case 'ArrowRight':
        this.arrowKey.emit('ArrowRight');
        break;
      default:
        break;
    }
  }
}
