import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonHelper } from 'app/helpers/json-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { ChatBotMessage, MessageType } from 'app/models/chat-bot-message.model';
import { COLOR_NAMES } from 'app/models/constants-app-values';
import { CHATBOT_OPENING_QUESTION } from 'app/models/constants-chatbot';
import {
  ACTIVE_CHAT_SESSION_LOGICAL_NAME,
  IS_USER_AGREEMENT_AGREED_LOGICAL_NAME,
} from 'app/models/constants-logical-names';
import { STANDARD_ERROR_CODE, STANDARD_ERROR_TEXT } from 'app/models/constants-status';
import { TabsComponent } from '../tabs/tabs.component';
import { ChatBotCommunicationService } from './../../../services/chat-bot-communication.service';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent implements OnInit, OnChanges {
  @Input() isChatOpen = false;
  public userInput = '';
  public activeChatSession: ChatBotMessage[] = [];
  public messageType: typeof MessageType = MessageType;
  public currentChatBotTab: string = this.messageType.BotMessage;
  public areSystemNotificationsUnread: boolean = false;
  public isUserAgreementAgreed = false;
  public isLoggedIn = false;

  public chatForm: FormGroup = this.formBuilder.group({
    userInputChat: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
  });

  private isChatLoaded = false;

  constructor(
    public tabsComponent: TabsComponent,
    private chatBotCommunicationService: ChatBotCommunicationService,
    private formBuilder: FormBuilder,
    private jsonHelper: JsonHelper,
    private storageHelper: StorageHelper,
    private userHelper: UserHelper,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    this.isUserAgreementAgreed = await this.storageHelper.getSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME);
    if (this.isUserAgreementAgreed === true || this.isLoggedIn === true) {
      this.storageHelper.setSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME, true);
      this.isUserAgreementAgreed = true;
      this.connectToBot();
    }
  }

  async ngOnChanges() {
    this.areSystemNotificationsUnread = this.storageHelper.getSessionStorage('areSystemNotificationsUnread');
    if (this.currentChatBotTab === this.messageType.SystemMessage) {
      this.areSystemNotificationsUnread = false;
      this.storageHelper.setSessionStorage('areSystemNotificationsUnread', false);
    }
    if (this.isChatOpen === false) {
      this.closeChat();
      return;
    }
    this.isLoggedIn = await this.userHelper.checkForUser();
    this.isUserAgreementAgreed = await this.storageHelper.getSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME);
    if (this.isUserAgreementAgreed !== true && this.isLoggedIn !== true) {
      setTimeout(() => this.askPermissionToConnect(), 100);
      return;
    }
    setTimeout(() => this.loadChatSession(), 100);
  }

  public askPermissionToConnect(): void {
    this.receiveBotMessage(CHATBOT_OPENING_QUESTION);
  }

  public async yesButtonClicked(): Promise<void> {
    this.clearChat();
    this.storageHelper.setSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME, true);
    this.isUserAgreementAgreed = true;
    await this.connectToBot();
  }

  public changeChatBotTab(event: any): void {
    if (event === this.messageType.SystemMessage) {
      this.areSystemNotificationsUnread = false;
      this.storageHelper.setSessionStorage('areSystemNotificationsUnread', false);
    }
    if (this.currentChatBotTab === event) {
      return;
    }
    this.currentChatBotTab = event;
    setTimeout(() => this.loadChatSession(), 100);
  }

  public loadChatSession(): void {
    let activeChatSession = this.storageHelper.getSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME);
    activeChatSession = activeChatSession === null ? [] : activeChatSession;
    for (const chatMessageItem of activeChatSession) {
      if (chatMessageItem.messageType === this.messageType.UserMessage) {
        this.sendMessage(chatMessageItem.text);
      }
      if (chatMessageItem.messageType === this.messageType.BotMessage) {
        this.receiveBotMessage(chatMessageItem.text);
      }
      if (chatMessageItem.messageType === this.messageType.SystemMessage) {
        this.writeSystemMessage(chatMessageItem.text);
      }
    }
    this.activeChatSession = activeChatSession;
    this.isChatLoaded = true;
  }

  public closeChat(): void {
    this.storageHelper.setSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME, this.activeChatSession);
    this.isChatLoaded = false;
    this.tabsComponent.isChatOpen = false;
    const navBar = document.getElementById('tab-button-chat');
    navBar?.classList.remove('tab-activated');
  }

  public changeUserInput(userInput: any): void {
    this.userInput = userInput;
  }

  public receiveSystemMessage(message: ChatBotMessage) {
    let activeChatSession: ChatBotMessage[] = [];

    activeChatSession = this.storageHelper.getSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME);
    if (activeChatSession.find((x) => x.text === message.text) !== undefined) {
      return;
    }

    activeChatSession = activeChatSession === null ? [] : activeChatSession;

    activeChatSession.push({
      text: message.text,
      messageType: MessageType.SystemMessage,
    });

    this.storageHelper.setSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME, activeChatSession);

    if (this.currentChatBotTab !== MessageType.SystemMessage || this.isChatOpen === false) {
      this.areSystemNotificationsUnread = true;
      this.storageHelper.setSessionStorage('areSystemNotificationsUnread', true);
      this.tabsComponent.triggerPulseAnimation('chatbot-pulse', COLOR_NAMES.magenta);
    }
    return;
  }

  public receiveSystemNotification(message: ChatBotMessage) {
    let activeChatSession: ChatBotMessage[] = [];

    activeChatSession = this.storageHelper.getSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME);

    if (activeChatSession.find((x) => x === message) !== undefined) {
      return;
    }

    activeChatSession = activeChatSession === null ? [] : activeChatSession;

    activeChatSession.push({
      text: message.text,
      messageType: MessageType.SystemMessage,
    });

    this.storageHelper.setSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME, activeChatSession);
    this.tabsComponent.toggleChat();

    if (this.currentChatBotTab !== MessageType.SystemMessage || this.isChatOpen === false) {
      this.areSystemNotificationsUnread = true;
      this.storageHelper.setSessionStorage('areSystemNotificationsUnread', true);
      this.tabsComponent.triggerPulseAnimation('chatbot-pulse', COLOR_NAMES.magenta);
    }
    return;
  }

  public triggerChatSend(): void {
    if (this.chatForm.valid === true) {
      this.sendMessage(this.userInput);
      this.chatBotCommunicationService.sendUserMessageToBot(this.userInput);
      this.clearUserInput();
      return;
    }
  }

  private clearChat(): void {
    document.getElementById('chat-output').innerHTML = '';
  }

  private async connectToBot(): Promise<void> {
    const botConnectionResult = await this.chatBotCommunicationService.setBotConnection();
    if (botConnectionResult === STANDARD_ERROR_CODE) {
      console.error(STANDARD_ERROR_TEXT);
    }

    this.chatBotCommunicationService.botConnection.on('bot_uttered', (response) => {
      if (this.storageHelper.getSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME)?.length === 0) {
        this.activeChatSession.push({ text: response.text, messageType: this.messageType.BotMessage });
        this.storageHelper.setSessionStorage(ACTIVE_CHAT_SESSION_LOGICAL_NAME, this.activeChatSession);
        return;
      }
      this.receiveBotMessage(response.text);
    });
  }

  private scrollChatDown(): void {
    const chatOutput = document.getElementById('chat-output');

    const scrollOptions: ScrollToOptions = {
      top: chatOutput?.scrollHeight,
      behavior: 'smooth',
    };

    chatOutput?.scrollTo(scrollOptions);
  }

  private receiveBotMessage(botMessage: string): void {
    if (this.jsonHelper.isStringJson(botMessage)) {
      const responseArray = JSON.parse(botMessage);
      let responseArrayMessage = '';

      for (const responseItem of responseArray) {
        if ('cmd' in responseItem === true) {
          this.connectToBot();
          break;
        }

        responseArrayMessage = `${responseArrayMessage}-<a aria-label="Zum Kurs ${responseItem.name}" style="color: white;" href="#/kurs/${responseItem.id}">${responseItem.name}</a> <br />`;
      }
      this.writeBotMessage(responseArrayMessage);

      return;
    }

    this.writeBotMessage(botMessage);
  }

  private writeSystemMessage(systemMessage): void {
    if (this.isChatLoaded === true) {
      this.activeChatSession.push({ text: systemMessage, messageType: this.messageType.SystemMessage });
    }

    const systemMessageMarkup = `
      <ion-row>
        <ion-col size="1" style="display: grid; align-content: center;">
          <ion-icon style="color: var(--magenta); font-size: 16pt;" name="alert-outline"></ion-icon>
        </ion-col>
        <ion-col size="10">
          <ion-card style="float:left; border-radius: 10px 10px 10px 1px; max-width:100%;
            background-color: var(--blue); color: var(--white);">
            <p style="margin: 10px;">
              ${systemMessage}
            </p>
          </ion-card>
        </ion-col>
      </ion-row>
    `;

    document.getElementById('system-output')?.insertAdjacentHTML('beforeend', systemMessageMarkup);

    setTimeout(this.scrollChatDown, 50);
  }

  private writeBotMessage(botMessage): void {
    if (this.isChatLoaded === true) {
      this.activeChatSession.push({ text: botMessage, messageType: this.messageType.BotMessage });
    }

    const botMessageMarkup = `
      <ion-row>
        <ion-col>
          <ion-card style="float:left; border-radius: 10px 10px 10px 1px; max-width:70%;
            background-color: var(--blue); color: var(--white);">
            <p style="margin: 10px;">
              ${botMessage}
            </p>
          </ion-card>
        </ion-col>
      </ion-row>
    `;

    document.getElementById('chat-output')?.insertAdjacentHTML('beforeend', botMessageMarkup);

    setTimeout(this.scrollChatDown, 50);
  }

  private sendMessage(userMessage: string): void {
    if (this.isChatLoaded === true) {
      this.activeChatSession.push({ text: userMessage, messageType: this.messageType.UserMessage });
    }

    const userMessageMarkup = `
      <ion-row>
        <ion-col>
          <ion-card style="float:right; border-radius: 10px 10px 1px 10px; max-width:70%;">
            <p style="margin: 10px;">
              ${userMessage}
            </p>
          </ion-card>
        </ion-col>
      </ion-row>
    `;

    document.getElementById('chat-output')?.insertAdjacentHTML('beforeend', userMessageMarkup);
  }

  private clearUserInput(): void {
    this.userInput = '';
    this.chatForm.get('userInputChat').setValue('');

    setTimeout(this.scrollChatDown, 50);
  }
}
