import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ConfirmationModalComponent } from 'app/components/layout/confirmation-modal/confirmation-modal.component';
import { UserFileComponent } from 'app/components/user-file/user-file.component';
import { ObjectConversionHelper } from 'app/helpers/object-conversion.helper';
import { UserHelper } from 'app/helpers/user-helper';
import { GENDER_VALUES, VERIFICATION_STATUS } from 'app/models/constants-app-values';
import { STANDARD_ERROR_CODE, STANDARD_ERROR_TEXT, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { PreviewImage } from 'app/models/preview-image.model';
import { User } from 'app/models/user.model';
import { CameraService } from 'app/services/camera.service';
import { DeleteUserService } from 'app/services/delete-user.service';
import { GetUserFilesService } from 'app/services/get-user-files.service';
import { UserFileUploadService } from 'app/services/user-file-upload.service';
import { UpdateUserService } from './../../../services/update-user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePage implements OnInit {
  protected readonly GENDER_VALUES = GENDER_VALUES;
  public formGroup = this.formBuilder.group({
    firstNameInput: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
    lastNameInput: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
    emailInput: ['', [Validators.required, Validators.email, Validators.minLength(6), Validators.maxLength(255)]],
    genderInput: ['', [Validators.required]],
  });
  public user: User = null;
  public arePreviewImagesLoading = false;
  public hasCameraFailed = false;
  public hasRequestFailed = false;
  public isUpdateUserLoading = false;
  public isInEditMode = false;
  public isEditButtonCooldown = false;
  public isPhotoEditLoading = false;
  public isSelectedImageLoading = false;
  public isLoggedIn = false;
  public previewImages: PreviewImage[] = [];
  public readonly standardErrorText = STANDARD_ERROR_TEXT;
  public readonly verificationStatus = VERIFICATION_STATUS;

  constructor(
    private cameraService: CameraService,
    private deleteUserService: DeleteUserService,
    private formBuilder: FormBuilder,
    private getUserFileService: GetUserFilesService,
    private modalCtrl: ModalController,
    private objectConversionHelper: ObjectConversionHelper,
    private updateUserService: UpdateUserService,
    private userFileUploadService: UserFileUploadService,
    private userHelper: UserHelper,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
      this.loadPreviewImages();
    }
  }

  async ionViewWillEnter() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
      this.loadPreviewImages();
    }
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public getCurrentUserGenderName(): string {
    return GENDER_VALUES.find((x) => x.id === this.user.genderId).name;
  }

  public async safeButtonClick(): Promise<void> {
    this.isUpdateUserLoading = true;
    const updatedUserData = new User();
    updatedUserData.firstName = this.formGroup.get('firstNameInput').value;
    updatedUserData.lastName = this.formGroup.get('lastNameInput').value;
    updatedUserData.email = this.formGroup.get('emailInput').value;
    updatedUserData.genderId = parseInt(this.formGroup.get('genderInput').value);
    const updateUserResult = await this.updateUserService.updateUser(this.user, updatedUserData);

    if (updateUserResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isUpdateUserLoading = false;
      throw Error(this.getUserFileService.errorMessage);
    }
    this.isUpdateUserLoading = false;
    this.editButtonClick();
    this.user.firstName = updatedUserData.firstName;
    this.user.lastName = updatedUserData.lastName;
    this.user.email = updatedUserData.email;
    this.user.genderId = updatedUserData.genderId;
    await this.userHelper.updateUserData(this.user);
  }

  public isUserEditValid(): boolean {
    return this.formGroup.valid || this.isEditButtonCooldown;
  }

  public editButtonClick(): void {
    this.isEditButtonCooldown = true;
    this.isInEditMode = !this.isInEditMode;
    setTimeout(() => {
      this.isEditButtonCooldown = false;
    }, 500);
    if (this.isInEditMode === true) {
      this.formGroup.controls.firstNameInput.setValue(this.user.firstName);
      this.formGroup.controls.lastNameInput.setValue(this.user.lastName);
      this.formGroup.controls.emailInput.setValue(this.user.email);
      this.formGroup.controls.genderInput.setValue(this.user.genderId.toString());
      this.changeDetectorRef.detectChanges();
    }
  }

  public deleteProfileLinkClicked(): void {
    this.openConfirmationModalProfileDeletion();
  }

  public async cameraButtonClick(): Promise<void> {
    const photoResult = await this.cameraService.takePhoto();
    if (photoResult === STANDARD_ERROR_CODE) {
      this.hasCameraFailed = true;
      console.error('Beim Aufnehmen des Fotos ist etwas schiefgelaufen.');
    }

    const photo = this.cameraService.photo;
    const base64 = photo.base64String;
    const contentType = photo.format;

    const imageFile = this.objectConversionHelper.base64ToFile(
      base64,
      contentType,
      this.user.firstName + this.user.lastName + Date.now(),
    );

    await this.uploadPhoto(imageFile);
    this.loadSelectedImage(this.previewImages[0]);
    this.hasCameraFailed = false;
  }

  public pressLogoutButton(): void {
    this.isLoggedIn = this.userHelper.logoutUser();
  }

  public async loadSelectedImage(previewImage: PreviewImage): Promise<void> {
    this.isSelectedImageLoading = true;

    const selectedImageResult = await this.getUserFileService.getUserFile(this.user, previewImage);
    if (selectedImageResult === 401) {
      this.isSelectedImageLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (selectedImageResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isSelectedImageLoading = false;
      throw Error(this.getUserFileService.errorMessage);
    }

    const selectedImage = this.objectConversionHelper.blobToFile(selectedImageResult as File, 'Platzhaltername');
    const fileType = selectedImage.type;
    this.hasRequestFailed = false;
    this.isSelectedImageLoading = false;
    this.showImageModal(
      `data:image/${{ fileType }};base64,` + (await this.objectConversionHelper.fileToBase64(selectedImage)),
      previewImage,
    );
  }

  private async openConfirmationModalProfileDeletion(): Promise<string> {
    const modal = await this.modalCtrl.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-modal',
      componentProps: {
        enableBackdropDismiss: true,
        action: 'löschen',
        objectToRemove: 'dein Profil',
        locationWhereObjectIsToBeRemoved: 'Hubgrade',
        toggleQuestionText: 'Profil aus allen ZWH-Plattformen löschen?',
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }

    if (role === 'confirm') {
      await this.deleteUserService.deleteUser(this.user, data);
      this.pressLogoutButton();
      return;
    }
  }

  private async deleteFile(previewImage: PreviewImage): Promise<void> {
    this.isPhotoEditLoading = true;
    const deletionResult = await this.userFileUploadService.deleteFile(this.user, previewImage);

    if (deletionResult === 401) {
      this.isPhotoEditLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (deletionResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isPhotoEditLoading = false;
      throw Error(this.userFileUploadService.errorMessage);
    }

    this.previewImages.splice(this.previewImages.indexOf(this.previewImages.find((x) => x.id === previewImage.id)), 1);
    this.hasRequestFailed = false;
    this.isPhotoEditLoading = false;
  }

  private async uploadPhoto(imageFile: File): Promise<void> {
    this.isPhotoEditLoading = true;
    const uploadResult = await this.userFileUploadService.uploadFile(this.user, imageFile);

    if (uploadResult === 401) {
      this.isPhotoEditLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }

    if (uploadResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isPhotoEditLoading = false;
      throw Error(this.userFileUploadService.errorMessage);
    }

    this.hasRequestFailed = false;
    this.isPhotoEditLoading = false;

    await this.loadPreviewImages();
  }

  private async loadPreviewImages(): Promise<void> {
    this.arePreviewImagesLoading = true;
    const previewImagesResult = await this.getUserFileService.getUserPreviewImages(this.user);
    if (previewImagesResult === 401) {
      this.arePreviewImagesLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (previewImagesResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.arePreviewImagesLoading = false;
      throw Error(this.getUserFileService.errorMessage);
    }
    this.previewImages = previewImagesResult as PreviewImage[];
    this.hasRequestFailed = false;
    this.arePreviewImagesLoading = false;
  }

  private async showImageModal(base64Image: string, previewImage: PreviewImage): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: UserFileComponent,
      id: 'ImageModal',
      cssClass: 'standard-modal',
      componentProps: {
        base64Image,
        previewImage,
        user: this.user,
        enableBackdropDismiss: true,
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }

    if (role === 'delete') {
      this.deleteFile(data);
    }
  }
}
