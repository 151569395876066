<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
  *ngIf="isLoading === true"></ion-img>
<ion-card-content class="ion-text-start" *ngIf="course">
  <ion-row>
    <ion-col>
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.description)"></div>
    </ion-col>
  </ion-row>
  <ion-row class="highlighted-text">
    <ion-col size="6" size-md="5">
      <strong>Kurszeitraum:</strong>
    </ion-col>
    <ion-col size="6" size-md="7">
      {{ course?.formattedRange }}
    </ion-col>
  </ion-row>
  <ion-row class="highlighted-text">
    <ion-col size="6" size-md="5">
      <strong>Anmeldung bis:</strong>
    </ion-col>
    <ion-col size="6" size-md="7">
      {{ dateHelper.formatDateString(course?.lastRegistrationDate) }}
    </ion-col>
  </ion-row>
  <ion-row class="highlighted-text">
    <ion-col size="6" size-md="5">
      <strong>Freie Plätze:</strong>
    </ion-col>
    <ion-col size="6" size-md="7">
      {{ occupancyStatusText[course?.occupancyStatus] }} ({{ course?.learnMethod?.name }})
    </ion-col>
  </ion-row>
  <ion-row *ngIf="course?.formattedPrice" class="highlighted-text">
    <ion-col size="6" size-md="5">
      <strong>Preis:</strong>
    </ion-col>
    <ion-col size="6" size-md="7">
      {{ course?.formattedPrice }}
    </ion-col>
  </ion-row>
  <ion-row class="highlighted-text">
    <ion-col size="6" size-md="5">
      <strong>Förderungen:</strong>
    </ion-col>
    <ion-col size="6" size-md="7">
      <ion-row *ngFor="let funding of course?.fundings">
        <a class="highlighted-text" [href]="funding.link" target="_blank">{{ funding.name }}</a>
      </ion-row>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="course?.address">
    <ion-col size="3" size-md="2" class="display-flex ion-justify-content-center">
      <i alt="Ortsnadel-Icon" class="course-icons icon-needle-outline icon-color-dark-blue-1"></i>
    </ion-col>
    <ion-col size="7" size-md="8">
      <ion-row>
        <strong>Ort:</strong>
      </ion-row>
      <ion-row>
        {{course?.address!.name}}
      </ion-row>
      <ion-row>
        {{course?.address!.streetName}} {{course?.address!.streetNumber}}
      </ion-row>
      <ion-row>
        {{course?.address!.zip}} {{course?.address!.city}}
      </ion-row>
      <ion-row>
        {{course?.address!.state?.name}}
      </ion-row>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="3" size-md="2" class="display-flex ion-justify-content-center">
      <ion-img *ngIf="course?.personnel.picture" aria-label="Bild der Ansprechperson" class="personnel-image"
        src="{{course?.personnel.picture}}"></ion-img>
      <i *ngIf="!course?.personnel.picture" alt="Geschäftsmann-Icon"
        class="course-icons icon-businessman-outline icon-color-dark-blue-1"></i>
    </ion-col>
    <ion-col size="7" size-md="8">
      <ion-row>
        <strong>Kontaktperson:</strong>
      </ion-row>
      <ion-row>
        {{course?.personnel?.name}}
      </ion-row>
      <ion-row>
        {{course?.personnel.function}}
      </ion-row>
      <ion-row>
        {{course?.personnel.email}}
      </ion-row>
      <ion-row>
        {{course?.personnel.tel}}
      </ion-row>
      <ion-row>
        {{course?.personnel.callTime}}
      </ion-row>
      <ion-row>
        {{course?.personnel.description}}
      </ion-row>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" size-md="5" class="ion-align-items-center display-flex">
      <ng-container *ngIf="courseHelper.canCoursetemplateBeRegisteredFor(course, userCourseRegistrations)">
        <ion-button
          *ngIf="course?.courseTemplate.registrationTypeId === REGISTRATION_TYPE_ID.registrationRequestPossible"
          [disabled]="isLoading" class="ion-margin-top" (click)="courseRegistrationButtonClicked()">
          Teilnahme anfragen
        </ion-button>

        <ion-button
          *ngIf="course?.courseTemplate.registrationTypeId === REGISTRATION_TYPE_ID.registrationPossible"
          [disabled]="isLoading" class="ion-margin-top" (click)="courseRegistrationButtonClicked()">
          Verbindlich anmelden
        </ion-button>
      </ng-container>
      <ng-container *ngIf="course?.isRegistered === true"><ion-button class="button-pressed
          ion-margin-top">Angefragt</ion-button></ng-container>
    </ion-col>
  </ion-row>
</ion-card-content>
