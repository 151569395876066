<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true">
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>
      <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
        *ngIf="isLoading === true"></ion-img>
      <ion-row>
        <ion-col size-lg="8" offset-lg="2" size-md="10" offset-md="1">
          <div class="base-content-top-margin center-element display-flex">
            <ion-card class="swiper-card">
              <div class="stretcher-div"></div>
              <ng-container *ngIf="isLoading === false && course !== undefined">
                <ion-row>
                  <ion-col size="10">
                    <ion-card-header>
                      <h4>{{ course.name }}</h4>
                    </ion-card-header>
                  </ion-col>
                  <ion-col size="2">
                    <div class="ion-text-end">
                      <ion-button class="fav-button" (click)="toggleCourseFavoriteButtonClicked(course?.courseTemplate?.id)"
                        [disabled]="isLikingDisabled()">
                        <i
                          *ngIf="courseHelper.isFavoriteCourse(course?.courseTemplate?.id, selectedCourseTemplateIds) === false"
                          class="button-icon icon-bookmark-2-outline icon-color-white" aria-label="lesezeichen setzen"></i>
                        <i
                          *ngIf="courseHelper.isFavoriteCourse(course?.courseTemplate?.id, selectedCourseTemplateIds) === true"
                          class="button-icon icon-bookmark-2 icon-color-white" aria-label="lesezeichen entfernen"></i>
                      </ion-button>
                    </div>
                  </ion-col>
                </ion-row>
                <app-four-star-rating [courseTemplate]="course.courseTemplate"
                  [userCourseRegistrations]="userCourseRegistrations" [user]="user"></app-four-star-rating>
                <ion-row>
                  <ion-col>
                    <app-course-information [course]="course" [userCourseRegistrations]="userCourseRegistrations"
                      [isLoading]="isLoading" [user]="user" (isLoggedInEvent)="isLoggedIn"></app-course-information>
                  </ion-col>
                </ion-row>
              </ng-container>
              <ng-container *ngIf="isLoading === false && course === undefined">
                <ion-row>
                  <ion-col size="12">
                    <ion-card-header>
                      <h4>Kurs nicht gefunden</h4>
                    </ion-card-header>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
