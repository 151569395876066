<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions appScrollPosition [content]="content"
               [hasSearched]="hasSearched" (isScrollerAtBottom)="loadMoreCourses()">
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app *ngIf="hasSearched === false" id="app-header"></app-header-app>
      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto">
            </ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
               *ngIf="(areQualificationsLoading === true || isSearchLoading === true) && hasSearched === false">
      </ion-img>
      <ng-container *ngIf="hasSearched === false && areQualificationsLoading === false">
        <ion-row class="base-content-top-margin">
          <ion-col size-md="8" offset-md="2">
            <div class="center-element">
              <app-course-search class="display-grid" (searchEvent)="searchCourses($event)"
                                 [qualificationCategories]="qualificationCategories"
                                 [qualificationTypes]="qualificationTypes"
                                 [isSearchLoading]="isSearchLoading"></app-course-search>
            </div>
          </ion-col>
        </ion-row>
      </ng-container>
      <ion-row class="pointer-events-none">
        <ion-col>
          <ng-container *ngIf="hasSearched === true">
            <ion-row class="chip-row">
              <ion-col class="chip-col pointer-events-auto" size-lg="8" offset-lg="2" size-md="10" offset-md="1">
                <ion-chip aria-label="zurück zur Kurssuche" (click)="hasSearched = false">
                  <ion-icon class="text-white" name="arrow-back" alt="Zurück zur Suche"></ion-icon>&nbsp;Suche
                </ion-chip>
                <ion-chip *ngFor="let chipItem of chipSearchItems" [disabled]="isDeletableChip(chipItem) === false">
                  {{ chipItem.term }}:
                  {{ chipItem.value }}
                  <ion-icon (click)="removeSearchChip(chipItem)" alt="Suchen ohne diesen Parameter" name="close"
                            color="text-white"></ion-icon>
                </ion-chip>
              </ion-col>
            </ion-row>
            <ion-row id="courses" class="base-content-top-margin pointer-events-auto">
              <ion-col size-lg="8" offset-lg="2" size-md="10" offset-md="1">
                <div id="course-list">
                  <app-course-list [courses]="courses" [searchFailed]="searchFailed" [hasSearched]="hasSearched"
                                   [isActiveTab]="isActiveTab" [courseParameter]="currentSearchParameter">
                  </app-course-list>
                  <div class="display-grid" *ngIf="isSearchLoading === true && hasSearched === true">
                    <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif"
                             class="dynamic-loading-animation ion-margin-top">
                    </ion-img>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
  <div>
    <ion-footer hide-nav-bar="true" class="ion-text-center position-fixed" #scrollButton>
      <app-footer></app-footer>
    </ion-footer>
  </div>
</div>
