<div appKeypress (arrowKey)="onArrowKey($event)" class="swiper-container" id="career-path-container"
     [appScrollBottom]="content"
     [style.pointer-events]="isSwiperCooldown ? 'none' : 'auto'">
  <div class="swiper-slide display-grid" id="swiper-slide-first"
       *ngIf="this.currentCourseTemplateTreeBranchIndex === 0 && renderedCourseTemplateTreeBranches.length !== 1">
    <div class="career-path-card">
    </div>
  </div>
  <div class="swiper-slide display-grid"
       *ngFor="let courseTemplateTreeBranchItem of renderedCourseTemplateTreeBranches; let i = index"
       [attr.data-index]="i"
       id="swiper-slide-{{json.stringify(courseTemplateTreeBranchItem)}}">
    <ion-card class="career-path-card" (click)="clickCard(i)">
      <button *ngIf="isCurrentCard(i) === true" class="fav-swiper-button" (click)="clickFavoriteButton()"
              [disabled]="isWatchlistDisabled()">
        <i class="button-icon icon-bookmark-2 icon-color-white pulse-{{!isWatchlistDisabled()}}"
           aria-label="lesezeichen entfernen"
           *ngIf="isBranchFavoritesStored === true"></i>
        <i class="button-icon icon-bookmark-2-outline icon-color-white pulse-{{!isWatchlistDisabled()}}"
           aria-label="lesezeichen setzen"
           *ngIf="isBranchFavoritesStored === false"></i>
      </button>
      <div class="win-icon-div">
        <i class="icon-person-win icon-color-dark-blue-1" aria-label="Icon einer gewinnenden Person"></i>
      </div>

      <div id="{{json.stringify(courseTemplateTreeBranchItem)}}-stamp"></div>

      <ng-container *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === true">
        <div class="career-text-box"
             (click)="isSwiperCooldown ? null : courseClicked(i, courseTemplateTreeBranchItem.nextBranch.id, $event)">
          <ion-row>
            <ion-col class="center-element">
              <ion-text class="career-text">
                Du hast dein Karriereziel erreicht! <br />
                <strong class="underline cursor-pointer">
                  {{ courseTemplateTreeBranchItem.nextBranch?.name }}&nbsp;
                </strong>
              </ion-text>
            </ion-col>
          </ion-row>
        </div>

        <div class="justify-content-center display-grid">
          <div class="css-art-career-path-line"></div>
        </div>
      </ng-container>

      <div class="career-text-box"
           (click)="isSwiperCooldown ? null : courseClicked(i, courseTemplateTreeBranchItem.id, $event)">
        <ion-row>
          <ion-col class="center-element">
            <ion-text class="career-text">
              <ng-container *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === true">
                Dein Zwischenschritt bis zum Ziel! <br /></ng-container>
              <ng-container *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === false">
                Du hast dein Karriereziel erreicht! <br /></ng-container>
              <strong class="underline cursor-pointer">
                {{ courseTemplateTreeBranchItem?.name }}&nbsp;
              </strong>
            </ion-text>
          </ion-col>
        </ion-row>
      </div>

      <div class="justify-content-center display-grid">
        <div class="css-art-career-path-dot"></div>
        <div class="css-art-career-path-line"></div>
      </div>

      <div class="career-text-box">
        <ion-text class="career-text center-element">
          Hier beginnt deine Reise! <br />
          <strong>
            <ng-container *ngIf="selectedRequirementQualificationCategory !==  null &&
              selectedRequirementQualificationCategory !==  undefined">
              {{ selectedRequirementQualificationCategory?.name }}<br />
            </ng-container>
            {{ selectedRequirementQualificationType?.name }}
          </strong>
        </ion-text>
      </div>

      <div *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === false" class="career-path">
      </div>
    </ion-card>
  </div>
</div>
<div class="swiper-slide display-grid" id="swiper-slide-last" *ngIf="renderedCourseTemplateTreeBranches.length < 3 &&
    currentCourseTemplateTreeBranchIndex > 1">
  <div class="career-path-card">
  </div>
</div>
<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="background-loading-animation"
         *ngIf="isLoading === true"></ion-img>

