export class BrowserInteractionHelper {
  private touchtime = 0;

  public isDoubleClick(): boolean {
    if (new Date().getTime() - this.touchtime < 800 && this.touchtime !== 0) {
      this.touchtime = 0;
      return true;
    }
    this.touchtime = new Date().getTime();
    return false;
  }

  public refresh() {
    window.location.reload();
  }
}
