import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.page.html',
  styleUrls: ['./data-protection.page.scss'],
})
export class DataProtectionPage implements OnInit {
  constructor() {}

  ngOnInit() {}

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }
}
