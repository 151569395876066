import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ErrorMessageHelper } from './helpers/error-message-helper';
import { CHUNK_LOADING_FAILED_ERROR } from './models/constants-status';
import { ErrorMessageParameter } from './models/error-message-parameter';
import { ErrorMessageService } from './services/error-message.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private errorMessageService: ErrorMessageService,
    private errorMessageHelper: ErrorMessageHelper,
  ) {
    super();
  }

  async handleError(error: any): Promise<void> {
    if (CHUNK_LOADING_FAILED_ERROR.test(error.message) === true) {
      console.error(error);
      window.location.reload();
      return;
    }

    if (environment.production === true) {
      const errorMessage: ErrorMessageParameter = new ErrorMessageParameter();
      errorMessage.description = await this.errorMessageHelper.createErrorMessage(error);
      this.errorMessageService.reportErrorMessage(errorMessage);

      super.handleError('Ein unerwarteter Fehler ist aufgetreten');
    }
    if (environment.production === false) {
      super.handleError(error);
    }
  }
}
