import { Component, Input, OnInit } from '@angular/core';
import { CourseHelper } from 'app/helpers/course-helper';
import { CourseTemplate } from 'app/models/course-template';
import { UserCourseTemplateRating } from 'app/models/user-course-institute-template-rating.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { User } from 'app/models/user.model';

@Component({
  selector: 'app-four-star-rating',
  templateUrl: './four-star-rating.component.html',
  styleUrls: ['./four-star-rating.component.scss'],
})
export class FourStarRatingComponent implements OnInit {
  @Input() public courseTemplate: CourseTemplate = new CourseTemplate();
  @Input() public userCourseRegistrations: UserCourseRegistration[] = [];
  @Input() public user: User = undefined;

  constructor(public courseHelper: CourseHelper) {}

  ngOnInit() {}

  public onMouseEnterRatingStar(template: CourseTemplate, temporaryRating: number): void {
    template.temporaryRating = temporaryRating;
  }

  public onMouseLeaveRatingStar(template: CourseTemplate): void {
    template.temporaryRating = template.rating;
  }

  public onClickRatingStar(template: CourseTemplate, rating: number): void {
    template.rating = rating;
  }

  public onClickSendRatingButton(template: CourseTemplate): void {
    const courseRating: UserCourseTemplateRating = new UserCourseTemplateRating();
    courseRating.courseTemplateId = template.id;
    courseRating.rating = template.rating;
    this.courseHelper.sendCourseRating(courseRating, this.user);
    template.wasRatingSent = true;
  }
}
