<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
         *ngIf="isCourseLoading === true || areFavoritesLoading === true || isPageLoading === true"></ion-img>
<ion-card class="ion-text-center margin-slider-card" *ngIf="courseTemplateTreeBranches?.length <= 0">
  <h1 class="ion-text-center ion-margin">Die Suche fand keine interessanten Karrierepfade.</h1>
  <ion-row class="ion-margin-bottom">
    <ion-col offset="1" size="10" size-md="5">
      <ion-button expand="block" *ngIf="this.hasPotentialResultsInCareerSearch"
                  aria-label="zurück zu Vorigen Ergebnissen" (click)="careerPathAlgorithmHelper.resetAllPaths();"
                  alt="Zu letzten Ergebnissen" default-href="#">Vorige
        Ergebnisse
      </ion-button>
    </ion-col>
    <ion-col offset="1" size="10" offset-md="0" size-md="5">
      <ion-button expand="block" (click)="setHasSearched(false); browserInteractionHelper.refresh();">Neu
        Suchen
      </ion-button>
    </ion-col>
  </ion-row>
</ion-card>
<ng-container *ngIf="courseTemplateTreeBranches?.length > 0 && isPageLoading === false">
  <ion-row>
    <app-swiper [amountOfPreviousCareerBranches]="amountOfPreviousCareerBranches" [content]="content"
                [courseTemplateTreeBranches]="courseTemplateTreeBranches"
                [selectedRequirementQualificationCategory]="selectedRequirementQualificationCategory"
                [selectedRequirementQualificationType]="selectedRequirementQualificationType"
                [isCourseLoading]="isCourseLoading"
                (setDoesLikeClickAddLikeEvent)="setDoesLikeClickAddLike()"
                (courseClickedEvent)="courseClicked($event.courseId, $event.event)"
                (changeIndexEvent)="setCurrentBranch($event.index); markStamps($event.index)"
                [searchedCourseTemplateTreeParameter]="searchedCourseTemplateTreeParameter"
                (updateBranchesEvent)="updateBranches($event.branches)"
                (changeIsSwiperCooldownEvent)="$event.isSwiperCooldown"
                [nextCareerSearchStartIndex]="nextCareerSearchStartIndex"
                [isAtFavoritesLimit]="isAtFavoritesLimit"
                [isStoringDisabled]="isStoringDisabled()"
                [isBranchFavoritesStored]="isBranchFavoritesStored(currentCourseTemplateTreeBranch)"
                (clickFavoriteButtonEvent)="clickFavoriteButton()">
    </app-swiper>
  </ion-row>
  <ion-row id="thumb-button-row" class="justify-content-center" *ngIf="currentCourseTemplateTreeBranch !== undefined">
    <button class="dislike-swiper-button" (click)="clickDislikeButton()" [disabled]="isStoringDisabled()">
      <div class="thumb-dislike"></div>
    </button>
    <button class="like-swiper-button" (click)="clickLikeButton()" [disabled]="isStoringDisabled()">
      <div class="thumb-like"></div>
    </button>
  </ion-row>
</ng-container>
