import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CourseListComponent } from 'app/components/course-components/course-list/course-list.component';
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { register } from 'swiper/element/bundle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './app.global-error-handler';
import { CourseDetailsComponent } from './components/course-components/course-details/course-details.component';
import { CourseInformationComponent } from './components/course-components/course-information/course-information.component';
import { TreeComponent } from './components/course-components/tree/tree.component';
import { UserCourseRegistrationComponent } from './components/course-components/user-course-registration/user-course-registration.component';
import { WatchListComponent } from './components/course-components/watch-list/watch-list.component';
import { ChatWindowComponent } from './components/layout/chat-window/chat-window.component';
import { ConfirmationModalComponent } from './components/layout/confirmation-modal/confirmation-modal.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { FourStarRatingComponent } from './components/layout/four-star-rating/four-star-rating.component';
import { HeaderAppComponent } from './components/layout/header-app/header-app.component';
import { HeaderWebComponent } from './components/layout/header-web/header-web.component';
import { HomeNavigationBarComponent } from './components/layout/home-navigation-bar/home-navigation-bar.component';
import { InfoModalComponent } from './components/layout/info-modal/info-modal.component';
import { MenuContentComponent } from './components/layout/menu/menu-content/menu-content.component';
import { MenuComponent } from './components/layout/menu/menu.component';
import { SelectionComponent } from './components/layout/selection/selection.component';
import { SwiperComponent } from './components/layout/swiper/swiper.component';
import { TabsComponent } from './components/layout/tabs/tabs.component';
import { CareerSearchComponent } from './components/search-components/career-search/career-search.component';
import { CourseSearchComponent } from './components/search-components/course-search/course-search.component';
import { UserFileComponent } from './components/user-file/user-file.component';
import { ArrowkeyPressDirective } from './directives/arrowkey-press.directive';
import { ResizeActionsDirective } from './directives/resize-actions.directive';
import { ScrollActionsDirective } from './directives/scroll-actions.directive';
import { ScrollBottomDirective } from './directives/scroll-bottom.directive';
import { ScrollPositionDirective } from './directives/scroll-position.directive';
import { BrowserInteractionHelper } from './helpers/browser-interaction-helper';
import { CourseHelper } from './helpers/course-helper';
import { DateHelper } from './helpers/date-helper';
import { DeviceHelper } from './helpers/device-helper';
import { ErrorMessageHelper } from './helpers/error-message-helper';
import { FormHelper } from './helpers/form-helper';
import { JsonHelper } from './helpers/json-helper';
import { MatomoHelper } from './helpers/matomo-helper';
import { ObjectConversionHelper } from './helpers/object-conversion.helper';
import { SelectionHelper } from './helpers/selection-helper';
import { StorageHelper } from './helpers/storage-helper';
import { UserHelper } from './helpers/user-helper';
import { HomePage } from './pages/main-pages/home/home.page';
import { SearchPage } from './pages/main-pages/search/search.page';
import { TreePage } from './pages/main-pages/tree/tree.page';
import { WatchListPage } from './pages/main-pages/watch-list/watch-list.page';
import { CoursePage } from './pages/misc-pages/course/course.page';
import { DataProtectionPage } from './pages/static-pages/data-protection/data-protection.page';
import { LegalInformationPage } from './pages/static-pages/legal-information/legal-information.page';
import { UserAgreementPage } from './pages/static-pages/user-agreement/user-agreement.page';
import { UserLoginPage } from './pages/user-pages/user-login/user-login.page';
import { UserProfilePage } from './pages/user-pages/user-profile/user-profile.page';
import { UserRegistrationPage } from './pages/user-pages/user-registration/user-registration.page';
register();
@NgModule({
  declarations: [
    AppComponent,
    CareerSearchComponent,
    ChatWindowComponent,
    ConfirmationModalComponent,
    CourseDetailsComponent,
    CourseInformationComponent,
    CourseListComponent,
    CourseSearchComponent,
    FooterComponent,
    FourStarRatingComponent,
    HeaderAppComponent,
    HeaderWebComponent,
    HomeNavigationBarComponent,
    InfoModalComponent,
    MenuComponent,
    MenuContentComponent,
    SelectionComponent,
    SwiperComponent,
    TabsComponent,
    TreeComponent,
    UserCourseRegistrationComponent,
    UserFileComponent,
    WatchListComponent,
    ArrowkeyPressDirective,
    ResizeActionsDirective,
    ScrollActionsDirective,
    ScrollBottomDirective,
    ScrollPositionDirective,
    CoursePage,
    DataProtectionPage,
    HomePage,
    LegalInformationPage,
    SearchPage,
    TreePage,
    UserAgreementPage,
    UserLoginPage,
    UserProfilePage,
    UserRegistrationPage,
    WatchListPage,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CourseListComponent,
    CareerSearchComponent,
    CourseHelper,
    DateHelper,
    FormHelper,
    StorageHelper,
    SelectionHelper,
    UserHelper,
    ChatWindowComponent,
    TabsComponent,
    ObjectConversionHelper,
    JsonHelper,
    ErrorMessageHelper,
    DeviceHelper,
    BrowserInteractionHelper,
    MatomoHelper,
    MenuContentComponent,
    HomeNavigationBarComponent,
    provideHttpClient(withInterceptorsFromDi()),
    provideMatomo({ trackerUrl: 'https://matomo.zwh.de/', siteId: '15' }, withRouter()),
  ],
})
export class AppModule {}
