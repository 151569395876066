import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { QualificationType } from 'app/models/qualification-type.model';
import { Qualification } from 'app/models/qualification.model';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements OnInit {
  @Input() options: Qualification[] | QualificationCategory[] | QualificationType[];
  @Input() selectedOption: string = null;
  @Input() title = '';
  public filteredOptions: Qualification[] | QualificationCategory[] | QualificationType[];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.filteredOptions = this.options;
  }

  public confirm(selectedOption: string): Promise<boolean> {
    this.selectedOption = selectedOption;
    let selectedOptionNumber = null;
    if (this.selectedOption !== null) {
      selectedOptionNumber = parseInt(this.selectedOption, 10);
    }
    return this.modalCtrl.dismiss(selectedOptionNumber, 'confirm');
  }

  public cancel(): Promise<boolean> {
    return this.modalCtrl.dismiss(this.selectedOption, 'cancel');
  }

  public filterContent(searchInput): void {
    this.filteredOptions = this.options.filter((x) => x.name.toLowerCase().includes(searchInput.toLowerCase()));
  }
}
