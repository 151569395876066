import { Course } from './course.model';

export class CourseTemplate {
  public id: number;
  public name: string;
  public courses?: Course[];
  public websiteDescription?: string;
  public temporaryRating?: number;
  public rating?: number;
  public wasRatingSent?: boolean;
}
