import { Address } from './address.model';

export class UserData {
  public address: Address;
  public birthDate: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public genderId: number;
}
