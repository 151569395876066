<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-row class="base-content-top-margin">
        <ion-col size-md="8" offset-md="2">
          <div class="center-element">
            <ion-card>
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Impressum</h2>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col>
                    <p>Die nachstehenden Informationen enthalten die gesetzlich vorgesehenen Pflichtangaben zur
                      Anbieterkennzeichnung auf der Internet-Präsenz der Zentralstelle für die Weiterbildung im Handwerk
                      e. V. und der ZWH Dienstleistungs-GmbH.
                    </p>
                    <h3><strong>Dies ist ein Webauftritt der</strong></h3>
                    <p>Zentralstelle für die Weiterbildung im Handwerk e. V. (ZWH)</p>

                    <p>Sternwartstraße 27–29</p>
                    <p>40223 Düsseldorf</p>
                    <p><a aria-label="ZWH anrufen" href="tel:+492113020090">Telefon: +49 / 211 / 302009-0</a></p>
                    <p>Telefax: +49 / 211 / 302009-99</p>
                    <p><a aria-label="ZWH mailen" href="mailto:info@zwh.de">info&#64;zwh.de</a></p>
                    <p><a href="https://www.zwh.de">www.zwh.de</a></p>

                    <p>Vereinsregister: Amtsgericht Düsseldorf</p>
                    <p>Registernummer: VR 8315</p>
                    <p>USt-IdNr: DE191832850</p>

                    <p>Vertretungsberechtigte Person:</p>
                    <p>Sebastian Knobloch</p>
                    <p><a aria-label="ZWH mailen" href="mailto:sknobloch@zwh.de">sknobloch&#64;zwh.de</a></p>
                    <p><em>Geschäftsführer nach § 12 der Satzung der Zentralstelle für die Weiterbildung im Handwerk e.
                      V.
                    </em></p>
                    <p><strong>Redaktion: </strong></p>
                    <p>Michael Trommen</p>
                    <p><a href="mailto:mtrommen@zwh.de">mtrommen&#64;zwh.de</a></p>
                    <p><a href="tel:+49211302009758">+49 / 211 / 302009-758</a></p>
                    <p><strong>Layout:</strong></p>
                    <p>Moana Larissa Brunow</p>
                    <p><a href="mailto:mbrunow@zwh.de">mbrunow&#64;zwh.de</a></p>
                    <p><a href="tel:+4921130200925">+49 / 211 / 302009-25</a></p>
                    <h3><strong>Schlichtungsverfahren</strong></h3>
                    <h3><strong>OS-Plattform zur Beilegung außergerichtlicher Streitigkeiten:</strong></h3>
                    <p>Die EU-Kommission stellt gemäß der EU-Verordnung Nr. 524/2013 eine interaktive Website zur
                      Online-Streitbeilegung (OS-Plattform) bereit, die Unterstützung bei der Schlichtung
                      außergerichtlicher Streitigkeiten aus Online-Rechtsgeschäften bietet. Die OS-Plattform der
                      EU-Kommission finden Sie unter diesem Link:<a
                        href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
                    <h3><strong>Schlichtungsstelle nach Verbraucherstreitbeilegungsgesetz:</strong></h3>
                    <p>>Wir nehmen an einem Streitbeilegungsverfahren im Sinne des Verbraucherstreitbeilegungsgesetzes
                      (VSBG) nicht teil.</p>
                    <h3><strong>Urheberrechte</strong></h3>
                    <p>Inhalt und Struktur unserer Websites sind urheberrechtlich geschützt. Alle Texte, Bilder,
                      Grafiken,
                      Ton-, Video- und Animationsdateien unterliegen dem Urheberrecht und anderen Gesetzen zum Schutz
                      geistigen Eigentums. Sie dürfen weder kopiert noch verändert und auf anderen Websites verwendet
                      werden.

                      Einige Seiten enthalten auch Bilder, die dem Urheberrecht derjenigen unterliegen, die sie zur
                      Verfügung gestellt haben. Die Vervielfältigung von Informationen oder Daten, insbesondere die
                      Verwendung von Texten, Textteilen oder Bildmaterial, bedarf der ausdrücklichen vorherigen
                      Zustimmung
                      des jeweiligen Inhabers der Urheberrechte.</p>
                    <h3><strong>Haftungsausschluss</strong></h3>
                    <p>Wir übernehmen keinerlei Verantwortung oder Haftung für die Angaben auf dieser Website. Unser
                      Ziel ist es, aktuelle und genaue Informationen bereitzustellen. Allerdings kann nicht garantiert
                      werden, dass die auf dieser Website verfügbaren Angaben tatsächlich aktuell, umfassend, komplett
                      oder genau sind.

                      Bei den bereitgestellten Informationen handelt es sich um solche allgemeiner Art. Sie stellen
                      keine rechtliche Beratung dar.

                      Sofern von dieser Website auf andere Websites verwiesen wird, kann die ZWH e. V. den Inhalt der
                      Websites nicht beeinflussen und für diesen auch keine Verantwortung übernehmen.
                    </p>

                    <ion-text>
                      <ion-row>
                        <ion-col>
                          <h3><strong>Hubgrade wurde ursprünglich gefördert von:</strong></h3>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size-sm="6" size="12">
                          <ion-img src="../../../../assets/sponsors/logo-bibb.png"></ion-img>
                        </ion-col>
                        <ion-col size-sm="6" size="12">
                          <ion-img src="../../../../assets/sponsors/logo-bmbf.png"></ion-img>
                        </ion-col>
                      </ion-row>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
