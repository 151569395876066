import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { MatomoTracker } from 'ngx-matomo-client';
@Injectable()
export class MatomoHelper {
  constructor(private readonly tracker: MatomoTracker) {}

  public trackMatomo(eventName: string, data?: Object[]) {
    if (environment.production === true) {
      this.tracker.trackEvent(eventName, JSON.stringify(data));
    }
  }
}
