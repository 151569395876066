import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gesture, GestureController, GestureDetail, IonContent } from '@ionic/angular';
import { BrowserInteractionHelper } from 'app/helpers/browser-interaction-helper';
import { CareerPathAlgorithmHelper } from 'app/helpers/career-path-algorithm-helper';
import { CareerPathHelper } from 'app/helpers/career-path-helper';
import { CourseHelper } from 'app/helpers/course-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { HAS_CAREER_SEARCHED_LOGICAL_NAME } from 'app/models/constants-logical-names';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { QualificationType } from 'app/models/qualification-type.model';
import { CAREER_SEARCH_STANDARD_PARAMETER_VALUES } from '../../../models/constants-app-values';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements OnInit {
  @Output() changeIndexEvent = new EventEmitter();
  @Output() changeIsSwiperCooldownEvent = new EventEmitter();
  @Output() courseClickedEvent = new EventEmitter();
  @Output() setDoesLikeClickAddLikeEvent = new EventEmitter();
  @Output() updateBranchesEvent = new EventEmitter();
  @Output() clickFavoriteButtonEvent = new EventEmitter();
  @Input() amountOfPreviousCareerBranches: number = 0;
  @Input() content: IonContent;
  @Input() courseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];
  @Input() doesLikeButtonAddLike: boolean = false;
  @Input() isCourseLoading: boolean = false;
  @Input() searchedCourseTemplateTreeParameter: CourseTemplateTreeParameter;
  @Input() selectedRequirementQualificationCategory: QualificationCategory;
  @Input() selectedRequirementQualificationType: QualificationType;
  @Input() nextCareerSearchStartIndex: number = CAREER_SEARCH_STANDARD_PARAMETER_VALUES.startIndex;
  @Input() isAtFavoritesLimit: boolean = false;
  @Input() isStoringDisabled: boolean = false;
  @Input() isBranchFavoritesStored: boolean = false;

  public currentCourseTemplateTreeBranchIndex: number;
  public isLoading: boolean = false;
  public isSwiperCooldown: boolean = false;
  public json = JSON;
  public renderedCourseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];

  private currentCareerSearchIndex: number;

  constructor(
    public browserInteractionHelper: BrowserInteractionHelper,
    public careerPathAlgorithmHelper: CareerPathAlgorithmHelper,
    private careerPathHelper: CareerPathHelper,
    private courseHelper: CourseHelper,
    private gestureCtrl: GestureController,
    private storageHelper: StorageHelper,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.setupSwipeGesture();
    await this.updateRenderedBranches();
    this.changeDetector.detectChanges();
    await this.activateCurrentSlide();
  }

  public onArrowKey(key: string): void {
    if (key === 'ArrowLeft') {
      this.goToPreviousSlide().then(() => {
        this.resetMarginsOfBranchElementsAfterSwipe();
      });
    }
    if (key === 'ArrowRight') {
      this.goToNextSlide().then(() => {
        this.resetMarginsOfBranchElementsAfterSwipe();
      });
    }
  }

  public courseClicked(indexOfClickedCard: number, courseId: number, event: Event): void {
    if (
      this.isCourseLoading === true ||
      this.currentCourseTemplateTreeBranchIndex < indexOfClickedCard ||
      this.currentCourseTemplateTreeBranchIndex > indexOfClickedCard
    ) {
      return;
    }
    this.courseClickedEvent.emit({ courseId, event });
  }

  public async clickFavoriteButton(): Promise<void> {
    this.clickFavoriteButtonEvent.emit();
  }

  public clickCard(indexOfClickedCard: number) {
    if (this.isNextCard(indexOfClickedCard) === true) {
      this.goToNextSlide().then(() => {
        this.resetMarginsOfBranchElementsAfterSwipe();
      });
    }
    if (this.isPreviousCard(indexOfClickedCard) === true) {
      this.goToPreviousSlide().then(() => {
        this.resetMarginsOfBranchElementsAfterSwipe();
      });
    }
  }

  public isCurrentCard(indexOfCard: number): boolean {
    if (
      (this.isCurrentCardFirst() === true && indexOfCard === 0) ||
      (this.isCurrentCardFirst() === false && indexOfCard === 1)
    ) {
      return true;
    }
    return false;
  }

  public isPreviousCard(indexOfCard: number): boolean {
    if (this.isCurrentCardFirst() === false && indexOfCard === 0) {
      return true;
    }
    return false;
  }

  public isNextCard(indexOfCard: number): boolean {
    if (
      (this.isCurrentCardFirst() === true && indexOfCard === 1) ||
      (this.isCurrentCardFirst() === false && indexOfCard === 2)
    ) {
      return true;
    }
    return false;
  }

  public isWatchlistDisabled(): boolean {
    return this.isStoringDisabled || (this.isAtFavoritesLimit === true && this.isBranchFavoritesStored === false);
  }

  private isCurrentCardFirst(): boolean {
    if (this.currentCourseTemplateTreeBranchIndex === 0) {
      return true;
    }
    return false;
  }

  private async updateRenderedBranches(): Promise<void> {
    if (this.currentCourseTemplateTreeBranchIndex === undefined) {
      this.currentCourseTemplateTreeBranchIndex = 0;
      this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });

      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex],
      );
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );

      this.setDoesLikeClickAddLikeEvent.emit();
      return;
    }
    if (this.renderedCourseTemplateTreeBranches.length === 2) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
    }
  }

  private setupSwipeGesture(): void {
    const swiperContainer = document.querySelector('.swiper-container');

    const gesture: Gesture = this.gestureCtrl.create({
      el: swiperContainer,
      gestureName: 'swipe',
      direction: 'x',
      threshold: 0,
      onMove: (event) => this.onSwipeStart(event),
      onEnd: (event) => this.onSwipeEnd(event),
    });

    gesture.enable();
  }

  private onSwipeStart(event: GestureDetail): void {
    this.changeDetector.detectChanges();
    if (
      !document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])) ||
      this.isSwiperCooldown === true
    ) {
      return;
    }
    this.changeDetector.detectChanges();
    document.getElementById(
      'swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0]),
    ).style.marginLeft = event.deltaX + 'px';
  }

  private onSwipeEnd(event: GestureDetail): void {
    if (event.deltaX >= 75) {
      this.isSwiperCooldown = true;
      this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
      this.goToPreviousSlide().then(() => {
        this.resetMarginsOfBranchElementsAfterSwipe();
      });
    }
    if (event.deltaX <= -75) {
      this.isSwiperCooldown = true;
      this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
      this.goToNextSlide().then(() => {
        this.resetMarginsOfBranchElementsAfterSwipe();
      });
    }
    if (event.deltaX > -75 && event.deltaX < 75) {
      this.resetMarginsOfBranchElementsAfterSwipe();
    }
  }

  private resetMarginsOfBranchElementsAfterSwipe(): void {
    // timeout um versehentliche Klicks auf Kurse beim Swipen zu verhindern
    setTimeout(() => {
      this.isSwiperCooldown = false;
      this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
      this.changeDetector.detectChanges();
    }, 300);
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])),
    );
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[1])),
    );
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[2])),
    );
    this.changeDetector.detectChanges();
  }

  private async goToNextSlide(): Promise<void> {
    if (
      this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 5 &&
      this.isLoading === false
    ) {
      console.log(
        'this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 5 &&\n' +
          '        this.isLoading === false',
      );
      await this.loadMoreBranches();
      await this.updateRenderedBranches();
      this.changeDetector.detectChanges();
    }

    if (this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 1) {
      return;
    }
    this.currentCourseTemplateTreeBranchIndex++;

    if (this.hasPreviousTreeRootChanged() === true) {
      this.currentCareerSearchIndex += 1;
    }
    if (this.currentCourseTemplateTreeBranchIndex === 1) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
      this.changeDetector.detectChanges();
      await this.activateCurrentSlide();
      this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });
      return;
    }

    this.renderedCourseTemplateTreeBranches.shift();
    if (this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1] !== undefined) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
      this.changeDetector.detectChanges();
    }
    await this.activateCurrentSlide();
    this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });
    return;
  }

  private async goToPreviousSlide(): Promise<void> {
    if (this.currentCourseTemplateTreeBranchIndex <= 0) {
      return;
    }
    this.currentCourseTemplateTreeBranchIndex--;
    this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });

    if (this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 2) {
      this.renderedCourseTemplateTreeBranches.unshift(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex - 1],
      );
      this.changeDetector.detectChanges();
      await this.activateCurrentSlide();
      return;
    }

    this.renderedCourseTemplateTreeBranches.pop();
    if (this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex - 1] !== undefined) {
      this.renderedCourseTemplateTreeBranches.unshift(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex - 1],
      );
      this.changeDetector.detectChanges();
    }
    await this.activateCurrentSlide();
    if (this.hasNextTreeRootChanged() === true) {
      this.currentCareerSearchIndex -= 1;
    }
    return;
  }

  private async activateCurrentSlide(): Promise<void> {
    const previouslyActiveBranch = document.getElementsByClassName('active-slide')[0];
    previouslyActiveBranch?.classList.remove('active-slide');

    document
      .getElementById(
        'swiper-slide-' +
          this.json.stringify(this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex]),
      )
      ?.classList.add('active-slide');

    this.changeDetector.detectChanges();
  }

  private resetMarginsOfBranchElement(branch: HTMLElement): void {
    if (!branch) {
      return;
    }
    branch.style.marginLeft = '0';
  }

  private async getRootCourseIdOfNextTreeToSkip(): Promise<number | undefined> {
    const skippedPathIds = await this.careerPathAlgorithmHelper.getSkippedPathIds();
    const unskippedPaths = this.courseTemplateTreeBranches.filter((x) => skippedPathIds.includes(x.id) === false);
    if (!unskippedPaths || unskippedPaths.length === 0) {
      return undefined;
    }
    return unskippedPaths[0].id;
  }

  private async loadMoreBranches(): Promise<void> {
    this.isLoading = true;
    const rootCourseIdOfNextTreeToSkip = await this.getRootCourseIdOfNextTreeToSkip();
    if (rootCourseIdOfNextTreeToSkip !== undefined) {
      await this.careerPathAlgorithmHelper.addDateSkippedToAlgorithmParameters(rootCourseIdOfNextTreeToSkip);
    }
    const courseTemplateTreesSearchResult = await this.careerPathHelper.searchCourseTemplateTrees(
      this.searchedCourseTemplateTreeParameter,
      this.nextCareerSearchStartIndex,
    );
    this.nextCareerSearchStartIndex += CAREER_SEARCH_STANDARD_PARAMETER_VALUES.maxResults;

    if (courseTemplateTreesSearchResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.courseHelper.errorCollection);
    }
    const newBranches = await this.careerPathHelper.buildCourseTemplateTrees(
      courseTemplateTreesSearchResult as CourseTemplateTree[],
    );
    /*
  TODO: diesen Teil hier nur nutzen wenn skip algorithmus triggert:
    if (this.renderedCourseTemplateTreeBranches.length === 3) {
      this.courseTemplateTreeBranches.splice(this.currentCourseTemplateTreeBranchIndex + 2);
      this.changeDetector.detectChanges();
      await this.updateRenderedBranches();
    }*/
    this.courseTemplateTreeBranches = this.courseTemplateTreeBranches.concat(newBranches);
    if (
      this.renderedCourseTemplateTreeBranches.length === 2 &&
      this.currentCourseTemplateTreeBranchIndex !== (0 && undefined)
    ) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
      this.changeDetector.detectChanges();
    }
    this.updateBranchesEvent.emit({ branches: this.courseTemplateTreeBranches });
    this.storageHelper.setSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME, true);
    await this.careerPathAlgorithmHelper.createParametersForCurrentTree(
      this.courseTemplateTreeBranches[this.courseTemplateTreeBranches.length - 1].id,
    );
    this.isLoading = false;
    this.changeDetector.detectChanges();
    return;
  }

  private hasPreviousTreeRootChanged(): boolean {
    return this.renderedCourseTemplateTreeBranches[0]?.id !== this.renderedCourseTemplateTreeBranches[1]?.id;
  }

  private hasNextTreeRootChanged(): boolean {
    return this.renderedCourseTemplateTreeBranches[2]?.id !== this.renderedCourseTemplateTreeBranches[1]?.id;
  }
}
