import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { InfoModalComponent } from 'app/components/layout/info-modal/info-modal.component';
import { CourseHelper } from 'app/helpers/course-helper';
import { DateHelper } from 'app/helpers/date-helper';
import { MatomoHelper } from 'app/helpers/matomo-helper';
import {
  ADVANCED_SEARCH_TERM,
  FUNDING_OPTIONS,
  LEARN_METHODS,
  LOGIN_SUGGESTION_TEXT,
  OCCUPANCY_STATUS_TEXT,
  QUALIFICATION_TYPE_SELECT_COURSE_TEXT,
  REGIONAL_SEARCH_TERM,
  THANKS_FOR_YOUR_REGISTRATION_MESSAGE_TEXT,
} from 'app/models/constants-app-texts';
import { DISTANCE_VALUES, REGISTRATION_TYPE_ID } from 'app/models/constants-app-values';
import { MATOMO_LOGICAL_NAME_COURSE_REGISTERED } from 'app/models/constants-matomo';
import { STANDARD_ERROR_TEXT } from 'app/models/constants-status';
import { Course } from 'app/models/course.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { User } from 'app/models/user.model';
import { UserCourseRegistrationComponent } from '../user-course-registration/user-course-registration.component';

@Component({
  selector: 'app-course-information',
  templateUrl: './course-information.component.html',
  styleUrls: ['./course-information.component.scss'],
})
export class CourseInformationComponent implements OnInit {
  @Input() course: Course;
  @Input() isLoading: boolean = false;
  @Input() userCourseRegistrations: UserCourseRegistration[];
  @Input() user: User;
  @Output() isLoggedInEvent = new EventEmitter<boolean>();
  @Output() registerForTemplateEvent = new EventEmitter<number>();

  public occupancyStatusText = OCCUPANCY_STATUS_TEXT;
  public readonly STANDARD_ERROR_TEXT = STANDARD_ERROR_TEXT;
  public readonly THANKS_FOR_YOUR_REGISTRATION_MESSAGE_TEXT = THANKS_FOR_YOUR_REGISTRATION_MESSAGE_TEXT;
  public readonly REGISTRATION_TYPE_ID = REGISTRATION_TYPE_ID;

  constructor(
    public courseHelper: CourseHelper,
    public dateHelper: DateHelper,
    public sanitizer: DomSanitizer,
    private matomoHelper: MatomoHelper,
    private modalCtrl: ModalController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    try {
      if (!this.course.appDescription) {
        this.course.appDescription = '';
      }
      if (this.course.isRegistered === undefined) {
        this.course.isRegistered = false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  public async openCourseRegistrationModal(): Promise<void> {
    let modal = await this.modalCtrl.create({
      component: UserCourseRegistrationComponent,
      componentProps: {
        user: this.user,
        courseId: this.course.id,
        registrationTypeId: this.course?.registrationTypeId,
      },
    });

    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }

    if (role === 'error') {
      this.doneCourseRegistration(false);
      this.changeDetectorRef.detectChanges();
      return;
    }

    if (role === 'confirm') {
      this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_COURSE_REGISTERED, [
        'Kurs ID: ' + this.course.id + ' Kursname: ' + this.course.name,
      ]);
      this.doneCourseRegistration(true);
      this.course.isRegistered = true;
      this.registerForTemplateEvent.emit(this.course.courseTemplate.id);
      this.changeDetectorRef.detectChanges();
      return;
    }
  }

  public async courseRegistrationButtonClicked(): Promise<void> {
    if (this.user) {
      if (this.course.url !== null) {
        this.openCourseRegistrationUrl();
      }
      if (this.course.url === null) {
        this.openCourseRegistrationModal();
      }
      return;
    }
    await this.openLoginSuggestionModal();
  }

  private async openLoginSuggestionModal(): Promise<void> {
    let modal = await this.modalCtrl.create({
      component: InfoModalComponent,
      componentProps: {
        text: LOGIN_SUGGESTION_TEXT,
        formatHtml: true,
      },
      cssClass: 'small-modal',
    });

    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      setTimeout(() => this.modalCtrl.dismiss(null, 'cancel'), 500);
      return;
    }
    return;
  }

  private openCourseRegistrationUrl(): void {
    window.open(this.course.url, '_blank');
  }

  private async doneCourseRegistration(isSuccess: boolean): Promise<void> {
    let modal = await this.modalCtrl.create({
      component: InfoModalComponent,
      cssClass: 'small-modal',
      componentProps: {
        text: isSuccess === true ? this.THANKS_FOR_YOUR_REGISTRATION_MESSAGE_TEXT : this.STANDARD_ERROR_TEXT,
        isError: !isSuccess,
      },
    });

    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }
  }

  protected readonly advancedSearchTerm = ADVANCED_SEARCH_TERM;
  protected readonly distanceValues = DISTANCE_VALUES;
  protected readonly fundingOption = FUNDING_OPTIONS;
  protected readonly qualificationTypeSelectText = QUALIFICATION_TYPE_SELECT_COURSE_TEXT;
  protected readonly regionalSearchTerm = REGIONAL_SEARCH_TERM;
  protected readonly learnMethod = LEARN_METHODS;
}
