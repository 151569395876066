import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { RequestStatus } from 'app/models/request-status.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(private http: HttpClient) {}
  public async reportErrorMessage(errorMessage: ErrorMessageParameter): Promise<void> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(errorMessage);
      const requestStatusObservable = this.http.post<RequestStatus>(environment.apiUrlErrorMessage, body, options);
      await lastValueFrom(requestStatusObservable);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }
}
