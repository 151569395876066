export class StorableCourseTemplateTreeBranch {
  public zip: string;
  public distance: number;
  public countryWide: boolean;
  public typeId: number;
  public requirementQualificationTypeId: number;
  public requirementQualificationCategoryId: number;
  public targetQualificationCategoryId: number;
  public templateIds: number[];
  public storageId?: number;
}

export const STORABLE_COURSE_TEMPLATE_TREE_BRANCH_TYPES = {
  FAVORITE: 1,
  LIKE: 2,
  DISLIKE: 3,
};
