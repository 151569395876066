// logical names
export const IS_USER_AGREEMENT_AGREED_LOGICAL_NAME = 'isUserAgreementAgreed-V1-0-0';
export const USER_DATA_LOGICAL_NAME = 'userData-V1-0-0';
export const HAS_CAREER_SEARCHED_LOGICAL_NAME = 'hasCareerSearched-V1-0-0';
export const AMOUNT_OF_PREVIOUS_CAREER_BRANCH_LOCAL_LOGICAL_NAME = 'previousCareerBranchLocal-V1-0-0';
export const AMOUNT_OF_PREVIOUS_CAREER_BRANCH_SESSION_LOGICAL_NAME = 'previousCareerBranchSession-V1-0-0';
export const SELECTED_COURSE_TEMPLATE_IDS_LOGICAL_NAME = 'selectedCourseTemplateIds-V1-0-0';
export const ACTIVE_CHAT_SESSION_LOGICAL_NAME = 'activeChatSession-V1-0-0';
export const PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME = 'previousCourseTemplateTreeParameters-V1-0-0';
export const PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME = 'previousCourseSearchParameters-V1-0-0';
export const ARE_TOOLTIPS_ACTIVE = 'areTooltipsActive-V1-0-0';
export const SHOWN_SESSION_TOOLTIPS = 'shownSessionTooltips-V1-0-0';
export const CAREER_SEARCH_PARAMETER_KEYS = {
  distance: 'range',
  requirementQualificationCategory: 'requirementQualificationCategoryId',
  targetQualificationCategory: 'targetQualificationCategoryId',
  requirementQualificationType: 'requirementQualificationTypeId',
};
export const CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY = 'careerAlgorithmParameters-V1-0-0';
export const SEARCH_PARAMETER_KEYS = {
  distance: 'range',
  qualificationCategory: 'qualificationCategoryId',
  qualificationType: 'qualificationTypeId',
  fullTextSearch: 'fullTextSearch',
  learnMethodId: 'learnMethodId',
  fundable: 'fundable',
};
export const IS_FIRST_HOME_VISIT_IN_SESSION = 'isFirstHomeVisitInSession-V1-0-0';
