import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class FormHelper {
  constructor() {}

  public nextInput(formGroup: FormGroup, event: Event, controlName: string): void {
    event.preventDefault();
    const formControl = formGroup.get(controlName);
    if (formControl) {
      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLElement;
      if (inputElement) {
        const ionInput = inputElement.querySelector('input');
        if (ionInput) {
          ionInput.focus();
          return;
        }
        inputElement.focus();
        return;
      }
    }
  }
}
