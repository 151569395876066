import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { InfoModalComponent } from 'app/components/layout/info-modal/info-modal.component';
import { CourseHelper } from 'app/helpers/course-helper';
import { MatomoHelper } from 'app/helpers/matomo-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { LOGIN_SUGGESTION_TEXT } from 'app/models/constants-app-texts';
import { WATCH_LIST_LIMIT } from 'app/models/constants-app-values';
import { MATOMO_LOGICAL_NAME_COURSE_STORED } from 'app/models/constants-matomo';
import { STANDARD_ERROR_CODE, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { Course } from 'app/models/course.model';
import { CourseParameter } from 'app/models/course_parameter.model';
import { Template } from 'app/models/template.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { User } from 'app/models/user.model';
import { GetCoursesService } from 'app/services/get-courses.service';
import { UserWatchlistService } from 'app/services/user-watchlist.service';
import { UserCourseRegistrationService } from './../../../services/user-course-registration.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.page.html',
  styleUrls: ['./course.page.scss'],
})
export class CoursePage implements AfterViewInit {
  public isLoading = true;
  public course: Course = new Course();
  public userCourseRegistrations: UserCourseRegistration[] = [];
  public isLoggedIn = false;
  public isButtonCooldown = false;
  public isAtLikeLimit = false;
  public selectedCourseTemplateIds: number[] = [];
  public user: User = null;

  constructor(
    public courseHelper: CourseHelper,
    private route: ActivatedRoute,
    private courseService: GetCoursesService,
    private matomoHelper: MatomoHelper,
    private modalCtrl: ModalController,
    private userCourseRegistrationService: UserCourseRegistrationService,
    private userHelper: UserHelper,
    private userWatchlistService: UserWatchlistService,
  ) {}

  async ngAfterViewInit() {
    await this.searchCourse();
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
      await this.loadRegisteredCourses();
    }
    await this.loadSelectedCourses();
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public isLikingDisabled(): boolean {
    return (
      this.isButtonCooldown || (this.checkIfLikeClickAddsLike(this.course.courseTemplate.id) && this.isAtLikeLimit)
    );
  }

  public checkIfLikeClickAddsLike(courseId: number): boolean {
    if (this.courseHelper.isFavoriteCourse(courseId, this.selectedCourseTemplateIds)) {
      return false;
    } else {
      return true;
    }
  }

  public async toggleCourseFavoriteButtonClicked(currentCourseTemplateId: number): Promise<void> {
    if (this.isLoggedIn === true) {
      await this.toggleCourseFavorite(currentCourseTemplateId);
      return;
    }
    await this.openLoginSuggestionModal();
  }

  private async openLoginSuggestionModal(): Promise<void> {
    let modal = await this.modalCtrl.create({
      component: InfoModalComponent,
      componentProps: {
        text: LOGIN_SUGGESTION_TEXT,
        formatHtml: true,
      },
      cssClass: 'small-modal',
    });

    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }
  }

  private async toggleCourseFavorite(currentCourseTemplateId: number): Promise<void> {
    if (this.courseHelper.isFavoriteCourse(currentCourseTemplateId, this.selectedCourseTemplateIds) === false) {
      this.isButtonCooldown = true;
      this.selectedCourseTemplateIds.push(currentCourseTemplateId);
      await this.userWatchlistService.storeCourse(this.user, currentCourseTemplateId);
      this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_COURSE_STORED, [
        {
          courseTemplateId: this.course.courseTemplate.id,
          courseTemplateName: this.course.courseTemplate.name,
        },
      ]);

      this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
      setTimeout(() => (this.isButtonCooldown = false), 1000);
      return;
    }
    const courseToRemove = this.selectedCourseTemplateIds.find((x) => x === currentCourseTemplateId);
    if (courseToRemove !== undefined) {
      this.isButtonCooldown = true;
      this.selectedCourseTemplateIds.splice(this.selectedCourseTemplateIds.indexOf(courseToRemove), 1);
      await this.userWatchlistService.deleteStoredCourse(this.user, courseToRemove);
      this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
      setTimeout(() => (this.isButtonCooldown = false), 1000);
      return;
    }
  }

  private async searchCourse(): Promise<void> {
    this.isLoading = true;

    const courseParameter = new CourseParameter();
    courseParameter.courseId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    const coursesResult = await this.courseService.searchCourses(courseParameter);

    if (coursesResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.courseService.errorMessage);
    }

    this.course = coursesResult[0];

    this.isLoading = false;
  }

  private async loadSelectedCourses(): Promise<void> {
    this.isLoading = true;

    const selectedCoursesPreferenceResult = await this.userWatchlistService.loadStoredCourses(this.user);

    if (selectedCoursesPreferenceResult === 401) {
      this.isLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (selectedCoursesPreferenceResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.userWatchlistService.errorMessage);
    }

    const selectedCourseTemplateIds = [];

    for (const selectedCourseTemplate of selectedCoursesPreferenceResult as Template[]) {
      selectedCourseTemplateIds.push(selectedCourseTemplate.id);
    }
    this.selectedCourseTemplateIds = selectedCourseTemplateIds;

    this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
    this.isLoading = false;
  }

  private async loadRegisteredCourses(): Promise<void> {
    this.isLoading = true;
    const userCourseRegistrationsPreferenceResult = await this.userCourseRegistrationService.getUserCourseRegistrations(
      this.user,
    );

    if (userCourseRegistrationsPreferenceResult === 401) {
      this.isLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (userCourseRegistrationsPreferenceResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.userCourseRegistrationService.errorMessage);
    }
    if (userCourseRegistrationsPreferenceResult === STANDARD_ERROR_CODE) {
      this.isLoading = false;
      console.error(this.userCourseRegistrationService.errorMessage);
    }
    const userCourseRegistrations: UserCourseRegistration[] = [];

    for (const userCourseRegistration of userCourseRegistrationsPreferenceResult as UserCourseRegistration[]) {
      userCourseRegistrations.push(userCourseRegistration);
    }

    this.userCourseRegistrations = userCourseRegistrations;

    const registeredCourse = this.userCourseRegistrations.find((x) => x.id === this.course.id);
    if (registeredCourse) {
      this.course.isRegistered = true;
    }

    this.isLoading = false;
  }

  private checkIfLikesAreAtLimit(): boolean {
    if (this.selectedCourseTemplateIds.length >= WATCH_LIST_LIMIT) {
      return true;
    }
    return false;
  }
}
