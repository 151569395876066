// static texts
export const THANKS_FOR_YOUR_REGISTRATION_MESSAGE_TEXT =
  'Vielen Dank! Das zuständige Institut wird sich in Kürze bei dir melden.';
export const LOADING_TEXT = 'Die Daten werden geladen ...';
export const USER_AGREEMENT_QUESTION = `Ich bestätige, die <a style="color:inherit" href="#/tabs/nutzung">Nutzungsbedingungen</a> gelesen zu haben und willige
hiermit ein.`;
export const DATA_PROTECTION_QUESTION = `Ich bestätige, den <a style="color:inherit" href="#/tabs/datenschutz">Datenschutz</a> gelesen zu haben und willige
hiermit ein.`;
export const QUALIFICATION_CATEGORY_UNSELECTED = 'Gewerkeübergreifend';
export const REGIONAL_SEARCH_TERM = 'Regionalsuche';
export const ADVANCED_SEARCH_TERM = 'Erweiterte Suche';
export const DIFFERENT_PAYER_ADDRESS_TERM = 'Abweichende Rechnungsadresse';
export const REQUIREMENT_QUALIFICATION_CATEGORY_SELECT_COURSE_TEXT = 'Gewerk';
export const REQUIREMENT_QUALIFICATION_CATEGORY_SELECT_CAREER_TEXT = 'Mein Gewerk';
export const TARGET_QUALIFICATION_CATEGORY_SELECT_TEXT = 'Wohin will ich?';
export const QUALIFICATION_TYPE_SELECT_COURSE_TEXT = 'Bildungsstand';
export const QUALIFICATION_TYPE_SELECT_CAREER_TEXT = 'Mein Bildungsstand';
export const COUNTRY_WIDE_TEXT = 'Deutschlandweit';
export const OCCUPANCY_STATUS_TEXT = ['Viel Platz', 'Wenig Platz', 'Voll'];
export const LEARN_METHODS = ['Egal', 'Präsenz', 'Online', 'Hybrid'];
export const FUNDING_OPTIONS = ['Egal', 'Gefördert', 'Nicht gefördert'];
export const SEARCH_PARAMETER_TERMS = {
  distance: 'Reichweite',
  qualificationCategory: 'Gewerk',
  qualificationType: 'Bildungsstand',
  fullTextSearch: 'Suchbegriff',
  learnMethod: 'Lernmethode',
  fundable: 'Förderung',
};
export const CAREER_SEARCH_PARAMETER_TERMS = {
  distance: 'Reichweite',
  requirementQualificationCategory: 'Mein Gewerk',
  targetQualificationCategory: 'Mein Ziel',
  requirementQualificationType: 'Mein Bildungsstand',
};
export const STUDENT_CAREER_NOTIFICATION = `Hallo. Mir ist aufgefallen, dass du mit einem Schulabschluss suchst,
ohne ein Ziel festgelegt zu haben. Du kannst dich auch mit dem
<a aria-label="zum Lehrstellenradar" style="color: var(--white)" target="_self" href=\"https://www.lehrstellen-radar.de/\">Lehrstellenradar</a>
nach Ausbildungsplätzen umschauen.`;
export const WATCH_LIST_LIMIT_CAREER_NOTIFICATION = `Hallo. Deine Karriere-Merkliste scheint voll zu sein.
Falls du weitere Karrieremöglichkeiten hinzufügen möchtest, kannst du
<a aria-label="zur Merkliste" style="color: var(--white)" target="_self" href="#/tabs/merkliste">hier</a> vorherige Pfade aussortieren.`;
export const WATCH_LIST_LIMIT_COURSE_NOTIFICATION = `Hallo. Deine Kurs-Merkliste scheint voll zu sein.
Falls du weitere Kurse hinzufügen möchtest, kannst du
<a aria-label="zur Merkliste" style="color: var(--white)" target="_self" href="#/tabs/merkliste">hier</a> vorherige Kurse aussortieren.`;
export const LOGIN_SUGGESTION_TEXT = `Du bist nicht eingeloggt.
        Möchtest du dich <a class="color-primary" aria-label="zur Registrierung" href="#/tabs/registrierung">registrieren</a> oder
        <a class="color-primary" aria-label="zum Login" href="#/tabs/login">einloggen</a>, um die Merkliste zu verwenden?`;
