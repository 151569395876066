import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChatWindowComponent } from 'app/components/layout/chat-window/chat-window.component';
import { InfoModalComponent } from 'app/components/layout/info-modal/info-modal.component';
import { CourseHelper } from 'app/helpers/course-helper';
import { DateHelper } from 'app/helpers/date-helper';
import { MatomoHelper } from 'app/helpers/matomo-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { MessageType } from 'app/models/chat-bot-message.model';
import { LOGIN_SUGGESTION_TEXT, WATCH_LIST_LIMIT_COURSE_NOTIFICATION } from 'app/models/constants-app-texts';
import { REGISTRATION_TYPE_ID, WATCH_LIST_LIMIT } from 'app/models/constants-app-values';
import { MATOMO_LOGICAL_NAME_COURSE_STORED } from 'app/models/constants-matomo';
import { CourseTemplate } from 'app/models/course-template';
import { CourseParameter } from 'app/models/course_parameter.model';
import { Template } from 'app/models/template.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { User } from 'app/models/user.model';
import { UserCourseRegistrationService } from 'app/services/user-course-registration.service';
import { UserWatchlistService } from 'app/services/user-watchlist.service';
import { STANDARD_ERROR_CODE, SYSTEM_ERROR_CODE } from '../../../models/constants-status';
import { Course } from '../../../models/course.model';
import { BrowserInteractionHelper } from './../../../helpers/browser-interaction-helper';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent implements OnInit, OnChanges {
  @Input() courses: Course[] = [];
  @Input() searchFailed = false;
  @Input() hasSearched = false;
  @Input() isActiveTab = false;
  @Input() courseParameter = new CourseParameter();

  public isLoading = true;
  public courseTemplates: CourseTemplate[];
  public selectedCourseTemplateIds: number[] = [];
  public isLoggedIn = false;
  public isButtonCooldown = false;
  public isAtLikeLimit = false;
  public user: User = null;
  public userCourseRegistrations: UserCourseRegistration[] = [];

  constructor(
    public courseHelper: CourseHelper,
    public dateHelper: DateHelper,
    private browserInteractionHelper: BrowserInteractionHelper,
    private chatWindowComponent: ChatWindowComponent,
    private matomoHelper: MatomoHelper,
    private modalCtrl: ModalController,
    private userCourseRegistrationService: UserCourseRegistrationService,
    private userWatchlistService: UserWatchlistService,
    private userHelper: UserHelper,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
      await this.loadRegisteredCourses();
    }

    this.courseTemplates = this.courseHelper.getCourseTemplates(this.courses);
    this.loadSelectedCourses();
  }

  async ngOnChanges() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
    }

    this.courseTemplates = this.courseHelper.getCourseTemplates(this.courses);
    this.loadSelectedCourses();
  }

  public setTemplateToCannotRegister(templateId: number): void {
    this.courses
      .filter((x) => x.courseTemplate.id === templateId)
      .forEach((x) => (x.courseTemplate.registrationTypeId = REGISTRATION_TYPE_ID.noRegistration));
  }

  public async doubleClickToggleFavorite(currentCourseTemplateId: number): Promise<void> {
    if (
      this.browserInteractionHelper.isDoubleClick() === true &&
      this.isLikingDisabled(currentCourseTemplateId) === false
    ) {
      this.toggleCourseFavoriteButtonClicked(currentCourseTemplateId);
    }
  }

  public checkIfLikeClickAddsLike(courseId: number): boolean {
    if (this.courseHelper.isFavoriteCourse(courseId, this.selectedCourseTemplateIds)) {
      return false;
    } else {
      return true;
    }
  }

  public isLikingDisabled(currentCourseTemplateId: number): boolean {
    return (
      this.isLoading ||
      this.isButtonCooldown ||
      (this.checkIfLikeClickAddsLike(currentCourseTemplateId) && this.isAtLikeLimit)
    );
  }

  public async toggleCourseFavoriteButtonClicked(currentCourseTemplateId: number): Promise<void> {
    if (this.isLoggedIn === true) {
      await this.toggleCourseFavorite(currentCourseTemplateId);
      return;
    }
    await this.openLoginSuggestionModal();
  }

  private async openLoginSuggestionModal(): Promise<void> {
    let modal = await this.modalCtrl.create({
      component: InfoModalComponent,
      componentProps: {
        text: LOGIN_SUGGESTION_TEXT,
        formatHtml: true,
      },
      cssClass: 'small-modal',
    });

    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }
  }

  private async toggleCourseFavorite(currentCourseTemplateId: number): Promise<void> {
    if (this.courseHelper.isFavoriteCourse(currentCourseTemplateId, this.selectedCourseTemplateIds) === false) {
      this.isButtonCooldown = true;
      this.selectedCourseTemplateIds.push(currentCourseTemplateId);
      await this.userWatchlistService.storeCourse(this.user, currentCourseTemplateId);
      this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_COURSE_STORED, [
        {
          courseTemplateId: currentCourseTemplateId,
          courseTemplateName: this.courseTemplates.find((x) => (x.id = currentCourseTemplateId)).name,
        },
      ]);

      this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
      setTimeout(() => (this.isButtonCooldown = false), 1000);
      return;
    }
    const courseToRemove = this.selectedCourseTemplateIds.find((x) => x === currentCourseTemplateId);
    if (courseToRemove !== undefined) {
      this.isButtonCooldown = true;
      this.selectedCourseTemplateIds.splice(this.selectedCourseTemplateIds.indexOf(courseToRemove), 1);
      await this.userWatchlistService.deleteStoredCourse(this.user, courseToRemove);
      this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
      setTimeout(() => (this.isButtonCooldown = false), 1000);
      return;
    }
  }

  private async loadSelectedCourses(): Promise<void> {
    this.isLoading = true;

    const selectedCoursesPreferenceResult = await this.userWatchlistService.loadStoredCourses(this.user);

    if (selectedCoursesPreferenceResult === 401) {
      this.isLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (selectedCoursesPreferenceResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.userWatchlistService.errorMessage);
    }

    const selectedCourseTemplateIds = [];

    for (const selectedCourseTemplate of selectedCoursesPreferenceResult as Template[]) {
      selectedCourseTemplateIds.push(selectedCourseTemplate.id);
    }
    this.selectedCourseTemplateIds = selectedCourseTemplateIds;

    this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
    this.isLoading = false;
  }

  private checkIfLikesAreAtLimit(): boolean {
    if (this.selectedCourseTemplateIds.length >= WATCH_LIST_LIMIT) {
      this.chatWindowComponent.receiveSystemMessage({
        text: WATCH_LIST_LIMIT_COURSE_NOTIFICATION,
        messageType: MessageType.SystemMessage,
      });
      return true;
    }
    if (this.selectedCourseTemplateIds.length < WATCH_LIST_LIMIT) {
      return false;
    }
  }

  private async loadRegisteredCourses(): Promise<void> {
    this.isLoading = true;
    const userCourseRegistrationsPreferenceResult = await this.userCourseRegistrationService.getUserCourseRegistrations(
      this.user,
    );

    if (userCourseRegistrationsPreferenceResult === 401) {
      this.isLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (userCourseRegistrationsPreferenceResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.userCourseRegistrationService.errorMessage);
    }
    if (userCourseRegistrationsPreferenceResult === STANDARD_ERROR_CODE) {
      this.isLoading = false;
      console.error(this.userCourseRegistrationService.errorMessage);
    }
    const userCourseRegistrations: UserCourseRegistration[] = [];

    for (const userCourseRegistration of userCourseRegistrationsPreferenceResult as UserCourseRegistration[]) {
      userCourseRegistrations.push(userCourseRegistration);
    }

    this.userCourseRegistrations = userCourseRegistrations;

    for (const userCourseRegistration of this.userCourseRegistrations) {
      const registeredCourse = this.courses.find((x) => x.id === userCourseRegistration.id);
      if (registeredCourse) {
        const coursesInTemplate = this.courses.filter(
          (x) => x.courseTemplate.id === registeredCourse.courseTemplate.id,
        );
        for (const courseInTemplate of coursesInTemplate) {
          courseInTemplate.courseTemplate.registrationTypeId = REGISTRATION_TYPE_ID.noRegistration;
        }

        this.courses.find((x) => x.id === userCourseRegistration.id).isRegistered = true;
      }
    }

    this.isLoading = false;
  }
}
