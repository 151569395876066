import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceHelper } from 'app/helpers/device-helper';
import { MatomoHelper } from 'app/helpers/matomo-helper';
import { SelectionHelper } from 'app/helpers/selection-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import {
  ADVANCED_SEARCH_TERM,
  FUNDING_OPTIONS,
  LEARN_METHODS,
  QUALIFICATION_CATEGORY_UNSELECTED,
  QUALIFICATION_TYPE_SELECT_COURSE_TEXT,
  REGIONAL_SEARCH_TERM,
  REQUIREMENT_QUALIFICATION_CATEGORY_SELECT_COURSE_TEXT,
  USER_AGREEMENT_QUESTION,
} from 'app/models/constants-app-texts';
import {
  DEFAULT_COURSE_SEARCH_MAX_RESULTS_AMOUNT,
  DEFAULT_COURSE_SEARCH_STARTING_POSITION,
  DISTANCE_VALUES,
} from 'app/models/constants-app-values';
import {
  IS_USER_AGREEMENT_AGREED_LOGICAL_NAME,
  PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME,
} from 'app/models/constants-logical-names';
import { MATOMO_LOGICAL_NAME_COURSE_SEARCH } from 'app/models/constants-matomo';
import { CourseParameter } from '../../../models/course_parameter.model';
import { QualificationCategory } from '../../../models/qualification-category.model';
import { QualificationType } from '../../../models/qualification-type.model';

@Component({
  selector: 'app-course-search',
  templateUrl: './course-search.component.html',
  styleUrls: ['./course-search.component.scss'],
})
export class CourseSearchComponent implements OnInit, AfterViewChecked {
  @Output() searchEvent = new EventEmitter<CourseParameter>();
  @Input() qualificationCategories: QualificationCategory[] = [];
  @Input() qualificationTypes: QualificationType[] = [];
  @Input() isSearchLoading = false;

  public readonly qualificationCategoryUnselected = QUALIFICATION_CATEGORY_UNSELECTED;
  public readonly distanceValues = DISTANCE_VALUES;
  public readonly learnMethod = LEARN_METHODS;
  public readonly fundingOption = FUNDING_OPTIONS;
  public readonly requirementQualificationCategorySelectText = REQUIREMENT_QUALIFICATION_CATEGORY_SELECT_COURSE_TEXT;
  public readonly qualificationTypeSelectText = QUALIFICATION_TYPE_SELECT_COURSE_TEXT;
  public readonly regionalSearchTerm = REGIONAL_SEARCH_TERM;
  public readonly advancedSearchTerm = ADVANCED_SEARCH_TERM;
  public readonly userAgreementQuestion = USER_AGREEMENT_QUESTION;

  public selectedQualificationCategory: QualificationCategory = null;
  public selectedQualificationType: QualificationType = null;
  public regionalSearchBadgeNumber: number = 0;
  public advancedSearchBadgeNumber: number = 0;
  public isRegionAccordionOpen: boolean = false;
  public isAdvancedAccordionOpen: boolean = false;
  public isCountryWide = true;
  public isLoggedIn = false;
  public isUserAgreementAgreed = false;

  private selectedLearnMethod = this.learnMethod[0];
  private selectedFunding = this.fundingOption[0];
  private selectedZip = '';
  private selectedDistance = 30;
  private selectedFullTextSearch = '';

  public formGroup: FormGroup = this.formBuilder.group(
    {
      isCountryWide: [this.isCountryWide],
      learnMethodSelect: [this.selectedLearnMethod, Validators.required],
      zipCodeInput: [{ value: '', disabled: true }],
      distanceSelect: [{ value: this.selectedDistance, disabled: true }],
      qualificationCategorySelect: [QUALIFICATION_CATEGORY_UNSELECTED],
      searchTermInput: [''],
      qualificationTypeSelect: [this.selectedQualificationType],
      fundableSelect: [this.selectedFunding, Validators.required],
      userAgreementCheckbox: [this.isLoggedIn, Validators.requiredTrue],
    },
    { validators: [this.hasSelectedAtLeastOneRequiredFormField(), Validators.required] },
  );

  constructor(
    public deviceHelper: DeviceHelper,
    public selectionHelper: SelectionHelper,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private matomoHelper: MatomoHelper,
    private storageHelper: StorageHelper,
    private userHelper: UserHelper,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    await this.deviceHelper.checkUserDevice();
  }

  async ngAfterViewInit() {
    await this.fillForm();
    this.isLoggedIn = await this.userHelper.checkForUser();
    this.formGroup.controls.userAgreementCheckbox.setValue(this.isLoggedIn);
    if (this.isLoggedIn === false) {
      this.formGroup.controls.userAgreementCheckbox.setValue(this.isUserAgreementAgreed);
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public async changeIsUserAgreementAgreed(isUserAgreementAgreed: any): Promise<void> {
    this.isUserAgreementAgreed = isUserAgreementAgreed;
    if (this.isUserAgreementAgreed === false) {
      this.storageHelper.setSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME, null);
    }
  }
  public changeSelectedLearnMethod(selectedLearnMethod: string): void {
    this.selectedLearnMethod = selectedLearnMethod;
  }
  public changeSelectedZip(selectedZip: string): void {
    this.selectedZip = selectedZip;
  }
  public changeSelectedDistance(selectedDistance: number): void {
    this.selectedDistance = selectedDistance;
  }
  public changeSelectedFullTextSearch(selectedFullTextSearch: string): void {
    this.selectedFullTextSearch = selectedFullTextSearch;
  }
  public changeSelectedFunding(selectedFunding: string): void {
    this.selectedFunding = selectedFunding;
  }
  public changeIsCountryWide(isCountryWide: any): void {
    this.isCountryWide = isCountryWide;
    if (this.isCountryWide === true) {
      this.formGroup.controls.distanceSelect.disable();
      this.formGroup.controls.zipCodeInput.disable();
    }
    if (this.isCountryWide === false) {
      this.formGroup.controls.distanceSelect.enable();
      this.formGroup.controls.zipCodeInput.enable();
    }
  }

  public changeSelectedQualificationCategory(qualificationCategory: QualificationCategory): void {
    this.selectedQualificationCategory = qualificationCategory;

    if (qualificationCategory === null) {
      this.formGroup.controls.qualificationCategorySelect.setValue(QUALIFICATION_CATEGORY_UNSELECTED);
    }
  }

  public changeSelectedQualificationType(qualificationType: QualificationType): void {
    this.selectedQualificationType = qualificationType;

    if (qualificationType === null) {
      this.formGroup.controls.qualificationTypeSelect.setValue(null);
    }
  }

  public toggleIsCountryWide(): void {
    this.formGroup.controls.isCountryWide.setValue(!this.formGroup.controls.isCountryWide.getRawValue());
    this.changeIsCountryWide(this.formGroup.controls.isCountryWide.getRawValue());
  }

  public changeAccordionStatus(event: any): void {
    //returns wenn event kein array ist, da ionChange (undokumentiert von Ionic)
    //auch auf Form änderungen innerhalb des Accordions feuert
    if (Array.isArray(event.detail.value) === false) {
      return;
    }

    const openedAccordions: string[] = event.detail.value;

    if (openedAccordions.find((x) => x === this.regionalSearchTerm) !== undefined) {
      this.isRegionAccordionOpen = true;
    } else {
      this.isRegionAccordionOpen = false;
    }
    if (openedAccordions.find((x) => x === this.advancedSearchTerm) !== undefined) {
      this.isAdvancedAccordionOpen = true;
    } else {
      this.isAdvancedAccordionOpen = false;
    }
  }

  public searchCourses(): void {
    if (this.formGroup.valid === false) {
      return;
    }

    const courseParameter = new CourseParameter();

    if (this.isCountryWide === false) {
      if (this.selectedZip !== '') {
        courseParameter.zip = this.selectedZip === '' ? null : this.selectedZip;
        courseParameter.distance = this.selectedDistance;
      }
    }
    if (this.isCountryWide === true) {
      courseParameter.zip = undefined;
      courseParameter.distance = undefined;
    }

    if (this.selectedLearnMethod === this.learnMethod[3]) {
      courseParameter.learnMethodId = 3;
    }
    if (this.selectedLearnMethod === this.learnMethod[2]) {
      courseParameter.learnMethodId = 2;
    }
    if (this.selectedLearnMethod === this.learnMethod[1]) {
      courseParameter.learnMethodId = 1;
    }
    if (this.selectedLearnMethod === this.learnMethod[0]) {
      courseParameter.learnMethodId = undefined;
    }
    if (this.selectedQualificationCategory !== undefined && this.selectedQualificationCategory !== null) {
      courseParameter.qualificationCategoryId = this.selectedQualificationCategory.id;
    }
    if (this.selectedFullTextSearch !== '') {
      courseParameter.fullTextSearch = this.selectedFullTextSearch;
    }
    if (this.selectedQualificationType !== undefined && this.selectedQualificationType !== null) {
      courseParameter.qualificationTypeId = this.selectedQualificationType.id;
    }
    if (this.selectedFunding === this.fundingOption[2]) {
      courseParameter.fundable = 2;
    }
    if (this.selectedFunding === this.fundingOption[1]) {
      courseParameter.fundable = 1;
    }
    if (this.selectedFunding === this.fundingOption[0]) {
      courseParameter.fundable = undefined;
    }

    this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_COURSE_SEARCH, []);

    courseParameter.partitionInstituteTemplateCount = 3;
    courseParameter.maxResults = DEFAULT_COURSE_SEARCH_MAX_RESULTS_AMOUNT;
    courseParameter.startIndex = DEFAULT_COURSE_SEARCH_STARTING_POSITION;

    this.storageHelper.setSessionStorage(PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME, JSON.stringify(courseParameter));
    this.storageHelper.setSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME, this.isUserAgreementAgreed);

    this.searchEvent.emit(courseParameter);
  }

  private async fillForm(): Promise<void> {
    const courseParameter: CourseParameter = JSON.parse(
      await this.storageHelper.getSessionStorage(PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME),
    );
    this.isUserAgreementAgreed = JSON.parse(
      await this.storageHelper.getSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME),
    );
    if (this.isUserAgreementAgreed === null) {
      this.isUserAgreementAgreed = false;
    }

    if (courseParameter === null) {
      return;
    }

    if (courseParameter.distance === undefined && courseParameter.zip === undefined) {
      this.changeIsCountryWide(true);
      this.formGroup.controls.isCountryWide.setValue(true);
      this.formGroup.controls.distanceSelect.disable();
      this.formGroup.controls.zipCodeInput.disable();
    }

    if (courseParameter.zip !== undefined) {
      this.changeIsCountryWide(false);
      this.changeSelectedDistance(courseParameter.distance);
      this.formGroup.controls.distanceSelect.setValue(courseParameter.distance);
      this.changeSelectedZip(courseParameter.zip);
      this.formGroup.controls.zipCodeInput.setValue(courseParameter.zip);
      this.formGroup.controls.isCountryWide.setValue(false);
      this.formGroup.controls.distanceSelect.enable();
      this.formGroup.controls.zipCodeInput.enable();
    }

    if (courseParameter.qualificationCategoryId !== undefined) {
      this.selectedQualificationCategory = this.qualificationCategories.find(
        (x) => x.id === courseParameter.qualificationCategoryId,
      );
      this.formGroup.controls.qualificationCategorySelect.setValue(this.selectedQualificationCategory);
    }

    if (courseParameter.qualificationTypeId !== undefined) {
      this.selectedQualificationType = this.qualificationTypes.find(
        (x) => x.id === courseParameter.qualificationTypeId,
      );
      this.formGroup.controls.qualificationTypeSelect.setValue(this.selectedQualificationType);
    }

    if (courseParameter.fullTextSearch !== undefined) {
      this.selectedFullTextSearch = courseParameter.fullTextSearch;
      this.formGroup.controls.searchTermInput.setValue(this.selectedFullTextSearch);
    }

    if (courseParameter.learnMethodId !== undefined) {
      this.selectedLearnMethod = this.learnMethod[courseParameter.learnMethodId];
      this.formGroup.controls.learnMethodSelect.setValue(this.selectedLearnMethod);
    }
    if (courseParameter.learnMethodId === undefined) {
      this.selectedLearnMethod = this.learnMethod[0];
      this.formGroup.controls.learnMethodSelect.setValue(this.selectedLearnMethod);
    }

    if (courseParameter.fundable !== undefined) {
      this.selectedFunding = this.fundingOption[courseParameter.fundable];
      this.formGroup.controls.fundableSelect.setValue(this.selectedFunding);
    }
    if (courseParameter.fundable === undefined) {
      this.selectedFunding = this.fundingOption[0];
      this.formGroup.controls.fundableSelect.setValue(this.selectedFunding);
    }
  }

  private hasSelectedAtLeastOneRequiredFormField(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.changeBadgeNumbers();
      const userAgreementCheckbox = control.get('userAgreementCheckbox').value;
      const zipCodeInput = control.get('zipCodeInput').value;
      const searchTermInput: string = control.get('searchTermInput').value;
      const isCountryWide = control.get('isCountryWide').value;

      const isValidZipCode = zipCodeInput.length === 5 && /^[0-9]*$/.test(zipCodeInput);
      const isValidSearchTerm = searchTermInput.length >= 3 && searchTermInput.length <= 255;
      const isValidQualificationCategory =
        control.get('qualificationCategorySelect').value !== null &&
        control.get('qualificationCategorySelect').value !== QUALIFICATION_CATEGORY_UNSELECTED;

      const isAtLeastOne =
        (isValidZipCode && !isCountryWide && zipCodeInput.length !== 0) ||
        isValidQualificationCategory ||
        isValidSearchTerm;

      const isZipValidity = zipCodeInput.length === 5 && /^[0-9]*$/.test(zipCodeInput);
      const isZipToCountryWideValid = isZipValidity || isCountryWide;

      const isSearchTermValid = (searchTermInput.length < 3 && searchTermInput.length > 0) === false;

      const isUserAgreementTrue = userAgreementCheckbox === true;

      const isValid = isAtLeastOne && isZipToCountryWideValid && isSearchTermValid && isUserAgreementTrue;

      return !isValid ? { hasSelectedAtOne: true } : null;
    };
  }

  private changeBadgeNumbers(): void {
    let regionalNumber = 0;
    let advancedNumber = 0;

    if (this.isCountryWide === false) {
      regionalNumber += 1;
    }

    if (this.formGroup?.controls.qualificationTypeSelect.value !== null) {
      advancedNumber += 1;
    }
    if (this.formGroup?.controls.fundableSelect.value !== this.fundingOption[0]) {
      advancedNumber += 1;
    }
    if (this.formGroup?.controls.learnMethodSelect.value !== this.learnMethod[0]) {
      advancedNumber += 1;
    }

    this.regionalSearchBadgeNumber = regionalNumber;
    this.advancedSearchBadgeNumber = advancedNumber;
  }
}
