<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
  *ngIf="isCourseLoading === true || areSelectedCoursesLoading === true"></ion-img>
<ion-card class="base-content-top-margin info-box" *ngIf="isLoggedIn === false">
  <div class="stretcher-div"></div>
  <ion-row>
    <ion-col size="9">
      <h3 class="ion-margin">
        Du möchtest dir deine Karrierepläne merken?
      </h3>
      <h3 class="ion-margin">
        Logge dich <a aria-label="zum Login" class="text-white" href="#/tabs/login">hier</a> ein.
      </h3>
    </ion-col>
    <ion-col size="3" class="display-grid">
      <i alt="Raketen-Icon" class="icon-rocket-outline icon-color-blue"></i>
    </ion-col>
  </ion-row>
</ion-card>
<ion-card class="base-content-top-margin" *ngIf="hasRequestFailed === true && areSelectedCoursesLoading === false">
  <h1 class="ion-text-center ion-margin error-text">{{standardErrorText}}</h1>
</ion-card>

<ion-card class="base-content-top-margin info-box"
  *ngIf="isLoggedIn === true &&
  hasRequestFailed === false && registeredCourseTemplates.length === 0 && displayedCourseTemplateTreeBranches.length === 0 && courseTemplates.length === 0 && areSelectedCoursesLoading === false">
  <div class="stretcher-div"></div>
  <ion-row>
    <ion-col size="9">
      <h3 class="ion-margin">
        Es wurden noch keine Kurse oder Kurspfade zum Merken hinzugefügt.
      </h3>
      <h3 class="ion-margin">
        Suche dir jetzt <a class="text-white" aria-label="zur Kurssuche" href="#/tabs/suche">einen Kurs</a> oder <a
          aria-label="zur Karrieresuche" class="text-white" href="#/tabs/karrierevorschlage">eine Karriere</a>.
      </h3>
    </ion-col>
    <ion-col size="3" class="display-grid">
      <i alt="Raketen-Icon" class="icon-rocket-outline icon-color-blue"></i>
    </ion-col>
  </ion-row>
</ion-card>
<ion-card class="base-content-top-margin" *ngIf="hasRequestFailed === true && areSelectedCoursesLoading === false">
  <div class="stretcher-div"></div>
  <h1 class="ion-text-center ion-margin error-text">{{standardErrorText}}</h1>
</ion-card>

<ion-card class="card-tabs base-content-top-margin justify-self-center ion-margin-end fill-available-width"
  *ngIf="(displayedCourseTemplateTreeBranches.length > 0 || courseTemplates.length > 0 || registeredCourseTemplates.length > 0)">
  <ion-segment value="{{currentWatchListTab}}" (ionChange)="changeWatchListTab($any($event.target).value)">
    <ion-segment-button value="Kurse">
      <ion-label>Kurse</ion-label>
    </ion-segment-button>
    <ion-segment-button value="Kurspfade">
      <ion-label>Kurspfade</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-card>

<ng-container *ngIf="currentWatchListTab === 'Kurse'">
  <ion-card class="info-box" *ngIf="displayedCourseTemplateTreeBranches.length !== 0 && courseTemplates.length === 0 &&
    registeredCourseTemplates.length === 0 && areSelectedCoursesLoading === false">
    <div class="stretcher-div"></div>
    <ion-row>
      <ion-col size="9">
        <h3 class="ion-margin">
          Es wurden noch keine Kurse zum Merken hinzugefügt.
        </h3>
        <h3 class="ion-margin">
          Suche dir jetzt <a aria-label="zur Kurssuche" class="text-white" href="#/tabs/suche">einen Kurs</a>.
        </h3>
      </ion-col>
      <ion-col size="3" class="display-grid">
        <i alt="Raketen-Icon" class="icon-rocket-outline icon-color-blue"></i>
      </ion-col>
    </ion-row>
  </ion-card>

  <ng-container class="ion-margin-bottom" *ngIf="registeredCourseTemplates.length > 0">
    <ion-card *ngIf="courseTemplates.length > 0"
      class="section-marker-card ion-justify-content-center display-flex">
      <h6 class="ion-margin-start ion-margin-end ion-margin-top"><strong>Anmeldung angefragt</strong></h6><ion-text
        class="beta-icon ion-align-items-center display-flex">beta</ion-text>
    </ion-card>
    <ion-card *ngFor="let registeredCourseTemplateItem of registeredCourseTemplates">
      <div class="stretcher-div"></div>
      <ion-row>
        <ion-col size-lg="10" size="9"
          (click)="openCourseDetailsUnloadedCourse(registeredCourseTemplateItem.id)">
          <ion-item lines="none">
            <ion-label class="truncate-text">{{ registeredCourseTemplateItem?.name }}</ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="1" (click)="openCourseDetailsUnloadedCourse(registeredCourseTemplateItem.id)">
          <div class="center-content-div">
            <ion-icon class="color-primary" name="information-circle"></ion-icon>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </ng-container>

  <ng-container *ngIf="courseTemplates.length > 0">
    <ion-card *ngIf="registeredCourseTemplates.length > 0"
      class="section-marker-card ion-justify-content-center display-flex additional-section-marker-card">
      <h6 class="ion-margin-start ion-margin-end ion-margin-top"><strong>Gemerkte Kurse</strong></h6><ion-text
        class="beta-icon ion-align-items-center display-flex">beta</ion-text>
    </ion-card>
    <ion-card *ngFor="let courseTemplateItem of courseTemplates">
      <div class="stretcher-div"></div>
      <ion-row>
        <ion-col size-lg="10" size="9" (click)="openCourseDetailsModalPreLoadedCourse(courseTemplateItem)">
          <ion-item lines="none">
            <ion-label class="truncate-text">{{ courseTemplateItem.name }}</ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="1" (click)="openCourseDetailsModalPreLoadedCourse(courseTemplateItem)">
          <div class="center-content-div">
            <ion-icon class="color-primary" name="information-circle"></ion-icon>
          </div>
        </ion-col>
        <ion-col size-lg="1" size="2">
          <div class="ion-text-end">
            <ion-button class="fav-button fav-button-small-card" (click)="openConfirmationModal(courseTemplateItem)">
              <i class="button-icon icon-bookmark-2 icon-color-white" aria-label="lesezeichen entfernen"></i>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </ng-container>
</ng-container>

<ng-container *ngIf="currentWatchListTab === 'Kurspfade'">
  <ion-card class="info-box" *ngIf="displayedCourseTemplateTreeBranches.length === 0 &&
    (courseTemplates.length !== 0 || registeredCourseTemplates.length !== 0) &&
    areSelectedCoursesLoading === false">
    <div class="stretcher-div"></div>
    <ion-row>
      <ion-col size="9">
        <h3 class="ion-margin">
          Es wurden noch keine Kurspfade zum Merken hinzugefügt.
        </h3>
        <h3 class="ion-margin">
          Suche dir jetzt <a aria-label="zur Karrieresuche" class="text-white" href="#/tabs/karrierevorschlage">eine
            Karriere</a>.
        </h3>
      </ion-col>
      <ion-col size="3" class="display-grid">
        <i alt="Raketen-Icon" class="icon-rocket-outline icon-color-blue"></i>
      </ion-col>
    </ion-row>
  </ion-card>
  <ion-card *ngFor="let courseTemplateTreeBranchItem of displayedCourseTemplateTreeBranches">
    <div class="stretcher-div"></div>
    <ion-row>
      <ion-col size="10">
        <ion-row>
          <ion-col class="display-grid justify-content-center ion-align-items-center" size="1">
            <i class="watchlist-paths-icons icon-needle-filled icon-color-blue"></i>
          </ion-col>
          <ion-col size="11" class="align-items-center display-flex">
            <ion-item lines="none" *ngIf="courseTemplateTreeBranchItem.isValid === true">
              <ion-label>{{courseTemplateTreeBranchItem.requirementQualificationType.name}}&nbsp;
                {{courseTemplateTreeBranchItem.requirementQualificationCategory?.name}}</ion-label>
            </ion-item>
            <ion-item class="invalid" lines="none" *ngIf="courseTemplateTreeBranchItem.isValid === false">
              <ion-label>{{courseTemplateTreeBranchItem.requirementQualificationType.name}}&nbsp;
                {{courseTemplateTreeBranchItem.requirementQualificationCategory?.name}}</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

        <!--überprüft anhand der Template Arraylänge wie viele Kurse im Pfad dargestellt werden-->
        <ion-row *ngIf="courseTemplateTreeBranchItem.templates.length === 2">
          <ion-col size="1" class="display-flex justify-content-center">
            <div *ngIf="courseTemplateTreeBranchItem.isValid === true" class="progression-line"></div>
            <div *ngIf="courseTemplateTreeBranchItem.isValid === false" class="progression-line invalid"></div>
            <div *ngIf="courseTemplateTreeBranchItem.isValid === true" class="progression-line"></div>
            <div *ngIf="courseTemplateTreeBranchItem.isValid === false" class="progression-line invalid"></div>
            <div *ngIf="courseTemplateTreeBranchItem.isValid === true" class="progression-line"></div>
            <div *ngIf="courseTemplateTreeBranchItem.isValid === false" class="progression-line invalid"></div>
          </ion-col>
          <ng-container *ngIf="courseTemplateTreeBranchItem.isValid === true">
            <ion-col size="10" class="align-items-center display-flex"
              (click)="openCourseDetailsUnloadedCourse(courseTemplateTreeBranchItem.templates[0].id)">
              <ion-item lines="none">
                <ion-label class="truncate-text">{{courseTemplateTreeBranchItem.templates[0].name}}</ion-label>
              </ion-item>
            </ion-col>
            <ion-col size="1" (click)="openCourseDetailsUnloadedCourse(courseTemplateTreeBranchItem.templates[0].id)">
              <div class="center-content-div">
                <ion-icon class="color-primary" name="information-circle"></ion-icon>
              </div>
            </ion-col>
          </ng-container>
          <ion-col size="11" class="align-items-center display-flex"
            *ngIf="courseTemplateTreeBranchItem.isValid === false">
            <ion-item class="invalid" lines="none">
              <ion-label class="truncate-text">{{courseTemplateTreeBranchItem.templates[0].name}}</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

        <!--überprüft anhand der Template Arraylänge wie viele Kurse im Pfad dargestellt werden-->
        <ion-row *ngIf="courseTemplateTreeBranchItem.templates.length === 1">
          <ion-col class="display-flex justify-content-center" size="1">
            <div *ngIf="courseTemplateTreeBranchItem.isValid === true" class="progression-line"></div>
            <div *ngIf="courseTemplateTreeBranchItem.isValid === false" class="progression-line invalid"></div>
          </ion-col>
        </ion-row>
        <!--überprüft anhand der Template Arraylänge wie viele Kurse im Pfad dargestellt werden-->
        <ion-row *ngIf="courseTemplateTreeBranchItem.templates.length === 1">
          <ion-col size="1" class="display-grid justify-content-center ion-align-items-center">
            <i class="watchlist-paths-icons icon-flag-filled icon-color-blue"
              *ngIf="courseTemplateTreeBranchItem.isValid === true"></i>
            <ion-img *ngIf="courseTemplateTreeBranchItem.isValid === false"
              src="../../../../assets/ui-icons/icon-not-verified-v1-0-0.svg"></ion-img>
          </ion-col>
          <ng-container *ngIf="courseTemplateTreeBranchItem.isValid === true">
            <ion-col size="10" class="align-items-center display-flex"
              (click)="openCourseDetailsUnloadedCourse(courseTemplateTreeBranchItem.templates[0].id)">
              <ion-item lines="none">
                <ion-label class="truncate-text">{{courseTemplateTreeBranchItem.templates[0].name}}</ion-label>
              </ion-item>
            </ion-col>
            <ion-col size="1" (click)="openCourseDetailsUnloadedCourse(courseTemplateTreeBranchItem.templates[0].id)">
              <div class="center-content-div">
                <ion-icon class="color-primary" name="information-circle"></ion-icon>
              </div>
            </ion-col>
          </ng-container>
          <ion-col size="11" class="align-items-center display-flex"
            *ngIf="courseTemplateTreeBranchItem.isValid === false">
            <ion-item class="invalid" lines="none">
              <ion-label class="truncate-text">{{courseTemplateTreeBranchItem.templates[0].name}}</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <!--überprüft anhand der Template Arraylänge wie viele Kurse im Pfad dargestellt werden-->
        <ion-row *ngIf="courseTemplateTreeBranchItem.templates.length === 2">
          <ion-col size="1" class="display-grid justify-content-center ion-align-items-center">
            <i *ngIf="courseTemplateTreeBranchItem.isValid === true"
              class="watchlist-paths-icons icon-flag-filled icon-color-blue"></i>
            <ion-img *ngIf="courseTemplateTreeBranchItem.isValid === false"
              src="../../../../assets/ui-icons/icon-not-verified-v1-0-0.svg"></ion-img>
          </ion-col>
          <ng-container *ngIf="courseTemplateTreeBranchItem.isValid === true">
            <ion-col size="10" class="align-items-center display-flex"
              (click)="openCourseDetailsUnloadedCourse(courseTemplateTreeBranchItem.templates[1].id)">
              <ion-item lines="none">
                <ion-label class="truncate-text">{{courseTemplateTreeBranchItem.templates[1].name}}</ion-label>
              </ion-item>
            </ion-col>
            <ion-col size="1" (click)="openCourseDetailsUnloadedCourse(courseTemplateTreeBranchItem.templates[1].id)">
              <div class="center-content-div">
                <ion-icon class="color-primary" name="information-circle"></ion-icon>
              </div>
            </ion-col>
          </ng-container>
          <ion-col size="11" class="align-items-center display-flex">
            <ion-item class="invalid" lines="none" *ngIf="courseTemplateTreeBranchItem.isValid === false">
              <ion-label class="truncate-text">{{courseTemplateTreeBranchItem.templates[1].name}}</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>

      <ion-col size="2">
        <div class="ion-text-end">
          <ion-button class="fav-button" (click)="openConfirmationModal(courseTemplateTreeBranchItem)"
            *ngIf="courseTemplateTreeBranchItem.isValid === true">
            <i class="button-icon icon-bookmark-2 icon-color-white" aria-label="lesezeichen entfernen"></i>
          </ion-button>
          <ion-button class="fav-button background-color-invalid width-100-percent"
            (click)="openConfirmationModal(courseTemplateTreeBranchItem)"
            *ngIf="courseTemplateTreeBranchItem.isValid === false">
            <ion-icon name="trash"></ion-icon>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-card>
</ng-container>
