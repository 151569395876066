import { State } from './state.model';

export class Address {
  public id?: number;
  public name?: string;
  public streetName: string;
  public streetNumber: string;
  public zip: string;
  public city: string;
  public state?: State;
}
