import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TabsComponent } from 'app/components/layout/tabs/tabs.component';
import { DeviceHelper } from 'app/helpers/device-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { ARE_TOOLTIPS_ACTIVE, IS_FIRST_HOME_VISIT_IN_SESSION } from 'app/models/constants-logical-names';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit, AfterViewInit {
  @ViewChild('header') header: any;
  @ViewChild('scrollButton') scrollButton: any;
  public isLoggedIn = false;
  public areTooltipsActive = true;

  public formGroup: FormGroup = this.formBuilder.group({
    areTooltipsActive: [this.areTooltipsActive],
  });

  constructor(
    private deviceHelper: DeviceHelper,
    private formBuilder: FormBuilder,
    private storageHelper: StorageHelper,
    private tabsComponent: TabsComponent,
    private userHelper: UserHelper,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    const areTooltipsActive = await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE);
    if (areTooltipsActive === false) {
      this.toggleAreTooltipsActive();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.homeGraphicScaler(), 200);
  }

  async ionViewWillEnter() {
    this.setFirstHomePageVisitOfSession();

    this.isLoggedIn = await this.userHelper.checkForUser();
  }

  ionViewWillLeave() {
    this.removePulseAnimation('chatbot-pulse');
    this.removePulseAnimation('tab-button-nav-image');
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public async changeAreTooltipsActive(areTooltipsActive: any): Promise<void> {
    this.areTooltipsActive = areTooltipsActive;
    await this.storageHelper.setPreferences(ARE_TOOLTIPS_ACTIVE, areTooltipsActive);
  }

  public toggleAreTooltipsActive(): void {
    this.formGroup.controls.areTooltipsActive.setValue(!this.formGroup.controls.areTooltipsActive.getRawValue());
    this.changeAreTooltipsActive(this.formGroup.controls.areTooltipsActive.getRawValue());
  }

  private setFirstHomePageVisitOfSession(): void {
    const isFirstHomeVisitInSession = this.storageHelper.getSessionStorage(IS_FIRST_HOME_VISIT_IN_SESSION);
    if (isFirstHomeVisitInSession === null) {
      this.storageHelper.setSessionStorage(IS_FIRST_HOME_VISIT_IN_SESSION, 'true');
      return;
    }
    if (isFirstHomeVisitInSession === 'true') {
      this.storageHelper.setSessionStorage(IS_FIRST_HOME_VISIT_IN_SESSION, 'false');
    }
  }

  private removePulseAnimation(elementId: string): void {
    const chatbotPulse = document.getElementById(elementId);

    chatbotPulse.getAnimations().forEach((animation) => {
      animation.cancel();
    });
  }

  private async homeGraphicScaler() {
    const image = document.getElementById('network-image-home');
    const device: string = await this.deviceHelper.checkUserDevice();
    if (device !== 'windows' && device !== 'mac') {
      const imageKeyframes = [
        {
          transform: 'scale(1) rotate(0deg)',
          '--webkit-transform': 'scale(1) rotate(0deg)',
        },
        {
          transform: 'scale(1.1) rotate(2deg)',
          '--webkit-transform': 'scale(1.1) rotate(2deg)',
        },
        {
          transform: 'scale(1) rotate(0deg)',
          '--webkit-transform': 'scale(1) rotate(0deg)',
        },
        {
          transform: 'scale(1.1) rotate(-2deg)',
          '--webkit-transform': 'scale(1.1) rotate(-2deg)',
        },
        {
          transform: 'scale(1) rotate(0deg)',
          '--webkit-transform': 'scale(1) rotate(0deg)',
        },
        {
          transform: 'scale(1.1) rotate(2deg)',
          '--webkit-transform': 'scale(1.1) rotate(2deg)',
        },
        {
          transform: 'scale(1) rotate(0deg)',
          '--webkit-transform': 'scale(1) rotate(0deg)',
        },
        {
          transform: 'scale(1.1) rotate(-2deg)',
          '--webkit-transform': 'scale(1.1) rotate(-2deg)',
        },
        {
          transform: 'scale(1) rotate(0deg)',
          '--webkit-transform': 'scale(1) rotate(0deg)',
        },
      ];
      const imageOptions = {
        duration: 200000,
        iterations: Infinity,
      };
      image.animate(imageKeyframes, imageOptions);

      return;
    }

    image.addEventListener('mousemove', (e: any) => {
      const { left, top, width, height } = image.getBoundingClientRect();
      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;
      const scale = 1.005;

      image.style.transform = `scale(${scale}) translate(${x * 10}px, ${y * 10}px)`;

      image.addEventListener('mouseleave', () => {
        image.style.transform = 'scale(1)';
      });
    });
  }
}
