import { Component, OnInit } from '@angular/core';
import { COLOR_NAMES } from '../../../models/constants-app-values';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  public isChatOpen = false;

  constructor() {}

  ngOnInit() {
    this.triggerPulseAnimation('chatbot-pulse', COLOR_NAMES.magenta);
  }

  public toggleChat(): void {
    this.isChatOpen = !this.isChatOpen;
    const navBar = document.getElementsByClassName('tab-button-chat')[0];
    if (this.isChatOpen === true) {
      navBar.classList.add('tab-activated');
      return;
    }
    navBar.classList.remove('tab-activated');
  }

  public triggerPulseAnimation(elementId: string, color: string): void {
    const chatbotPulse = document.getElementById(elementId);
    const chatbotPulseKeyframes = [
      {
        transform: 'scale(1)',
        '--webkit-transform': 'scale(1)',
        filter: `drop-shadow(0px 0px 0px var(--${color}))`,
      },
      {
        transform: 'scale(1.2)',
        '--webkit-transform': 'scale(1.2)',
        filter: `drop-shadow(0px 0px 6px var(--${color}))`,
      },
      {
        transform: 'scale(1)',
        '--webkit-transform': 'scale(1)',
        filter: `drop-shadow(0px 0px 0px var(--${color}))`,
      },
    ];

    const chatbotPulseOptions = {
      duration: 2000,
      iterations: Infinity,
    };

    chatbotPulse.animate(chatbotPulseKeyframes, chatbotPulseOptions);
  }
}
