import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonModal, ModalController } from '@ionic/angular';
import { ConfirmationModalComponent } from 'app/components/layout/confirmation-modal/confirmation-modal.component';
import { CourseHelper } from 'app/helpers/course-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME } from 'app/models/constants-logical-names';
import { STANDARD_ERROR_CODE, STANDARD_ERROR_TEXT, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplate } from 'app/models/course-template';
import { CourseParameter } from 'app/models/course_parameter.model';
import { StoredCourseTemplateTreeBranch } from 'app/models/stored-course-template-tree-branch.model';
import { Template } from 'app/models/template.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { User } from 'app/models/user.model';
import { GetCoursesService } from 'app/services/get-courses.service';
import { GetQualificationsService } from 'app/services/get-qualifications.service';
import { UserCourseRegistrationService } from 'app/services/user-course-registration.service';
import { UserWatchlistService } from 'app/services/user-watchlist.service';
import { Course } from '../../../models/course.model';
import { STORABLE_COURSE_TEMPLATE_TREE_BRANCH_TYPES } from '../../../models/storable-course-template-tree-branch.model';
import { CourseDetailsComponent } from './../course-details/course-details.component';

@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.scss'],
})
export class WatchListComponent implements OnInit, OnChanges {
  @ViewChild(IonModal) modal: IonModal;
  @Input() isActiveTab = false;

  public readonly standardErrorText = STANDARD_ERROR_TEXT;

  public isCourseLoading = false;
  public areSelectedCoursesLoading = false;
  public hasRequestFailed = false;
  public isLoggedIn = false;
  public currentWatchListTab = 'Kurse';
  public courseTemplates: CourseTemplate[] = [];
  public registeredCourseTemplates: CourseTemplate[] = [];
  public displayedCourseTemplateTreeBranches: StoredCourseTemplateTreeBranch[] = [];
  public selectedCourseTemplateIds: number[] = [];

  private errorCollection = '';
  private user: User = null;
  private selectedCourses: Course[] = [];

  constructor(
    private modalCtrl: ModalController,
    private courseService: GetCoursesService,
    private courseHelper: CourseHelper,
    private userCourseRegistrationService: UserCourseRegistrationService,
    private userHelper: UserHelper,
    private userWatchlistService: UserWatchlistService,
    private storageHelper: StorageHelper,
    private router: Router,
    private qualificationsService: GetQualificationsService,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
    }
  }

  async ngOnChanges() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
    }

    this.areSelectedCoursesLoading = true;
    this.qualificationsService.getQualifications();

    if (this.isActiveTab === true) {
      await this.loadSelectedCourseTreeBranches();
      await this.loadSelectedCourses();
      await this.loadRegisteredCourses();
    }

    this.areSelectedCoursesLoading = false;
    if (this.hasRequestFailed === true) {
      throw Error(this.errorCollection);
    }
  }

  public async openConfirmationModal(watchListItem: CourseTemplate | StoredCourseTemplateTreeBranch): Promise<void> {
    let modal = await this.modalCtrl.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-modal',
      componentProps: {
        enableBackdropDismiss: true,
        action: 'entfernen',
        objectToRemove: 'den Favorit',
        locationWhereObjectIsToBeRemoved: 'deiner Merkliste',
      },
    });

    if ('courses' in watchListItem === false && (watchListItem as StoredCourseTemplateTreeBranch).isValid === false) {
      modal = await this.modalCtrl.create({
        component: ConfirmationModalComponent,
        cssClass: 'small-modal',
        componentProps: {
          enableBackdropDismiss: true,
          action: 'entfernen',
          objectToRemove: 'den nicht mehr gültigen Favorit',
          locationWhereObjectIsToBeRemoved: 'deiner Merkliste',
          additionalInfo: 'Danach kannst du ähnliche Pfade entdecken.',
        },
      });
    }

    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }

    if (role === 'confirm') {
      if ('courses' in watchListItem === true) {
        this.unfavoriteCourseTemplate(watchListItem.id);
      }
      if ('courses' in watchListItem === false) {
        const item = watchListItem as StoredCourseTemplateTreeBranch;
        this.unfavoriteCourseTemplateTree(item.id);

        if (item.isValid === false) {
          const parameter = this.courseHelper.extractParameterFromStoredCourseTemplateTree(item);
          parameter.templateIds = undefined;

          this.storageHelper.setSessionStorage(
            PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME,
            JSON.stringify(parameter),
          );

          this.router.navigate(['/tabs/karrierevorschlage']);
        }
      }
    }
  }

  public async openCourseDetailsModalPreLoadedCourse(selectedCourseTemplate: CourseTemplate): Promise<void> {
    const courses = this.selectedCourses.filter((x) => x.courseTemplate.id === selectedCourseTemplate.id);

    const modal = await this.modalCtrl.create({
      component: CourseDetailsComponent,
      cssClass: 'small-modal',
      componentProps: {
        courseTemplate: selectedCourseTemplate,
        courses,
        user: this.user,
        areSelectedCoursesLoading: this.areSelectedCoursesLoading,
        selectedCourseTemplateIds: this.selectedCourseTemplateIds,
        enableBackdropDismiss: true,
      },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      this.loadSelectedCourses();
    }
  }

  public async openCourseDetailsUnloadedCourse(branchTemplateId: number): Promise<void> {
    if (branchTemplateId === undefined) {
      return;
    }

    const courseParameter = new CourseParameter();
    courseParameter.courseTemplateIds = [branchTemplateId];
    courseParameter.partitionInstituteTemplateCount = 3;

    this.isCourseLoading = true;

    const coursesResult = await this.courseService.searchCourses(courseParameter);

    if (coursesResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isCourseLoading = false;
      throw Error(this.courseService.errorMessage);
    }

    this.isCourseLoading = false;

    const courseTemplates = this.courseHelper.getCourseTemplates(coursesResult as Course[]);

    const modal = await this.modalCtrl.create({
      component: CourseDetailsComponent,
      cssClass: 'small-modal',
      componentProps: {
        courseTemplate: courseTemplates[0],
        courses: coursesResult,
        user: this.user,
        areSelectedCoursesLoading: this.areSelectedCoursesLoading,
        selectedCourseTemplateIds: this.selectedCourseTemplateIds,
        enableBackdropDismiss: true,
      },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      this.loadSelectedCourses();
    }
    this.hasRequestFailed = false;
  }

  public changeWatchListTab(event: any): void {
    this.currentWatchListTab = event;
  }

  private async loadSelectedCourseTreeBranches(): Promise<void> {
    const favoritedCourseTemplateTreeBranches = await this.userWatchlistService.loadFavoritedCourseTemplateTreeBranches(
      this.user,
    );

    if (favoritedCourseTemplateTreeBranches === 401) {
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (favoritedCourseTemplateTreeBranches === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.errorCollection += '\n' + this.userWatchlistService.errorMessage;
      return;
    }
    this.displayedCourseTemplateTreeBranches = favoritedCourseTemplateTreeBranches as StoredCourseTemplateTreeBranch[];

    this.hasRequestFailed = false;
  }

  private async unfavoriteCourseTemplateTree(storageId: number): Promise<void> {
    const indexToDelete = this.displayedCourseTemplateTreeBranches.findIndex((x) => x.id === storageId);

    await this.userWatchlistService.deleteStoredCourseTemplateTreeBranch(
      this.user,
      storageId,
      STORABLE_COURSE_TEMPLATE_TREE_BRANCH_TYPES.FAVORITE,
    );
    this.displayedCourseTemplateTreeBranches.splice(indexToDelete, 1);
  }

  private async unfavoriteCourseTemplate(courseTemplateId: number): Promise<void> {
    const courseToRemove = this.courseTemplates.find((x) => x.id === courseTemplateId);
    if (courseToRemove !== undefined) {
      this.courseTemplates.splice(this.courseTemplates.indexOf(courseToRemove), 1);

      this.userWatchlistService.deleteStoredCourse(this.user, courseToRemove.id);
    }
  }

  private async loadSelectedCourses(): Promise<void> {
    const selectedCoursesPreferenceResult = await this.userWatchlistService.loadStoredCourses(this.user);

    if (selectedCoursesPreferenceResult === 401) {
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (selectedCoursesPreferenceResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.errorCollection += '\n' + this.userWatchlistService.errorMessage;
      return;
    }

    const courseTemplates = selectedCoursesPreferenceResult as Template[];
    const courseParameter: CourseParameter = new CourseParameter();
    courseParameter.courseTemplateIds = [];
    const selectedCourses: Course[] = [];

    for (const templateItem of courseTemplates) {
      courseParameter.courseTemplateIds.push(templateItem.id);
      courseParameter.partitionInstituteTemplateCount = 3;
    }
    this.selectedCourseTemplateIds = courseParameter.courseTemplateIds;

    let searchedCourses: Course[] = [];
    let promiseCourses: Promise<Course[]>[] = [];

    const promiseCoursesResult = this.courseService.searchCourses(courseParameter);
    if ((await promiseCoursesResult) === SYSTEM_ERROR_CODE) {
      this.errorCollection += '\n' + this.courseService.errorMessage;
      this.hasRequestFailed = true;
      return;
    }

    promiseCourses = promiseCourses.concat(promiseCoursesResult as Promise<Course[]>);

    for (const promiseCourseItem of promiseCourses) {
      searchedCourses = searchedCourses.concat(await promiseCourseItem);
    }

    for (const searchedCourseItem of searchedCourses) {
      selectedCourses.push(searchedCourseItem);
    }

    this.selectedCourses = selectedCourses;
    this.courseTemplates = this.courseHelper.getCourseTemplates(this.selectedCourses);

    this.hasRequestFailed = false;
  }

  private async loadRegisteredCourses(): Promise<void> {
    if (!this.user) {
      return;
    }
    this.areSelectedCoursesLoading = true;

    const userCourseRegistrationsPreferenceResult = await this.userCourseRegistrationService.getUserCourseRegistrations(
      this.user,
    );

    if (userCourseRegistrationsPreferenceResult === 401) {
      this.areSelectedCoursesLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (userCourseRegistrationsPreferenceResult === SYSTEM_ERROR_CODE) {
      this.areSelectedCoursesLoading = false;
      throw Error(this.userCourseRegistrationService.errorMessage);
    }
    if (userCourseRegistrationsPreferenceResult === STANDARD_ERROR_CODE) {
      this.areSelectedCoursesLoading = false;
      console.error(this.userCourseRegistrationService.errorMessage);
    }
    const userCourseRegistrations: UserCourseRegistration[] = [];

    for (const userCourseRegistration of userCourseRegistrationsPreferenceResult as UserCourseRegistration[]) {
      userCourseRegistrations.push(userCourseRegistration);
    }

    this.registeredCourseTemplates = userCourseRegistrations;

    this.areSelectedCoursesLoading = false;
  }
}
