import { Directive, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[appScrollActions]',
})
export class ScrollActionsDirective implements OnInit {
  @Input() public scrollButton: any;
  @Input() public searchSlider: any;
  public isScrollActionsActive = true;
  public isWithinScrollThreshold = true;

  private scrollThreshold: number;
  private lastVerticalPosition = 0;

  constructor(
    private renderer: Renderer2,
    private domCtrl: DomController,
  ) {}

  @HostListener('ionScroll', ['$event']) onContentScroll($event: any) {
    if (this.isScrollActionsActive === true) {
      this.scrollThreshold =
        document.getElementById('app-header').offsetHeight + document.getElementById('top-scroll').offsetTop;

      if (
        $event.detail.scrollTop >= (this.scrollThreshold || 0) &&
        $event.detail.scrollTop >= this.lastVerticalPosition
      ) {
        this.isWithinScrollThreshold = false;
        this.moveScrollButtonIntoSight();
        this.searchSliderDownScrollingBehavior();
      }
    }
    if ($event.detail.scrollTop < (this.scrollThreshold || 0) && $event.detail.scrollTop < this.lastVerticalPosition) {
      this.isWithinScrollThreshold = true;
      this.isScrollActionsActive = true;
      this.moveScrollButtonOutOfSight();
      this.searchSliderUpScrollingBehavior();
    }
    this.lastVerticalPosition = $event.detail.scrollTop;
  }

  ngOnInit(): void {
    this.scrollButton = this.scrollButton.el;
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.scrollButton, 'bottom', '-29px');
      this.renderer.setStyle(this.scrollButton, 'transition', 'bottom 250ms');
    });
  }

  public shrinkSearchSlider(): void {
    const shrinkableContents = document.getElementsByClassName('shrinkable-content');
    const swiperContainer = document.getElementById('swiper-container');

    for (const shrinkableContent of Array.from(shrinkableContents)) {
      this.domCtrl.write(() => {
        this.renderer.setStyle(
          shrinkableContent,
          'transition',
          'visbility 250ms, max-height 250ms, margin 250ms, height: 250ms',
        );
        this.renderer.setStyle(shrinkableContent, 'visibility', 'hidden');
        this.renderer.setStyle(shrinkableContent, 'max-height', '0');
        this.renderer.setStyle(shrinkableContent, 'margin', '0');
      });
    }
    // fixt höhen bug von swiper-container
    setTimeout(() => {
      this.domCtrl.write(() => {
        this.renderer.setStyle(swiperContainer, 'height', 'initial');
      });
    }, 250);
  }

  public growSearchSlider(): void {
    const shrinkableContents = document.getElementsByClassName('shrinkable-content');
    const swiperContainer = document.getElementById('swiper-container');

    for (const shrinkableContent of Array.from(shrinkableContents)) {
      this.domCtrl.write(() => {
        this.renderer.setStyle(
          shrinkableContent,
          'transition',
          'visbility 250ms, max-height 250ms, margin 250ms, height: 250ms',
        );
        this.renderer.setStyle(shrinkableContent, 'visibility', 'visible');
        this.renderer.setStyle(shrinkableContent, 'max-height', '100vh');
        this.renderer.setStyle(shrinkableContent, 'margin', '');
      });
    }

    // fixt höhen bug von swiper-container
    this.domCtrl.write(() => {
      this.renderer.setStyle(swiperContainer, 'height', 'initial');
    });
    setTimeout(() => {
      this.domCtrl.write(() => {
        this.renderer.setStyle(swiperContainer, 'height', 'auto');
      });
    }, 100);
  }

  private mergePaginationBulletsWithSlider(): void {
    const swiperCards = document.getElementsByClassName('swiper-card');
    for (const swiperCard of Array.from(swiperCards)) {
      this.domCtrl.write(() => {
        this.renderer.setStyle(swiperCard, 'transition', 'margin 250ms, border-radius 250ms');
        this.renderer.setStyle(swiperCard, 'margin', '0');
        this.renderer.setStyle(swiperCard, 'border-radius', '0px 0px 10px 10px');
      });
    }
  }

  private unMergePaginationBulletsWithSlider(): void {
    const swiperCards = document.getElementsByClassName('swiper-card');
    for (const swiperCard of Array.from(swiperCards)) {
      this.domCtrl.write(() => {
        this.renderer.setStyle(swiperCard, 'transition', 'margin 250ms, border-radius 250ms');
        this.renderer.setStyle(swiperCard, 'margin', '');
        this.renderer.setStyle(swiperCard, 'border-radius', '10px 10px 10px 10px');
      });
    }
  }

  private moveScrollButtonOutOfSight(): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.scrollButton, 'bottom', '-29px');
    });
  }

  private moveScrollButtonIntoSight(): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.scrollButton, 'bottom', '10px');
    });
  }

  private searchSliderDownScrollingBehavior(): void {
    const swiperContainer = document.getElementById('swiper-container');
    const courseList = document.getElementById('course-list');
    this.fixSearchSlider(swiperContainer);
    this.mergePaginationBulletsWithSlider();
    this.shrinkSearchSlider();
    this.setCourseListMargin(courseList, swiperContainer);
  }

  private searchSliderUpScrollingBehavior(): void {
    const swiperContainer = document.getElementById('swiper-container');
    const courseList = document.getElementById('course-list');
    this.unfixSearchSlider(swiperContainer);
    this.unMergePaginationBulletsWithSlider();
    this.growSearchSlider();
    this.unsetCourseListMargin(courseList);

    // fixt höhen bug von swiper-container
    setTimeout(() => {
      this.domCtrl.write(() => {
        this.renderer.setStyle(swiperContainer, 'height', 'auto');
      });
    }, 100);
  }

  private setCourseListMargin(courseList: HTMLElement, swiperContainer: HTMLElement): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(courseList, 'margin-top', swiperContainer.clientHeight + 16 + 'px');
    });
  }
  private unsetCourseListMargin(courseList: HTMLElement): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(courseList, 'margin-top', '0px');
    });
  }

  private fixSearchSlider(swiperContainer: HTMLElement): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.searchSlider, 'position', 'fixed');
      this.renderer.setStyle(this.searchSlider, 'top', '0px');
      this.renderer.setStyle(this.searchSlider, 'left', '0px');
      this.renderer.setStyle(this.searchSlider, 'width', '100vw');
      this.renderer.setStyle(this.searchSlider, 'z-index', '100');
    });

    setTimeout(() => {
      this.domCtrl.write(() => {
        this.renderer.setStyle(swiperContainer, 'height', 'auto');
      });
    }, 250);
  }

  private unfixSearchSlider(swiperContainer: HTMLElement): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.searchSlider, 'position', 'relative');
    });

    this.domCtrl.write(() => {
      this.renderer.setStyle(swiperContainer, 'height', 'auto');
    });
  }
}
