import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { Institute } from 'app/models/institute.model';
import { lastValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { RequestStatus } from './../models/request-status.model';

@Injectable({
  providedIn: 'root',
})
export class InstituteService {
  public errorMessage = '';
  constructor(private http: HttpClient) {}

  public async getInstitutes(): Promise<Institute[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const institutesObservable = this.http.get<RequestStatus>(environment.apiUrlHwks, options);

      const requestStatus = await lastValueFrom(institutesObservable);

      // Nachträgliche Sortierung der Daten nach dem Namen, da diese zufällig in den Array hinzugefügt werden
      requestStatus.data.sort((a, b) => a.name.localeCompare(b.name));

      return requestStatus.data;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
