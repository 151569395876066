import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  @Input() text: string = '';
  @Input() formatHtml: boolean = false;
  @Input() isError: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {}

  public cancel(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      return this.modalCtrl.dismiss(null, null);
    }
  }

  public clickFormattedHtmlText(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.tagName === 'A') {
      return this.modalCtrl.dismiss(null, 'cancel');
    }
  }
}
