import { Injectable } from '@angular/core';
import { STANDARD_ERROR_CODE } from 'app/models/constants-status';
import { environment } from 'environments/environment';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class ChatBotCommunicationService {
  public botConnection: Socket;
  constructor() {}
  public async setBotConnection(): Promise<void | number> {
    try {
      const socket = io(environment.chatbotUrl);
      socket.on('connect', () => {
        console.log('Connected to chatbot');
      });
      socket.on('connect_error', (error) => {
        console.error(error);
      });

      this.botConnection = socket;

      this.botConnection.emit('user_uttered', {
        message: 'Kurse',
      });
    } catch (error) {
      console.error(error);
      return STANDARD_ERROR_CODE;
    }
  }

  public sendUserMessageToBot(userMessage: string): void | number {
    try {
      this.botConnection.emit('user_uttered', {
        message: userMessage,
      });
    } catch (error) {
      console.error(error);
      return STANDARD_ERROR_CODE;
    }
  }
}
