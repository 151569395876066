import { Injectable } from '@angular/core';

@Injectable()
export class DateHelper {
  constructor() {}

  public formatDateYyyymmddString(dateString: string): string {
    const date = new Date(dateString);

    const dayString = date.getDate().toString();
    const day = dayString.length === 1 ? '0' + dayString : dayString;
    const monthString = (date.getMonth() + 1).toString();
    const month = monthString.length === 1 ? '0' + monthString : monthString;
    const year = date.getFullYear();

    return year + '-' + month + '-' + day;
  }

  public formatDateString(dateString: string): string {
    const date = new Date(dateString);

    const dayString = date.getDate().toString();
    const day = dayString.length === 1 ? '0' + dayString : dayString;
    const monthString = (date.getMonth() + 1).toString();
    const month = monthString.length === 1 ? '0' + monthString : monthString;
    const year = date.getFullYear();

    return day + '.' + month + '.' + year;
  }

  public isDateInThePast(dateString: string): boolean {
    const date = new Date(dateString);
    const now = new Date();

    return date < now;
  }
}
