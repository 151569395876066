import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormHelper } from 'app/helpers/form-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { USER_DATA_LOGICAL_NAME } from 'app/models/constants-logical-names';
import {
  NEUTRAL_ERROR_CODE,
  PASSWORD_IS_WRONG_CODE,
  PASSWORD_IS_WRONG_TEXT,
  STANDARD_ERROR_CODE,
  STANDARD_ERROR_TEXT,
  SYSTEM_ERROR_CODE,
  USER_DATA_INPUT_ERROR_CODE,
  USER_DOESNT_EXIST_CODE,
  USER_DOESNT_EXIST_TEXT,
} from 'app/models/constants-status';
import { UserLoginParameter } from 'app/models/user-login-parameter.model';
import { User } from 'app/models/user.model';
import { LoginUserService } from 'app/services/login-user.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.page.html',
  styleUrls: ['./user-login.page.scss'],
})
export class UserLoginPage implements OnInit {
  public isLoading = false;
  public formGroup = this.formBuilder.group({
    emailInput: ['', [Validators.required, Validators.email]],
    passwordInput: ['', [Validators.required]],
  });
  public userLoginParameter: UserLoginParameter = new UserLoginParameter();
  public errorCode = NEUTRAL_ERROR_CODE;
  public isLoggedIn = false;
  public readonly passwordResetUrl = environment.passwordResetUrl;
  public readonly standardErrorCode = STANDARD_ERROR_CODE;
  public readonly userDataInputErrorCode = USER_DATA_INPUT_ERROR_CODE;

  private user: User = new User();

  constructor(
    public formHelper: FormHelper,
    private formBuilder: FormBuilder,
    private userLoginService: LoginUserService,
    private storageHelper: StorageHelper,
    private router: Router,
    private userHelper: UserHelper,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
  }

  async ionViewWillEnter() {
    this.isLoggedIn = await this.userHelper.checkForUser();
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public changeSelectedEmail(email: string): void {
    this.userLoginParameter.email = email;
  }

  public changeSelectedPassword(password: string): void {
    this.userLoginParameter.password = password;
  }

  public async pressLogoutButton(): Promise<void> {
    this.isLoggedIn = this.userHelper.logoutUser();
  }

  public async pressLoginButton(): Promise<void> {
    await this.loginUser();
  }

  public async loginUser(): Promise<void> {
    this.isLoading = true;

    const response = await this.userLoginService.loginUser(this.userLoginParameter);
    if (response === SYSTEM_ERROR_CODE) {
      this.errorCode = response;
      this.isLoading = false;
      console.error(this.userLoginService.errorMessage);
    }
    if (response === STANDARD_ERROR_CODE) {
      this.errorCode = response;
      this.isLoading = false;
      console.error(STANDARD_ERROR_TEXT);
    }
    if (response === PASSWORD_IS_WRONG_CODE) {
      this.errorCode = response;
      this.isLoading = false;
      console.error(PASSWORD_IS_WRONG_TEXT);
    }
    if (response === USER_DOESNT_EXIST_CODE) {
      this.errorCode = response;
      this.isLoading = false;
      console.error(USER_DOESNT_EXIST_TEXT);
    }
    if (typeof response === 'object') {
      this.user = response;
      this.errorCode = NEUTRAL_ERROR_CODE;

      this.storageHelper.setPreferences(USER_DATA_LOGICAL_NAME, this.user);

      this.isLoading = false;
      this.router.navigate(['/tabs/startseite']);
      return;
    }
    this.isLoading = false;
  }
}
