import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeParameterRequest } from 'app/models/course-template-tree-parameter-request.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { RequestStatus } from 'app/models/request-status.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetCourseTemplateTreesService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}
  public async searchCourseTrees(
    courseTemplateTreeParameterRequest: CourseTemplateTreeParameterRequest,
  ): Promise<CourseTemplateTree[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(courseTemplateTreeParameterRequest);
      const requestStatusObservable = this.http.post<RequestStatus>(
        environment.apiUrlCourseTemplateTrees,
        body,
        options,
      );
      const courseTemplateTrees: CourseTemplateTree[] = [];

      const requestStatus = await lastValueFrom(requestStatusObservable);

      requestStatus.data.forEach((courseTemplateTree) => {
        courseTemplateTrees.push(courseTemplateTree);
      });

      return courseTemplateTrees;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
