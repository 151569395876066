export const environment = {
  production: false,
  apiUrlCourses: 'https://verwaltung.dev.hubgrade.de/api/courses',
  apiUrlQualifications: 'https://verwaltung.dev.hubgrade.de/api/qualifications',
  apiUrlHwks: 'https://verwaltung.dev.hubgrade.de/api/institutes/2',
  apiUrlCourseTemplateTrees: 'https://verwaltung.dev.hubgrade.de/api/course_template_tree',
  apiUrlErrorMessage: 'https://verwaltung.dev.hubgrade.de/api/error_message',
  apiUrlUserRegistration: 'https://verwaltung.dev.hubgrade.de/api/user/register',
  apiUrlUserLogin: 'https://verwaltung.dev.hubgrade.de/api/user/login',
  apiUrlUser: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}',
  apiUrlUserCourseRegistration: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/course_registrations',
  apiUrlUserUpdate: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/update',
  apiUrlUserProject: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/project',
  apiUrlUserFilesUpload: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/files/upload',
  apiUrlUserFilesUpdate: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/files/update/{{preview_image_id}}',
  apiUrlUserFilesDelete: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/files/delete/{{preview_image_id}}',
  apiUrlUserFilesGetPreviews: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/files',
  apiUrlUserFilesGetFile: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/files/{{preview_image_id}}',
  apiUrlUserFilesVerification:
    'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/files/verification/{{preview_image_id}}',
  apiUrlUserFavoritedCourseTemplateTrees:
    'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/course_templates/tree_favorites',
  apiUrlUserStoredCourseTemplateTrees: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/course_templates/trees',
  apiUrlUserStoredCourses: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/course_templates/favorites',
  apiUrlUserCourseTemplateRating: 'https://verwaltung.dev.hubgrade.de/api/user/{{user_id}}/course_templates/rating',
  passwordResetUrl: 'https://verwaltung.dev.hubgrade.de/password/reset/',
  chatbotUrl: 'bot.dev.hubgrade.de',
  authenticationToken: 'hzYhZkRREb6IEiuZfikQMvv21kRcN7sFPPpjdOAa',
};
