import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { PreviewImage } from 'app/models/preview-image.model';
import { RequestStatus } from 'app/models/request-status.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetUserFilesService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async getUserPreviewImages(user: User): Promise<PreviewImage[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const previewImagesObservable = this.http.get<RequestStatus>(
        environment.apiUrlUserFilesGetPreviews.replace('{{user_id}}', user.id.toString()),
        options,
      );
      const previewImages: PreviewImage[] = [];

      const requestStatus = await lastValueFrom(previewImagesObservable);

      requestStatus.data.forEach((previewImage) => {
        previewImages.unshift(previewImage);
      });

      return previewImages;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async getUserFile(user: User, previewImage: PreviewImage): Promise<File | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
        responseType: 'blob' as 'json',
      };

      const fileObservable = this.http.get<any>(
        environment.apiUrlUserFilesGetFile
          .replace('{{user_id}}', user.id.toString())
          .replace('{{preview_image_id}}', previewImage.id.toString()),
        options,
      );

      const file = await lastValueFrom(fileObservable);
      return file;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
