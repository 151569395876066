import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  clientId: '5',
  dummyClientSecret: '8HTwdOadz87vFTr7BYFpEyowCDaRUQa7jZzT7JnU',
  loginUrl: 'https://zls.dev.zwh.de/oauth/authorize',
  tokenEndpoint: 'https://zls.dev.zwh.de/oauth/token',
  userinfoEndpoint: 'https://zls.dev.zwh.de/api/userinfo/project/5',
  redirectUri: window.location.origin,
  scope: 'profile',
  responseType: 'code',
  showDebugInformation: true,
  oidc: false,
  customQueryParams: {
    approvalPrompt: 'auto',
  },
};
