<ion-card>
  <div class="stretcher-div"></div>
  <ion-row>
    <ion-col>
      <ion-card-header class="ion-text-center">
        <h2>Kurssuche</h2>
      </ion-card-header>
    </ion-col>
  </ion-row>
  <ion-card-content>
    <form [formGroup]="formGroup">
      <ion-row class="ion-margin-top">
        <ion-col size="12" size-md="6" class="display-grid">
          <ion-select interface="action-sheet" label="{{requirementQualificationCategorySelectText}}*"
            value="{{qualificationCategoryUnselected}}" label-placement="floating" class="form-field ion-text-start"
            formControlName="qualificationCategorySelect"
            (ionChange)="changeSelectedQualificationCategory($any($event.target).value)">
            <ion-select-option>{{ qualificationCategoryUnselected }}</ion-select-option>
            <ion-select-option *ngFor="let qualificationCategoryItem of qualificationCategories"
              [value]="qualificationCategoryItem">
              {{qualificationCategoryItem.name}}
            </ion-select-option>
          </ion-select>
          <ion-icon *ngIf="selectedQualificationCategory !== null" name="close" class="select-removal-icon"
            (click)="changeSelectedQualificationCategory(null)"></ion-icon>
        </ion-col>
        <ion-col size="12" size-md="6">
          <ion-input class="form-field ion-text-start" placeholder="Suchbegriff*" formControlName="searchTermInput"
            (ionChange)="changeSelectedFullTextSearch($any($event.target).value)" (keyup.enter)="searchCourses()">
          </ion-input>
        </ion-col>
      </ion-row>
      <ion-accordion-group [multiple]="true" (ionChange)="changeAccordionStatus($event)">
        <ion-row>
          <ion-col size="12" size-md="6">
            <ion-accordion value="{{regionalSearchTerm}}">
              <ion-item lines="none" slot="header" color="white">
                Regionalsuche
                <ion-badge class="ion-margin-end"
                  *ngIf="regionalSearchBadgeNumber > 0 && isRegionAccordionOpen === false"
                  slot="end">{{regionalSearchBadgeNumber}}</ion-badge>
              </ion-item>
              <div slot="content">
                <ion-row class="ion-margin-top country-wide-row">
                  <ion-col size="11" class="ion-text-end">
                    <ion-label class="highlighted-bold-text" (click)="toggleIsCountryWide()">
                      Deutschlandweite Suche
                    </ion-label>
                  </ion-col>
                  <ion-col size="1" class="display-flex justify-content-end">
                    <ion-checkbox class="highlighted-checkbox"
                      (ionChange)="changeIsCountryWide($any($event.target).checked)" labelPlacement="start"
                      formControlName="isCountryWide"></ion-checkbox>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="7">
                    <ion-input class="form-field ion-text-start" placeholder="PLZ*" formControlName="zipCodeInput"
                      (ionChange)="changeSelectedZip($any($event.target).value)">
                    </ion-input>
                  </ion-col>
                  <ion-col size="5">
                    <ion-select interface="action-sheet" label-placement="floating" class="form-field ion-text-start"
                      placeholder="Umkreis" formControlName="distanceSelect"
                      (ionChange)="changeSelectedDistance($any($event.target).value)">
                      <ion-select-option *ngFor="let distanceValueItem of distanceValues" [value]="distanceValueItem">
                        {{distanceValueItem}}km</ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>
              </div>
            </ion-accordion>
          </ion-col>
          <ion-col size="12" size-md="6">
            <ion-accordion value="{{advancedSearchTerm}}">
              <ion-item lines="none" slot="header" color="white">
                Erweiterte Suche
                <ion-badge class="ion-margin-end"
                  *ngIf="advancedSearchBadgeNumber > 0 && isAdvancedAccordionOpen === false"
                  slot="end">{{advancedSearchBadgeNumber}}</ion-badge>
              </ion-item>
              <div slot="content">
                <ion-row class="ion-margin-top">
                  <ion-col class="display-grid">
                    <ion-select interface="action-sheet" label="{{qualificationTypeSelectText}}"
                      label-placement="floating" class="form-field ion-text-start"
                      formControlName="qualificationTypeSelect"
                      (ionChange)="changeSelectedQualificationType($any($event.target).value)">
                      <ion-select-option *ngFor="let qualificationTypeItem of qualificationTypes"
                        [value]="qualificationTypeItem">
                        {{qualificationTypeItem.name}}
                      </ion-select-option>
                    </ion-select>
                    <ion-icon *ngIf="selectedQualificationType !== null" name="close" class="select-removal-icon"
                      (click)="changeSelectedQualificationType(null)"></ion-icon>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <ion-select interface="action-sheet" label="Anwesenheit" label-placement="floating"
                      class="form-field ion-text-start" formControlName="learnMethodSelect"
                      (ionChange)="changeSelectedLearnMethod($any($event.target).value)">
                      <ion-select-option [value]="learnMethod[2]">
                        {{learnMethod[2]}}
                      </ion-select-option>
                      <ion-select-option [value]="learnMethod[1]">
                        {{learnMethod[1]}}
                      </ion-select-option>
                      <ion-select-option [value]="learnMethod[3]">
                        {{learnMethod[3]}}
                      </ion-select-option>
                      <ion-select-option [value]="learnMethod[0]">
                        {{learnMethod[0]}}
                      </ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <ion-select interface="action-sheet" label="Förderung" label-placement="floating"
                      class="form-field ion-text-start" formControlName="fundableSelect"
                      (ionChange)="changeSelectedFunding($any($event.target).value)">
                      <ion-select-option [value]="fundingOption[1]">
                        {{fundingOption[1]}}
                      </ion-select-option>
                      <ion-select-option [value]="fundingOption[2]">
                        {{fundingOption[2]}}
                      </ion-select-option>
                      <ion-select-option [value]="fundingOption[0]">
                        {{fundingOption[0]}}
                      </ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>
              </div>
            </ion-accordion>
          </ion-col>
        </ion-row>
      </ion-accordion-group>

      <ion-row class="hide-user-agreement-{{isLoggedIn}}">
        <ion-col class="ion-align-items-center display-flex">
          <ion-label class="highlighted-text ion-margin-end ion-margin-start"
            [innerHTML]="sanitizer.bypassSecurityTrustHtml(userAgreementQuestion)">
          </ion-label>
          <ion-checkbox [value]="isLoggedIn" class="highlighted-checkbox ion-margin-end"
            formControlName="userAgreementCheckbox"
            (ionChange)="changeIsUserAgreementAgreed($any($event.target).checked)">
          </ion-checkbox>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="display-grid" size="8">
          <ion-text class="color-magenta align-self-end">*mindestens eines ausfüllen</ion-text>
        </ion-col>
        <ion-col size="4">
            <ion-button class="search-button" expand="block" (click)="searchCourses()"
              [disabled]="formGroup.valid === false || isSearchLoading === true">
              <i class="button-icon icon-magnifying-glass-outline icon-color-white" alt="Suche starten"></i>
            </ion-button>
          </ion-col>
      </ion-row>
    </form>
  </ion-card-content>
</ion-card>
