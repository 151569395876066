import { Directive, HostListener } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { HomeNavigationBarComponent } from './../components/layout/home-navigation-bar/home-navigation-bar.component';

@Directive({
  selector: '[appResizeActions]',
})
export class ResizeActionsDirective {
  constructor(
    private menu: MenuController,
    private homeNavigationBarComponent: HomeNavigationBarComponent,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1200 && event.target.innerWidth > 768) {
      this.menu.enable(true);
    }
    if (event.target.innerWidth < 768) {
      this.menu.enable(false);
    }
    if (event.target.innerWidth >= 1200) {
      this.menu.enable(false);
    }
  }
}
