import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { USER_DATA_LOGICAL_NAME } from 'app/models/constants-logical-names';
import { User } from 'app/models/user.model';
import { StorageHelper } from './storage-helper';

@Injectable()
export class UserHelper {
  constructor(
    private router: Router,
    private storageHelper: StorageHelper,
  ) {}

  public async checkForUser(): Promise<boolean> {
    const user = await this.storageHelper.getPreferences(USER_DATA_LOGICAL_NAME);
    return user !== null && user !== undefined;
  }

  public async loadUserData(): Promise<User> {
    let user = await this.storageHelper.getPreferences(USER_DATA_LOGICAL_NAME);
    if (user === undefined) {
      user = null;
    }
    return user;
  }

  public async updateUserData(updatedUser: User): Promise<void> {
    await this.storageHelper.removePreferencesStorageItem(USER_DATA_LOGICAL_NAME);
    await this.storageHelper.setPreferences(USER_DATA_LOGICAL_NAME, updatedUser);
    return;
  }

  public logoutUser(): boolean {
    this.storageHelper.setPreferences(USER_DATA_LOGICAL_NAME, undefined);
    this.router.navigate(['/tabs/login']);
    return false;
  }
}
