import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() objectToRemove = '';
  @Input() locationWhereObjectIsToBeRemoved = '';
  @Input() additionalInfo = '';
  @Input() action = '';
  @Input() toggleQuestionText = null;

  public isToggled = false;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  public cancel(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      return this.modalCtrl.dismiss(null, 'close');
    }
  }

  public confirm() {
    return this.modalCtrl.dismiss(this.isToggled, 'confirm');
  }

  changeIsToggled(isToggled: any) {
    this.isToggled = isToggled;
  }
}
