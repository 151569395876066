import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { RequestStatus } from 'app/models/request-status.model';
import { UserRegistrationParameter } from 'app/models/user-registration-parameter.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterUserService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async registerUser(userRegistrationParameter: UserRegistrationParameter): Promise<number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(userRegistrationParameter);
      const returnObservable = this.http.post<RequestStatus>(environment.apiUrlUserRegistration, body, options);

      const requestStatus: RequestStatus = await lastValueFrom(returnObservable);

      return requestStatus.errorCode;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
