import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { PreviewImage } from 'app/models/preview-image.model';
import { User } from 'app/models/user.model';
import { Verification } from 'app/models/verification.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFileUploadService {
  public errorMessage = '';
  constructor(private http: HttpClient) {}

  public async uploadFile(user: User, image: File): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const formData = new FormData();
      formData.append('file', image, image.name);
      formData.append('json', `{"name": "${image.name}"}`);

      const responseObservable = this.http.post(
        environment.apiUrlUserFilesUpload.replace('{{user_id}}', user.id.toString()),
        formData,
        options,
      );
      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async updateFile(user: User, previewImage: PreviewImage): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const body = `{ "name": "${previewImage.name}" }`;

      const responseObservable = this.http.patch(
        environment.apiUrlUserFilesUpdate
          .replace('{{user_id}}', user.id.toString())
          .replace('{{preview_image_id}}', previewImage.id.toString()),
        body,
        options,
      );
      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async deleteFile(user: User, previewImage: PreviewImage): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const responseObservable = this.http.delete(
        environment.apiUrlUserFilesDelete
          .replace('{{user_id}}', user.id.toString())
          .replace('{{preview_image_id}}', previewImage.id.toString()),
        options,
      );
      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async requestFileVerification(user: User, previewImage: PreviewImage): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const verification: Verification = new Verification();
      verification.instituteId = previewImage.selectedInstitute.id;

      const body = verification;

      const responseObservable = this.http.post(
        environment.apiUrlUserFilesVerification
          .replace('{{user_id}}', user.id.toString())
          .replace('{{preview_image_id}}', previewImage.id.toString()),
        body,
        options,
      );
      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async deleteFileVerification(user: User, previewImage: PreviewImage): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const responseObservable = this.http.delete(
        environment.apiUrlUserFilesVerification
          .replace('{{user_id}}', user.id.toString())
          .replace('{{preview_image_id}}', previewImage.id.toString()),
        options,
      );
      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
