import { AfterViewInit, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserHelper } from 'app/helpers/user-helper';
import { Address } from 'app/models/address.model';
import { DIFFERENT_PAYER_ADDRESS_TERM } from 'app/models/constants-app-texts';
import { SALUTATION_VALUES } from 'app/models/constants-app-values';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { PayerData } from 'app/models/payer-data.model';
import { RequestStatus } from 'app/models/request-status.model';
import { UserCourseRegistrationParameter } from 'app/models/user-course-registration-parameter.model';
import { UserData } from 'app/models/user-data.model';
import { User } from 'app/models/user.model';
import { UserCourseRegistrationService } from 'app/services/user-course-registration.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-user-course-registration',
  templateUrl: './user-course-registration.component.html',
  styleUrls: ['./user-course-registration.component.scss'],
})
export class UserCourseRegistrationComponent implements AfterViewInit {
  @Input() courseId: number = null;
  @Input() user: User = null;
  @Input() registrationTypeId: number = null;

  public salutationOptions = SALUTATION_VALUES;
  public eighteenYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().slice(0, 10);
  public isLoading = false;
  public isPayerAccordionOpen: boolean = false;
  public readonly DIFFERENT_PAYER_ADDRESS_TERM = DIFFERENT_PAYER_ADDRESS_TERM;

  public userCourseRegistrationParameter: UserCourseRegistrationParameter = {
    courseId: null,
    registrationTypeId: null,
    saveData: false,
    userData: null,
    payerData: null,
  };
  public formGroup: FormGroup = this.formBuilder.group({
    firstNameInput: [
      this.userCourseRegistrationParameter.userData?.firstName,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß]+$/),
      ],
    ],
    lastNameInput: [
      this.userCourseRegistrationParameter.userData?.lastName,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß]+$/),
      ],
    ],
    emailInput: [
      this.userCourseRegistrationParameter.userData?.email,
      [Validators.required, Validators.minLength(6), Validators.maxLength(255), Validators.email],
    ],
    salutationSelect: [
      this.userCourseRegistrationParameter.userData?.salutationId,
      [Validators.required, Validators.pattern(/^[12]$/)],
    ],
    birthDateDateSelector: [
      new Date(this.userCourseRegistrationParameter.userData?.birthDate).toJSON(),
      Validators.required,
    ],
    streetNameInput: [
      this.userCourseRegistrationParameter.userData?.address.streetName,
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß\.]+$/),
      ],
    ],
    streetNumberInput: [
      this.userCourseRegistrationParameter.userData?.address.streetNumber,
      [Validators.required, Validators.pattern(/^\d{1,4}[a-z]?$/i)],
    ],
    zipInput: [
      this.userCourseRegistrationParameter.userData?.address.zip,
      [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^[0-9]{5}$/)],
    ],
    cityInput: [
      this.userCourseRegistrationParameter.userData?.address.city,
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß]+$/),
      ],
    ],
    firstNamePayerInput: [this.userCourseRegistrationParameter.payerData?.firstName],
    lastNamePayerInput: [this.userCourseRegistrationParameter.payerData?.lastName],
    salutationPayerSelect: [this.userCourseRegistrationParameter.payerData?.salutationId],
    streetNamePayerInput: [this.userCourseRegistrationParameter.payerData?.address?.streetName],
    streetNumberPayerInput: [this.userCourseRegistrationParameter.payerData?.address?.streetNumber],
    zipPayerInput: [this.userCourseRegistrationParameter.payerData?.address?.zip],
    cityPayerInput: [this.userCourseRegistrationParameter.payerData?.address?.city],
    isDataSavingAccepted: [this.userCourseRegistrationParameter.saveData],
  });

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private userHelper: UserHelper,
    private userCourseRegistrationService: UserCourseRegistrationService,
  ) {}

  ngAfterViewInit() {
    this.userCourseRegistrationParameter.userData = new UserData();
    this.userCourseRegistrationParameter.userData.address = new Address();
    this.userCourseRegistrationParameter.courseId = this.courseId;
    this.userCourseRegistrationParameter.userData.birthDate = this.eighteenYearsAgo;
    this.formGroup.controls.birthDateDateSelector.setValue(this.eighteenYearsAgo);
  }

  public changeSelectedFirstName(selectedFirstName: string): void {
    this.userCourseRegistrationParameter.userData.firstName = selectedFirstName;
  }

  public changeSelectedLastName(selectedLastName: string): void {
    this.userCourseRegistrationParameter.userData.lastName = selectedLastName;
  }

  public changeSelectedEmail(selectedEmail: string): void {
    this.userCourseRegistrationParameter.userData.email = selectedEmail;
  }

  public changeSelectedSalutation(selectedSalutation: number): void {
    this.userCourseRegistrationParameter.userData.salutationId = selectedSalutation;
  }

  public changeSelectedBirthDate(selectedBirthDate: string): void {
    this.userCourseRegistrationParameter.userData.birthDate = new Date(selectedBirthDate).toJSON().slice(0, 10);
  }

  public changeSelectedStreetName(selecteStreetName: string): void {
    this.userCourseRegistrationParameter.userData.address.streetName = selecteStreetName;
  }

  public changeSelectedStreetNumber(selectedStreetNumber: string): void {
    this.userCourseRegistrationParameter.userData.address.streetNumber = selectedStreetNumber;
  }

  public changeSelectedZip(selectedZip: string): void {
    this.userCourseRegistrationParameter.userData.address.zip = selectedZip;
  }

  public changeSelectedCity(selectedCity: string): void {
    this.userCourseRegistrationParameter.userData.address.city = selectedCity;
  }

  public changeSelectedFirstNamePayer(selectedFirstNamePayer: string): void {
    this.userCourseRegistrationParameter.payerData.firstName = selectedFirstNamePayer;
  }

  public changeSelectedLastNamePayer(selectedLastNamePayer: string): void {
    this.userCourseRegistrationParameter.payerData.lastName = selectedLastNamePayer;
  }

  public changeSelectedSalutationPayer(selectedSalutationPayer: number): void {
    this.userCourseRegistrationParameter.payerData.salutationId = selectedSalutationPayer;
  }

  public changeSelectedStreetNamePayer(selectedStreetNamePayer: string): void {
    this.userCourseRegistrationParameter.payerData.address.streetName = selectedStreetNamePayer;
  }

  public changeSelectedStreetNumberPayer(selectedStreetNumberPayer: string): void {
    this.userCourseRegistrationParameter.payerData.address.streetNumber = selectedStreetNumberPayer;
  }

  public changeSelectedZipPayer(selectedZipPayer: string): void {
    this.userCourseRegistrationParameter.payerData.address.zip = selectedZipPayer;
  }

  public changeSelectedCityPayer(selectedCityPayer: string): void {
    this.userCourseRegistrationParameter.payerData.address.city = selectedCityPayer;
  }

  public changeIsDataSavingAccepted(isDataSavingAccepted: boolean): void {
    this.userCourseRegistrationParameter.saveData = isDataSavingAccepted;
  }

  public toggleIsDataSavingAccepted(): void {
    this.formGroup.controls.isDataSavingAccepted.setValue(!this.formGroup.controls.isDataSavingAccepted.getRawValue());
    this.changeIsDataSavingAccepted(this.formGroup.controls.isDataSavingAccepted.getRawValue());
  }

  public cancel(event: MouseEvent): Promise<boolean> {
    if (event.target === event.currentTarget) {
      return this.modalCtrl.dismiss(null, 'close');
    }
  }

  public error(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, 'error');
  }

  public sendRegistration(): void {
    this.isLoading = true;
    this.userCourseRegistrationParameter.registrationTypeId = this.registrationTypeId;
    this.userCourseRegistrationService
      .registerUserForCourse(this.userCourseRegistrationParameter, this.user.token, this.user.id)
      .pipe(take(1))
      .subscribe((response) => {
        if ((response as number) === 401 || (response as RequestStatus)?.errorCode === 4) {
          this.error();
          return;
        }
        if (response === SYSTEM_ERROR_CODE) {
          this.userHelper.logoutUser();
          return;
        }
        this.confirm();
      });
  }

  private confirm(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  public changeAccordionStatus(event: any): void {
    //returns wenn event kein array ist, da ionChange (undokumentiert von Ionic)
    //auch auf Form änderungen innerhalb des Accordions feuert
    if (Array.isArray(event.detail.value) === false) {
      return;
    }

    const openedAccordions: string[] = event.detail.value;

    if (openedAccordions.find((x) => x === this.DIFFERENT_PAYER_ADDRESS_TERM) !== undefined) {
      this.isPayerAccordionOpen = true;
    } else {
      this.isPayerAccordionOpen = false;
    }
    this.adjustPayerOptionalityToAccordionStatus();
  }

  private adjustPayerOptionalityToAccordionStatus(): void {
    if (this.isPayerAccordionOpen === true) {
      this.userCourseRegistrationParameter.payerData = new PayerData();
      this.userCourseRegistrationParameter.payerData.address = new Address();
      this.formGroup.controls.firstNamePayerInput.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß]+$/),
      ]);
      this.formGroup.controls.lastNamePayerInput.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß]+$/),
      ]);
      this.formGroup.controls.salutationPayerSelect.setValidators([Validators.required, Validators.pattern(/^[12]$/)]);
      this.formGroup.controls.streetNamePayerInput.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß\.]+$/),
      ]);
      this.formGroup.controls.streetNumberPayerInput.setValidators([
        Validators.required,
        Validators.pattern(/^\d{1,4}[a-z]?$/i),
      ]);
      this.formGroup.controls.zipPayerInput.setValidators([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern(/^[0-9]{5}$/),
      ]);
      this.formGroup.controls.cityPayerInput.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z\s\-äöüß]+$/),
      ]);
    }
    if (this.isPayerAccordionOpen === false) {
      this.userCourseRegistrationParameter.payerData = null;
      this.formGroup.controls.firstNamePayerInput.setValidators(null);
      this.formGroup.controls.lastNamePayerInput.setValidators(null);
      this.formGroup.controls.salutationPayerSelect.setValidators(null);
      this.formGroup.controls.streetNamePayerInput.setValidators(null);
      this.formGroup.controls.streetNumberPayerInput.setValidators(null);
      this.formGroup.controls.zipPayerInput.setValidators(null);
      this.formGroup.controls.cityPayerInput.setValidators(null);

      this.formGroup.controls.firstNamePayerInput.setValue(null);
      this.formGroup.controls.lastNamePayerInput.setValue(null);
      this.formGroup.controls.salutationPayerSelect.setValue(null);
      this.formGroup.controls.streetNamePayerInput.setValue(null);
      this.formGroup.controls.streetNumberPayerInput.setValue(null);
      this.formGroup.controls.zipPayerInput.setValue(null);
      this.formGroup.controls.cityPayerInput.setValue(null);
    }
    this.formGroup.controls.firstNamePayerInput.updateValueAndValidity();
    this.formGroup.controls.lastNamePayerInput.updateValueAndValidity();
    this.formGroup.controls.salutationPayerSelect.updateValueAndValidity();
    this.formGroup.controls.streetNamePayerInput.updateValueAndValidity();
    this.formGroup.controls.streetNumberPayerInput.updateValueAndValidity();
    this.formGroup.controls.zipPayerInput.updateValueAndValidity();
    this.formGroup.controls.cityPayerInput.updateValueAndValidity();
  }
}
