import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable()
export class StorageHelper {
  constructor() {}

  public async setPreferences(key: string, value: any): Promise<void> {
    await Preferences.set({
      // eslint-disable-next-line object-shorthand
      key: key,
      value: JSON.stringify(value),
    });
  }

  public async getPreferences(key: string): Promise<any> {
    // eslint-disable-next-line object-shorthand
    const preferenceResult = await Preferences.get({ key: key });
    if (typeof preferenceResult.value === 'string') {
      return preferenceResult.value === '' ? null : JSON.parse(preferenceResult.value);
    }
  }

  public setSessionStorage(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getSessionStorage(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }
}
