<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
  *ngIf="isLoading === true"></ion-img>
<ion-row class="background-color-white">
  <ion-col class="margin-padding-0" size="2" offset="10">
    <ion-button expand="block" (click)="cancel($event)" class="close-button margin-padding-0">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
<div class="modal-div ion-align-items-center">
  <ion-row>
    <ion-col size="10" offset="1">
      <form [formGroup]="formGroup">
        <ion-row>
          <ion-col>
            <ion-text class="color-primary">
              <h5>
                <strong>Bitte gib deine Kontaktdaten ein.</strong>
              </h5>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text class="color-primary">
              <p>Wir leiten die von dir angegebenen Daten an das zuständige Institut weiter, damit es sich in Verbindung
                mit dir setzen kann.
              </p>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col size="4">
            <ion-select interface="action-sheet" class="form-field ion-text-start" placeholder="Geschlecht"
              formControlName="genderSelect" (ionChange)="changeSelectedGender($any($event.target).value)">
              <ion-select-option *ngFor="let genderOption of genderOptions" [value]="genderOption.id">
                {{genderOption.name}}
              </ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size="8">
            <ion-input class="form-field ion-text-start" placeholder="Nachname" formControlName="lastNameInput"
              (ionChange)="changeSelectedLastName($any($event.target).value)">
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col size="12">
            <ion-input class="form-field ion-text-start" placeholder="Vorname" formControlName="firstNameInput"
              (ionChange)="changeSelectedFirstName($any($event.target).value)">
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col size="4">
            <ion-datetime-button datetime="datetime"></ion-datetime-button>
            <ion-modal [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime id="datetime" [showDefaultButtons]="true" doneText="Bestätigen" cancelText="Abbrechen"
                  presentation="date" presentation="date" formControlName="birthDateDateSelector"
                  (ionChange)="changeSelectedBirthDate($any($event.target.value))">
                </ion-datetime>
              </ng-template>
            </ion-modal>
          </ion-col>
          <ion-col size="8">
            <ion-input class="form-field ion-text-start" type="email" placeholder="E-Mail" formControlName="emailInput"
              (ionChange)="changeSelectedEmail($any($event.target).value)">
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col size="10">
            <ion-input class="form-field ion-text-start" placeholder="Straße" formControlName="streetNameInput"
              (ionChange)="changeSelectedStreetName($any($event.target.value))">
            </ion-input>
          </ion-col>
          <ion-col size="2">
            <ion-input class="form-field ion-text-start" placeholder="Nr." formControlName="streetNumberInput"
              (ionChange)="changeSelectedStreetNumber($any($event.target.value))">
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col size="5">
            <ion-input class="form-field ion-text-start" placeholder="PLZ" formControlName="zipInput"
              (ionChange)="changeSelectedZip($any($event.target.value))">
            </ion-input>
          </ion-col>
          <ion-col size="7">
            <ion-input class="form-field ion-text-start" placeholder="Ort" formControlName="cityInput"
              (ionChange)="changeSelectedCity($any($event.target.value))">
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-accordion-group [multiple]="true" (ionChange)="changeAccordionStatus($event)">
          <ion-row>
            <ion-col size="12">
              <ion-accordion value="Abweichende Rechnungsadresse">
                <ion-item lines="none" slot="header" color="white">
                  {{DIFFERENT_PAYER_ADDRESS_TERM}}
                </ion-item>
                <div slot="content">
                  <ion-row class="ion-margin-top">
                    <ion-col size="4">
                      <ion-select interface="action-sheet" class="form-field ion-text-start" placeholder="Geschlecht"
                        formControlName="genderPayerSelect"
                        (ionChange)="changeSelectedGenderPayer($any($event.target.value))">
                        <ion-select-option *ngFor="let genderOption of genderOptions"
                          [value]="genderOption.id">
                          {{genderOption.name}}
                        </ion-select-option>
                      </ion-select>
                    </ion-col>
                    <ion-col size="8">
                      <ion-input class="form-field ion-text-start" placeholder="Nachname"
                        formControlName="lastNamePayerInput"
                        (ionChange)="changeSelectedLastNamePayer($any($event.target.value))">
                      </ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-margin-top">
                    <ion-col size="12">
                      <ion-input class="form-field ion-text-start" placeholder="Vorname"
                        formControlName="firstNamePayerInput"
                        (ionChange)="changeSelectedFirstNamePayer($any($event.target.value))">
                      </ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-margin-top">
                    <ion-col size="10">
                      <ion-input class="form-field ion-text-start" placeholder="Straße"
                        formControlName="streetNamePayerInput"
                        (ionChange)="changeSelectedStreetNamePayer($any($event.target.value))">
                      </ion-input>
                    </ion-col>
                    <ion-col size="2">
                      <ion-input class="form-field ion-text-start" placeholder="Nr."
                        formControlName="streetNumberPayerInput"
                        (ionChange)="changeSelectedStreetNumberPayer($any($event.target.value))">
                      </ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-margin-top">
                    <ion-col size="5">
                      <ion-input class="form-field ion-text-start" placeholder="PLZ" formControlName="zipPayerInput"
                        (ionChange)="changeSelectedZipPayer($any($event.target.value))">
                      </ion-input>
                    </ion-col>
                    <ion-col size="7">
                      <ion-input class="form-field ion-text-start" placeholder="Ort" formControlName="cityPayerInput"
                        (ionChange)="changeSelectedCityPayer($any($event.target.value))">
                      </ion-input>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-accordion>
            </ion-col>
          </ion-row>
        </ion-accordion-group>
        <ion-row class="ion-margin-top country-wide-row">
          <ion-col size="1" class="display-flex justify-content-end ion-align-items-center">
            <ion-checkbox class="highlighted-checkbox"
              (ionChange)="changeIsDataSavingAccepted($any($event.target).checked)" labelPlacement="start"
              formControlName="isDataSavingAccepted"></ion-checkbox>
          </ion-col>
          <ion-col size="11" class="ion-text-start">
            <ion-label class="highlighted-bold-text" (click)="toggleIsDataSavingAccepted()">
              Ich möchte, dass meine Addressdaten für die Zukunft gespeichert werden <br />
              <a href="#/tabs/datenschutz" aria-label="Datenschutz" class="text-magenta">Datenschutz</a>
            </ion-label>
          </ion-col>
        </ion-row>

        <div class="ion-float-right ion-margin-bottom ion-margin-top">
          <ion-buttons>
            <ion-row>
              <ion-col>
                <ion-button [disabled]="isLoading" (click)="cancel($event)"><strong
                    (click)="cancel($event)">Abbrechen</strong></ion-button>
              </ion-col>
              <ion-col>
                <ion-button [disabled]="isLoading" expand="block" (click)="sendRegistration()"
                  [disabled]="formGroup.valid === false">
                  Absenden
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-buttons>
        </div>
      </form>
    </ion-col>
  </ion-row>
</div>
