import { Injectable } from '@angular/core';
import { REGISTRATION_TYPE_ID } from 'app/models/constants-app-values';
import { SUCCESS_ERROR_CODE, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplate } from 'app/models/course-template';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { StorableCourseTemplateTreeBranch } from 'app/models/storable-course-template-tree-branch.model';
import { StoredCourseTemplateTreeBranch } from 'app/models/stored-course-template-tree-branch.model';
import { UserCourseTemplateRating } from 'app/models/user-course-institute-template-rating.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { User } from 'app/models/user.model';
import { ErrorMessageService } from 'app/services/error-message.service';
import { SaveUserCourseTemplateRatingService } from 'app/services/save-user-course-institute-template-rating.service';
import { UserWatchlistService } from 'app/services/user-watchlist.service';
import { Observable, of, take } from 'rxjs';
import { Course } from './../models/course.model';
import { CourseParameter } from './../models/course_parameter.model';
import { GetCoursesService } from './../services/get-courses.service';
import { DateHelper } from './date-helper';

@Injectable()
export class CourseHelper {
  public sendCourseRatingErrrorMessage = '';
  public errorCollection: string = '';

  constructor(
    private courseService: GetCoursesService,
    private dateHelper: DateHelper,
    private errorMessageService: ErrorMessageService,
    private userCourseRatingService: SaveUserCourseTemplateRatingService,
    private userWatchListService: UserWatchlistService,
  ) {}

  public async searchCourses(selectedCourseParameter: CourseParameter): Promise<Course[] | number> {
    let coursesResult: Course[] | number = [];

    coursesResult = await this.courseService.searchCourses(selectedCourseParameter);

    return coursesResult;
  }

  public getCourseTemplates(courses: Course[]): CourseTemplate[] {
    const courseTemplates: CourseTemplate[] = [];

    for (const courseItem of courses) {
      if (courseTemplates.find((x) => x.id === courseItem.courseTemplate.id) === undefined) {
        courseTemplates.push({
          id: courseItem.courseTemplate.id,
          name: courseItem.courseTemplate.name,
          courses: [],
        });
      }
    }

    for (const courseTemplateItem of courseTemplates) {
      courseTemplateItem.courses = courses.filter((x) => x.courseTemplate.id === courseTemplateItem.id);
    }

    return courseTemplates;
  }

  public isFavoriteCourse(currentCourseTemplateId: number, selectedCourseTemplateIds: number[]): boolean {
    return !(
      selectedCourseTemplateIds === null ||
      selectedCourseTemplateIds.find((x) => x === currentCourseTemplateId) === undefined
    );
  }

  public treeToBranch(tree: CourseTemplateTree): CourseTemplateTreeBranch {
    const branch = new CourseTemplateTreeBranch();
    branch.depth = tree?.depth;
    branch.id = tree?.id;
    branch.name = tree?.name;
    branch.templateIds = [];
    branch.templateIds.push(tree?.id);
    if (tree?.nextPossibilities !== undefined && tree.nextPossibilities.length !== 0) {
      branch.nextBranch = new CourseTemplateTreeBranch();
      branch.nextBranch.depth = tree.nextPossibilities[0]?.depth;
      branch.nextBranch.id = tree.nextPossibilities[0]?.id;
      branch.nextBranch.name = tree.nextPossibilities[0]?.name;
      branch.templateIds.push(tree?.nextPossibilities[0].id);
    }
    return branch;
  }

  public extractParameterFromStoredCourseTemplateTree(
    storedCourseTemplateTreeBranch: StoredCourseTemplateTreeBranch,
  ): CourseTemplateTreeParameter {
    const parameter = new CourseTemplateTreeParameter();
    parameter.templateIds = [];

    if (storedCourseTemplateTreeBranch.templates !== undefined) {
      for (const template of storedCourseTemplateTreeBranch.templates) {
        parameter.templateIds.push(template.id);
      }
    }

    parameter.countryWide = storedCourseTemplateTreeBranch.countryWide;
    parameter.distance = storedCourseTemplateTreeBranch.distance;
    parameter.zip = storedCourseTemplateTreeBranch.zip;
    parameter.requirementQualificationTypeId = storedCourseTemplateTreeBranch.requirementQualificationType.id;
    if (storedCourseTemplateTreeBranch.requirementQualificationCategory !== undefined) {
      parameter.requirementQualificationCategoryId = storedCourseTemplateTreeBranch.requirementQualificationCategory.id;
    }
    if (storedCourseTemplateTreeBranch.targetQualificationCategory !== undefined) {
      parameter.targetQualificationCategoryId = storedCourseTemplateTreeBranch.targetQualificationCategory.id;
    }

    return parameter;
  }

  public storeCourse(user: User, favoritedCourseTemplateIds: number[]): void {
    if (user !== null) {
      this.userWatchListService.storeCourse(user, favoritedCourseTemplateIds[favoritedCourseTemplateIds.length - 1]);
    }
  }

  public async storeCourseTemplateTree(
    user: User,
    favoritedCourseTemplateTreeBranches: StorableCourseTemplateTreeBranch[],
  ): Promise<number | boolean> {
    if (user !== null) {
      return await this.userWatchListService.storeCourseTemplateTreeBranch(user, favoritedCourseTemplateTreeBranches);
    }
  }

  public storedToStorableCourseTemplateTreeBranches(
    storedBranches: StoredCourseTemplateTreeBranch[],
  ): StorableCourseTemplateTreeBranch[] {
    const storableBranches: StorableCourseTemplateTreeBranch[] = [];
    for (const storedBranch of storedBranches) {
      const storableBranch: StorableCourseTemplateTreeBranch = new StorableCourseTemplateTreeBranch();

      storableBranch.countryWide = storedBranch.countryWide;
      storableBranch.distance = storedBranch.distance;
      storableBranch.requirementQualificationCategoryId = storedBranch.requirementQualificationCategory?.id;
      storableBranch.requirementQualificationTypeId = storedBranch.requirementQualificationType.id;
      storableBranch.targetQualificationCategoryId = storedBranch.targetQualificationCategory?.id;
      storableBranch.templateIds = [];
      if (storedBranch.templates !== undefined) {
        for (const storedBranchTemplate of storedBranch.templates) {
          storableBranch.templateIds.push(storedBranchTemplate.id);
        }
      }
      storableBranch.zip = storedBranch.zip;
      (storableBranch.storageId = storedBranch.id), storableBranches.push(storableBranch);
    }

    return storableBranches;
  }

  public canCoursetemplateBeRegisteredFor(course: Course, userCourseRegistrations: UserCourseRegistration[]): boolean {
    if (
      course.isRegistered === false &&
      course.courseTemplate.registrationTypeId !== REGISTRATION_TYPE_ID.noRegistration &&
      course.registrationTypeId !== REGISTRATION_TYPE_ID.noRegistration &&
      this.canCourseTemplateBeRated(course.courseTemplate, userCourseRegistrations) === false &&
      this.hasCourseTemplateBeenRated(course.courseTemplate, userCourseRegistrations) === false
    ) {
      return true;
    }
    return false;
  }

  public canCourseTemplateBeRated(
    courseTemplate: CourseTemplate,
    userCourseRegistrations: UserCourseRegistration[],
  ): boolean {
    if (
      userCourseRegistrations.find(
        (x) =>
          x.courseTemplate.id === courseTemplate.id &&
          x.hasRating === false &&
          this.dateHelper.isDateInThePast(x.endAt.toString()),
      ) !== undefined
    ) {
      return true;
    }
    return false;
  }

  public hasCourseTemplateBeenRated(
    courseTemplate: CourseTemplate,
    userCourseRegistrations: UserCourseRegistration[],
  ): boolean {
    if (userCourseRegistrations.find((x) => x.courseTemplate.id === courseTemplate.id && x.hasRating === true)) {
      return true;
    }
    return false;
  }

  public sendCourseRating(courseRating: UserCourseTemplateRating, user: User): Observable<number> {
    try {
      let responseObservable: Observable<number>;
      this.userCourseRatingService
        .saveUserCourseTemplateRating(courseRating, user)
        .pipe(take(1))
        .subscribe(() => {
          responseObservable = of(SUCCESS_ERROR_CODE);
          return;
        });
      return responseObservable;
    } catch (error) {
      this.sendCourseRatingErrrorMessage = error.message;
      return of(SYSTEM_ERROR_CODE);
    }
  }
}
