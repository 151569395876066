import { UserData } from './user-data.model';

export class User {
  public id: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public genderId: number;
  public token: string;
  public userData: UserData;
}
