export class CourseParameter {
  public courseId: number;
  public maxResults: number;
  public startIndex: number;
  public partitionInstituteTemplateCount: number;
  public learnMethodId: number;
  public zip: string;
  public distance: number;
  public qualificationCategoryId: number;
  public courseTemplateIds: number[];
  public fullTextSearch: string;
  public qualificationTypeId: number;
  public fundable: number;
  public qualificationId: number;
}
