import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-information',
  templateUrl: './legal-information.page.html',
  styleUrls: ['./legal-information.page.scss'],
})
export class LegalInformationPage implements OnInit {
  constructor() {}

  ngOnInit() {}

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }
}
