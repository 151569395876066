<div class="ion-page">
  <ion-content [scrollY]="isSwiperCooldown === false" [fullscreen]="true" scrollEvents="true" appResizeActions
    class="fancy-background-{{hasSearched}}">
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app *ngIf="hasSearched === false"></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
        style="z-index: 10000" *ngIf="areTreesLoading === true || areQualificationsLoading === true"></ion-img>

      <ion-row>
        <ion-col>
          <ion-row class="chip-row-career" *ngIf="hasSearched === true">
            <ion-col class="chip-col-career" size-md="8" offset-md="2">
              <ion-chip aria-label="zurück zur Karrieresuche" (click)="storageHelper.setSessionStorage(hasSearchedLogicalName, false);
                hasSearched = false; areQualificationsLoading = true; browserInteractionHelper.refresh();">
                <ion-icon class="text-white" name="arrow-back" alt="Zurück zur Suche"></ion-icon>&nbsp;Suche
              </ion-chip>
              <ion-chip *ngFor="let chipItem of chipSearchItems" [disabled]="true">{{chipItem.term}}: {{chipItem.value}}
              </ion-chip>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-md="8" offset-md="2">
              <div class="center-element">
                <div *ngIf="hasSearched === true && amountOfPreviousCareerBranches === 0 &&
                  courseTemplateTreeBranches.length !== 0 && areTooltipsActive === true"
                  class="swipe-animation-container">
                  <ion-text>wischen zum weiterschauen</ion-text>
                  <div class="swipe-animation-circle"></div>
                </div>
                <app-career-search class="display-grid" *ngIf="hasSearched === false && areQualificationsLoading === false
                  && areTreesLoading === false" (searchEvent)="searchCourseTemplateTrees($event)"
                  (requirementQualificationTypeEvent)="requirementQualificationTypeEventToInput($any($event))"
                  (targetQualificationCategoryEvent)="targetQualificationCategoryEventToInput($any($event))"
                  (requirementQualificationCategoryEvent)="requirementQualificationCategoryEventToInput($any($event))"
                  [searchFailed]="searchFailed"
                  [areTreesLoading]="areTreesLoading" [qualificationCategories]="qualificationCategories"
                  [qualificationTypes]="qualificationTypes" [isLoggedIn]="isLoggedIn"></app-career-search>
                <app-tree *ngIf="hasSearched === true
                  && areTreesLoading === false && areQualificationsLoading === false"
                  [amountOfPreviousCareerBranches]="amountOfPreviousCareerBranches"
                  [courseTemplateTreeBranches]="courseTemplateTreeBranches" [content]="content"
                  [searchedCourseTemplateTreeParameter]="searchedCourseTemplateTreeParameter"
                  [selectedRequirementQualificationType]="requirementQualificationType"
                  [selectedRequirementQualificationCategory]="requirementQualificationCategory"
                  [selectedTargetQualificationCategory]="targetQualificationCategory" [isActiveTab]="isActiveTab"
                  (changeIsSwiperCooldownEvent)="isSwiperCooldown"
                  [nextCareerSearchStartIndex]="nextCareerSearchStartIndex">
                </app-tree>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
