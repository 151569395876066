import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.page.html',
  styleUrls: ['./user-agreement.page.scss'],
})
export class UserAgreementPage implements OnInit {
  constructor() {}

  ngOnInit() {}

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }
}
