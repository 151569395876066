<ion-content class="ion-padding">
  <div class="base-content-top-margin">
    <app-header-app></app-header-app>
  </div>
  <ion-card>
    <ion-input style="display:none">

      <!--dieses input feld fixt einen unaufgeklärten autofocus bug
    für den keine andere Lösung gefunden wurde-->
    </ion-input>
    <ion-input class="selection-search" placeholder="Suche" (keyup)="filterContent($any($event.target).value)">
    </ion-input>
  </ion-card>
  <ion-card>
    <ion-row>
      <ion-col size="10">
        <h3 class="color-primary">{{title}}</h3>
      </ion-col>
      <ion-col class="margin-padding-0" size="2">
        <div class="close-button-div">
          <ion-button expand="block" (click)="cancel()" class="close-button">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card-content class="ion-text-start">
          <ion-list>
            <ion-radio-group value="{{selectedOption}}">
              <ion-item *ngFor="let optionItem of filteredOptions" (click)="confirm(optionItem.id.toString())">
                <label attr.aria-label="{{optionItem.name}}-option">{{optionItem.name}}</label>
                <ion-radio slot="start" value="{{optionItem.id}}"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-card-content>
      </ion-col>
    </ion-row>
    <ion-footer>
      <ion-button expand="block" (click)="confirm(null)">
        Auswahl aufheben
      </ion-button>
    </ion-footer>
  </ion-card>
</ion-content>
