import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { STANDARD_ERROR_CODE } from 'app/models/constants-status';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  public photo: Photo;
  constructor() {}
  public async takePhoto(): Promise<void | number> {
    try {
      const photo = await Camera.getPhoto({
        quality: 97,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
      });

      this.photo = photo;
    } catch (error) {
      console.error(error);
      return STANDARD_ERROR_CODE;
    }
  }
}
