import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsComponent } from './components/layout/tabs/tabs.component';
const routes: Routes = [
  {
    path: 'tabs',
    component: TabsComponent,
    children: [
      {
        path: 'startseite',
        loadChildren: () => import('./pages/main-pages/home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'suche',
        loadChildren: () => import('./pages/main-pages/search/search.module').then((m) => m.SearchPageModule),
      },
      {
        path: 'merkliste',
        loadChildren: () =>
          import('./pages/main-pages/watch-list/watch-list.module').then((m) => m.WatchListPageModule),
      },
      {
        path: 'karrierevorschlage',
        loadChildren: () => import('./pages/main-pages/tree/tree.module').then((m) => m.TreePageModule),
      },
      {
        path: 'registrierung',
        loadChildren: () =>
          import('./pages/user-pages/user-registration/user-registration.module').then(
            (m) => m.UserRegistrationPageModule,
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/user-pages/user-login/user-login.module').then((m) => m.UserLoginPageModule),
      },
      {
        path: 'profil',
        loadChildren: () =>
          import('./pages/user-pages/user-profile/user-profile.module').then((m) => m.UserProfilePageModule),
      },
      {
        path: 'datenschutz',
        loadChildren: () =>
          import('./pages/static-pages/data-protection/data-protection.module').then((m) => m.DataProtectionPageModule),
      },
      {
        path: 'impressum',
        loadChildren: () =>
          import('./pages/static-pages/legal-information/legal-information.module').then(
            (m) => m.LegalInformationPageModule,
          ),
      },
      {
        path: 'nutzung',
        loadChildren: () =>
          import('./pages/static-pages/user-agreement/user-agreement.module').then((m) => m.UserAgreementPageModule),
      },
    ],
  },
  {
    path: '',
    redirectTo: 'tabs/startseite',
    pathMatch: 'full',
  },
  {
    path: 'kurs/:id',
    loadChildren: () => import('./pages/misc-pages/course/course.module').then((m) => m.CoursePageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
