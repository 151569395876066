<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation" *ngIf="
       arePreviewImagesLoading === true ||
       isPhotoEditLoading === true ||
       isSelectedImageLoading === true ||
       isUpdateUserLoading === true"></ion-img>
      <ion-row>
        <ion-col size-md="8" offset-md="2">
          <div class="base-content-top-margin center-element display-flex">
            <ion-card *ngIf="isLoggedIn === false">
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Du bist nicht angemeldet.</h2>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col size="6">
                    <ion-button aria-label="zur Registrierung" href="#/tabs/registrierung" class="form-field"
                                expand="block">
                      Registrieren
                    </ion-button>
                  </ion-col>
                  <ion-col size="6">
                    <ion-button aria-label="zum Login" href="#/tabs/login"
                                class="form-field background-color-bright-blue-2" expand="block">
                      Login
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
            <ion-card *ngIf="isLoggedIn === true">
              <div class="stretcher-div"></div>
              <ion-row>
                <ion-col size="2" offset="10" class="margin-padding-0">
                  <ion-button lines="none" class="close-button margin-padding-0" (click)="pressLogoutButton()"
                              expand="block">
                    <ion-icon name="log-out-outline"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <ion-card-header class="ion-text-center">
                    <h4>Profil</h4>
                  </ion-card-header>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-card-content class="ion-text-start">
                    <ng-container *ngIf="isInEditMode === false">
                      <ion-item>
                        <ion-label>Vorname:</ion-label>
                        {{ user.firstName }}
                      </ion-item>
                      <ion-item>
                        <ion-label>Nachname:</ion-label>
                        {{ user.lastName }}
                      </ion-item>
                      <ion-item>
                        <ion-label>Email:</ion-label>
                        {{ user.email }}
                      </ion-item>
                      <ion-item>
                        <ion-label>Geschlecht:</ion-label>
                        {{ getCurrentUserGenderName() }}
                      </ion-item>
                    </ng-container>
                    <form [formGroup]="formGroup" class="visibility-{{isInEditMode}}">
                      <ion-row>
                        <ion-col>
                          <ion-input class="form-field" label="Vorname: "
                                     formControlName="firstNameInput"></ion-input>
                        </ion-col>
                        <ion-col>
                          <ion-input class="form-field" label="Nachname: "
                                     formControlName="lastNameInput"></ion-input>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col>
                          <ion-input class="form-field" label="Email: " formControlName="emailInput"></ion-input>
                        </ion-col>
                        <ion-col>
                          <ion-select class="form-field"
                                      label="Geschlecht:" formControlName="genderInput">
                            <ion-select-option *ngFor="let gender of GENDER_VALUES" value="{{gender.id}}">
                              {{ gender.name }}
                            </ion-select-option>
                          </ion-select>
                        </ion-col>
                      </ion-row>
                    </form>
                    <ion-row>
                      <ion-col class="ion-justify-content-end display-flex">
                        <ion-button *ngIf="isInEditMode === true" lines="none"
                                    aria-label="Speichern der Profiländerungen" [disabled]="isUserEditValid() === false"
                                    class="form-field ion-margin-top ion-margin-end background-color-bright-blue-2"
                                    (click)="safeButtonClick()" expand="block" aria-label="Profiländerungen speichern">
                          <ion-icon name="checkmark-outline"></ion-icon>
                        </ion-button>
                        <ion-button lines="none" aria-label="Bearbeiten des Profils" [disabled]="isEditButtonCooldown"
                                    class="form-field ion-margin-top ion-margin-end" (click)="editButtonClick()"
                                    expand="block">
                          <ng-container *ngIf="isInEditMode === false">
                            <i class="edit-profile-icon icon-person-outline icon-color-white" aria-label="Profil-Icon"
                               aria-disabled="true"></i>&nbsp;Bearbeiten
                          </ng-container>
                          <ng-container *ngIf="isInEditMode === true">
                            <ion-icon aria-label="Abbrechen" name="close-outline"></ion-icon>
                          </ng-container>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                    <ion-row *ngIf="isInEditMode === true">
                      <ion-col class="ion-text-end ion-margin-top">
                        <a class="color-primary" href="javascript:void(0);" (click)="deleteProfileLinkClicked()">Profil
                          löschen</a>
                      </ion-col>
                    </ion-row>
                    <ng-container *ngIf="isInEditMode === false">
                      <ion-card-header class="ion-text-center">
                        <h2>Unterlagen</h2>
                      </ion-card-header>
                      <ion-item lines="none" class="display-grid justify-content-end">
                        <ion-button class="rounded-button" (click)="cameraButtonClick()">
                          <ion-icon name="camera"></ion-icon>
                        </ion-button>
                      </ion-item>
                      <ion-item *ngIf="hasRequestFailed === true" lines="none" class="error-text">
                        {{ standardErrorText }}
                      </ion-item>
                      <ion-item *ngIf="hasCameraFailed === true" lines="none" class="error-text">
                        Beim Aufnehmen des Fotos ist etwas schiefgelaufen.
                      </ion-item>
                      <ion-row>
                        <ion-col size-sm="auto" size="6" *ngFor="let previewImage of previewImages">
                          <ion-card class="preview-image-card">
                            <ion-card-content (click)="loadSelectedImage(previewImage)">
                              <ion-img [src]="previewImage.previewImage"></ion-img>
                              <ion-row>
                                <ion-col class="preview-image-name">
                                  {{ previewImage.name }}
                                </ion-col>
                                <ion-col *ngIf="previewImage.verificationStatus === verificationStatus.declined"
                                         class="preview-image-icon" size="1">
                                  <ion-img src="../../../../assets/ui-icons/icon-not-verified-v1-0-0.svg"></ion-img>
                                </ion-col>
                                <ion-col *ngIf="previewImage.verificationStatus === verificationStatus.verified"
                                         class="preview-image-icon" size="1">
                                  <ion-img src="../../../../assets/ui-icons/icon-verified-v1-0-0.svg"></ion-img>
                                </ion-col>
                                <ion-col *ngIf="previewImage.verificationStatus === verificationStatus.pending"
                                         class="preview-image-icon" size="1">
                                  <ion-img
                                    src="../../../../assets/ui-icons/icon-verified-in-progress-v1-0-0.svg"></ion-img>
                                </ion-col>
                              </ion-row>
                            </ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>
                    </ng-container>
                  </ion-card-content>
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-justify-content-center display-flex">
          <a href="#/tabs/nutzung" aria-label="Nutzungsbedingungen" class="text-white">Nutzungsbedingungen</a>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
