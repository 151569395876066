<ion-grid>
  <app-chat-window [isChatOpen]="isChatOpen"></app-chat-window>
  <ion-row>
    <ion-col size="12" size-md="10" class="display-flex justify-content-end">
      <button (click)="toggleChat()" class="chatbot-button ion-margin-end">
        <i class="chatbot-icon icon-chatbot-outline icon-color-magenta" id="chatbot-pulse" aria-label="chatbot-öffnen"></i>
      </button>
    </ion-col>
  </ion-row>
</ion-grid>


<ion-tabs tabsHighlight="true" class="background-color-white">
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="profil">
      <i class="tab-bar-icons icon-person-outline icon-color-dark-blue-1" aria-label="profil-öffnen"></i>
    </ion-tab-button>
    <ion-tab-button tab="merkliste" aria-label="zur Merkliste" class="tab-button-nav">
      <i id="tab-button-watchlist-image" class="tab-bar-icons icon-bookmark-2-outline icon-color-dark-blue-1" aria-label="merkliste-öffnen"></i>
    </ion-tab-button>
    <ion-tab-button tab="suche" aria-label="zur Suche" class="tab-button-nav">
      <i id="tab-button-search-image" class="tab-bar-icons icon-magnifying-glass-outline icon-color-dark-blue-1" aria-label="suche-öffnen"></i>
    </ion-tab-button>
    <ion-tab-button tab="karrierevorschlage" aria-label="zu Karrierevorschläge" class="tab-button-nav">
      <i id="tab-button-nav-image" class="tab-bar-icons icon-link-website icon-color-dark-blue-1" aria-label="karrierevorschläge-öffnen"></i>
    </ion-tab-button>
  </ion-tab-bar>
  <ion-row class="footer-row footer-row-md">
    <ion-col size="6" size-md="5" offset-md="1" size-lg="4" offset-lg="2"
      class="ion-justify-content-start display-flex">
      <ion-text class="text-white ion-margin-start">Ein Angebot der ZWH e. V.</ion-text>
    </ion-col>
    <ion-col size="6" size-md="5" size-lg="4" class="ion-justify-content-end display-flex">
      <a href="#/tabs/impressum" aria-label="Impressum" class="text-white ion-margin-end">Impressum</a>
      <a href="#/tabs/datenschutz" aria-label="Datenschutz" class="text-white ion-margin-end">Datenschutz</a>
    </ion-col>
  </ion-row>
</ion-tabs>
<ion-row class="footer-row footer-row-sm">
  <ion-col size="6" size-md="5" offset-md="1" size-lg="4" offset-lg="2" class="ion-justify-content-start display-flex">
    <ion-text class="text-white">Ein Angebot der ZWH e. V.</ion-text>
  </ion-col>
  <ion-col size="6" size-md="5" size-lg="4" class="ion-justify-content-end display-flex">
    <a href="#/tabs/impressum" aria-label="Impressum" class="text-white ion-margin-end">Impressum</a>
    <a href="#/tabs/datenschutz" aria-label="Datenschutz" class="text-white">Datenschutz</a>
  </ion-col>
</ion-row>
