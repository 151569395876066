<ion-toolbar>
  <div class="box">
    <ion-grid>
      <ion-row>
        <ion-col size="3" size-lg="1" class="center-logo">
          <ion-img class="logo-size" src="assets/app-logo-v1-0-0.svg" alt="Hubgrade Logo"></ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-toolbar>
