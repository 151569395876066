import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-home-navigation-bar',
  templateUrl: './home-navigation-bar.component.html',
  styleUrls: ['./home-navigation-bar.component.scss'],
})
export class HomeNavigationBarComponent implements OnInit {
  @Input() isLoggedIn = false;

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {}

  public cancel() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
