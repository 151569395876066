<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
               *ngIf="isLoading === true"></ion-img>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <div class="base-content-top-margin center-element display-flex">
            <ion-card *ngIf="isLoggedIn === true">
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Du bist bereits eingeloggt.</h2>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col offset="1" size="10" size-md="6" offset-md="3">
                    <ion-button class="form-field" (click)="pressLogoutButton()" expand="block">
                      Ausloggen
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
            <ion-card *ngIf="isLoggedIn === false">
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Registrierung</h2>
              </ion-card-header>
              <ion-card-content class="ion-text-start" *ngIf="errorCode !== successErrorCode">
                <form [formGroup]="formGroup">
                  <ion-row>
                    <ion-col size="12" size-md="6">
                      <ion-input (keydown.enter)="formHelper.nextInput(formGroup, $event, 'lastNameInput')"
                                 class="form-field ion-text-start" placeholder="Vorname"
                                 formControlName="firstNameInput"
                                 (ionInput)="changeSelectedFirstName($any($event.target).value)">
                      </ion-input>
                    </ion-col>
                    <ion-col size="12" size-md="6">
                      <ion-input (keydown.enter)="formHelper.nextInput(formGroup, $event, 'emailInput')"
                                 class="form-field ion-text-start" placeholder="Nachname"
                                 formControlName="lastNameInput"
                                 (ionInput)="changeSelectedLastName($any($event.target).value)">
                      </ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="12">
                      <ion-input (keydown.enter)="formHelper.nextInput(formGroup, $event, 'emailInput')"
                        class="form-field ion-text-start" placeholder="Email" formControlName="emailInput"
                        (ionInput)="changeSelectedEmail($any($event.target).value)">
                      </ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-select
                        (keydown.enter)="formGroup.valid === false || isLoading === true ? null : registerUser()"
                        (ionChange)="changeSelectedGender($any($event.target).value)" class="form-field"
                        label="Wähle dein Geschlecht" formControlName="genderInput">
                        <ion-select-option value="1">Mann</ion-select-option>
                        <ion-select-option value="2">Frau</ion-select-option>
                        <ion-select-option value="3">Divers</ion-select-option>
                      </ion-select>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="ion-align-items-center display-flex">
                      <ion-label class="highlighted-bold-text ion-margin-end"
                                 [innerHTML]="sanitizer.bypassSecurityTrustHtml(USER_AGREEMENT_QUESTION)">
                      </ion-label>
                      <ion-checkbox (ionChange)="changeIsUserAgreementAgreed(($any($event.target).checked))"
                                    class="highlighted-checkbox" formControlName="userAgreementCheckbox">
                      </ion-checkbox>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="ion-align-items-center display-flex">
                      <ion-label class="highlighted-bold-text ion-margin-end"
                                 [innerHTML]="sanitizer.bypassSecurityTrustHtml(DATA_PROTECTION_QUESTION)">
                      </ion-label>
                      <ion-checkbox (ionChange)="changeIsDataProtectionAgreed(($any($event.target).checked))"
                                    class="highlighted-checkbox" formControlName="dataProtectionCheckbox">
                      </ion-checkbox>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6" offset="6" size-md="4" offset-md="8">
                      <ion-button class="form-field" type="email" (click)="pressRegistrationButton()" expand="block"
                                  [disabled]="formGroup.valid === false || isLoading === true">
                        Registrieren
                      </ion-button>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <a class="highlighted-text" aria-label="zum Login" href="#/tabs/login">Du hast bereits ein
                        Konto?</a>
                    </ion-col>
                    <ion-col class="ion-text-end">
                      <a class="highlighted-text" aria-label="zum Passwort zurücksetzen" href="{{passwordResetUrl}}{{ selectedUserRegistrationParameter.email ?
                      'email/' + selectedUserRegistrationParameter.email : '' }}">
                        Passwort vergessen?</a>
                    </ion-col>
                  </ion-row>

                  <ion-row *ngIf="errorCode === standardErrorCode">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Uns ist ein Fehler unterlaufen. Bitte versuche es später erneut.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="errorCode === userDataInputErrorCode">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Ein Fehler ist aufgetreten. Bitte korrigiere deine Eingabe.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="errorCode === 10">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Ein Fehler ist aufgetreten. Es gibt bereits einen Nutzer mit deiner Email Adresse.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </form>
              </ion-card-content>
              <ion-card-content *ngIf="errorCode === successErrorCode">
                Eine Email wurde an deine angegebene Adresse gesendet. Bitte bestätige deine Registrierung. <br />
                <ion-button class="ion-margin-top" expand="block" (click)="pressNoEmailReceivedButton()">
                  Keine Email erhalten?
                </ion-button>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
