<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
  *ngIf="areUserCourseRegistrationsLoading === true"></ion-img>

<div (click)="cancel($event)" class="modal-div">
  <ion-card>
    <ion-row>
      <ion-col size="10">
        <ion-card-header>
          <h4>{{ courseTemplate?.name }}</h4>
        </ion-card-header>
      </ion-col>
      <ion-col size="2" class="margin-padding-0">
        <div class="ion-text-end">
          <ion-button class="fav-button" (click)="toggleCourseFavoriteButtonClicked(courseTemplate?.id)"
            [disabled]="isLikingDisabled(courseTemplate?.id)">
            <i *ngIf="courseHelper.isFavoriteCourse(courseTemplate?.id, selectedCourseTemplateIds) === false"
              class="button-icon icon-bookmark-2-outline icon-color-white" aria-label="lesezeichen setzen"></i>
            <i *ngIf="courseHelper.isFavoriteCourse(courseTemplate?.id, selectedCourseTemplateIds) === true"
              class="button-icon icon-bookmark-2 icon-color-white" aria-label="lesezeichen entfernen"></i>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
    <app-four-star-rating [courseTemplate]="courseTemplate"
      [userCourseRegistrations]="userCourseRegistrations" [user]="user"></app-four-star-rating>
    <ion-row *ngFor="let courseItem of courses">
      <ion-col>
        <app-course-information [course]="courseItem" [userCourseRegistrations]="userCourseRegistrations" [user]="user"
          [isLoading]="areSelectedCoursesLoading || areUserCourseRegistrationsLoading"
          (registerForTemplateEvent)="setTemplateToCannotRegister($event)"
          (isLoggedInEvent)="isLoggedIn"></app-course-information>
      </ion-col>
    </ion-row>
    <div class="ion-float-right ion-margin-bottom ion-margin-end">
      <ion-buttons>
        <ion-button (click)="cancel($event)"><strong (click)="cancel($event)">Schließen</strong></ion-button>
      </ion-buttons>
    </div>
  </ion-card>
</div>
