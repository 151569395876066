export class ChatBotMessage {
  public text: string;
  public messageType: MessageType;
}

export enum MessageType {
  BotMessage = 'Chat',
  SystemMessage = 'Meldungen',
  UserMessage = 'Nutzernachricht',
}
