import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestStatus } from 'app/models/request-status.model';
import { UserCourseTemplateRating } from 'app/models/user-course-institute-template-rating.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveUserCourseTemplateRatingService {
  constructor(private http: HttpClient) {}

  public saveUserCourseTemplateRating(
    userCourseInstituteRatingService: UserCourseTemplateRating,
    user: User,
  ): Observable<RequestStatus> {
    const options = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${environment.authenticationToken}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'User-Token': user.token,
      }),
    };

    const body = JSON.stringify(userCourseInstituteRatingService);
    const returnObservable = this.http.post<RequestStatus>(
      environment.apiUrlUserCourseTemplateRating.replace('{{user_id}}', user.id.toString()),
      body,
      options,
    );

    return returnObservable;
  }
}
