import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SelectionComponent } from 'app/components/layout/selection/selection.component';
import { Institute } from 'app/models/institute.model';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { QualificationType } from 'app/models/qualification-type.model';
import { Qualification } from 'app/models/qualification.model';

@Injectable()
export class SelectionHelper {
  constructor(private modalCtrl: ModalController) {}

  public async openSelectionModal(
    selectedOption: string,
    options: Qualification[] | QualificationCategory[] | QualificationType[] | Institute[],
    title?: string,
  ): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: SelectionComponent,
      cssClass: 'small-modal',
      componentProps: {
        selectedOption,
        options,
        title,
      },
    });
    modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }

  public getRegexExcludeWord(excludedWord: string): RegExp {
    return new RegExp('^((?!' + excludedWord + ').)', 'i');
  }
}
