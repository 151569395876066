import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SelectionHelper } from 'app/helpers/selection-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { VERIFICATION_STATUS } from 'app/models/constants-app-values';
import { STANDARD_ERROR_TEXT, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { Institute } from 'app/models/institute.model';
import { PreviewImage } from 'app/models/preview-image.model';
import { User } from 'app/models/user.model';
import { InstituteService } from 'app/services/institute.service';
import { UserFileUploadService } from 'app/services/user-file-upload.service';
import { ConfirmationModalComponent } from '../layout/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-user-file',
  templateUrl: './user-file.component.html',
  styleUrls: ['./user-file.component.scss'],
})
export class UserFileComponent implements OnInit {
  @Input() base64Image: string;
  @Input() previewImage: PreviewImage;
  @Input() user: User;

  public readonly standardErrorText = STANDARD_ERROR_TEXT;
  public isPhotoEditLoading = false;
  public areInstitutesLoading = false;
  public verificationStatus = VERIFICATION_STATUS;
  public hasRequestFailed = false;
  public isInEditMode = false;
  public nameInput = '';

  private institutes: Institute[] = [];

  constructor(
    private modalCtrl: ModalController,
    private userFileUploadService: UserFileUploadService,
    private selectionHelper: SelectionHelper,
    private router: Router,
    private userHelper: UserHelper,
    private instituteService: InstituteService,
  ) {}

  ngOnInit() {
    this.loadInstitutes();
    this.nameInput = this.previewImage?.name;
  }

  public cancel(event: MouseEvent): Promise<boolean> {
    if (event.target === event.currentTarget) {
      return this.modalCtrl.dismiss(null, 'cancel');
    }
  }

  public async undoSendButtonClick(): Promise<void> {
    this.openConfirmationModalVerificationDeletion();
  }

  public async deleteImageButtonClick(previewImage: PreviewImage): Promise<void> {
    const modalResult = await this.openConfirmationModalImageDeletion(previewImage);
    if (modalResult === 'delete') {
      this.modalCtrl.dismiss(previewImage, modalResult, 'ImageModal');
    }
  }

  public requestVerificationClick(previewImage: PreviewImage): void {
    this.openModalInstitutes(previewImage);
  }

  public updateFileButtonClick(): void {
    this.updateFile(this.nameInput);
  }

  private async openConfirmationModalImageDeletion(previewImage: PreviewImage): Promise<string> {
    const modal = await this.modalCtrl.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-modal',
      componentProps: {
        enableBackdropDismiss: true,
        action: 'löschen',
        objectToRemove: 'das Dokument',
        locationWhereObjectIsToBeRemoved: 'deinen Dokumenten',
      },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }

    if (role === 'confirm') {
      return 'delete';
    }
  }

  private async openConfirmationModalVerificationDeletion(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-modal',
      componentProps: {
        enableBackdropDismiss: true,
        action: 'löschen',
        objectToRemove: 'die Verifizierungsanfrage',
        locationWhereObjectIsToBeRemoved: 'diesem Dokument',
      },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      return;
    }

    if (role === 'confirm') {
      this.isPhotoEditLoading = true;
      const result = await this.userFileUploadService.deleteFileVerification(this.user, this.previewImage);
      this.isPhotoEditLoading = false;
      if (result === SYSTEM_ERROR_CODE) {
        throw Error(this.userFileUploadService.errorMessage);
      }
      this.previewImage.verificationStatus = this.verificationStatus.none;
    }
  }

  private async openModalInstitutes(previewImage: PreviewImage): Promise<void> {
    const instituteId = await this.selectionHelper.openSelectionModal(
      null,
      this.institutes,
      'Wähle das Institut aus, welches dein Dokument überprüfen soll:',
    );

    if (instituteId !== null) {
      previewImage.selectedInstitute = this.institutes.find((x) => x.id === parseInt(instituteId, 10));
      this.requestVerification(previewImage);
      return;
    }
    previewImage.selectedInstitute = null;
  }

  private async requestVerification(previewImage: PreviewImage): Promise<void> {
    this.isPhotoEditLoading = true;

    const verificationResult = await this.userFileUploadService.requestFileVerification(this.user, previewImage);
    if (verificationResult === 401) {
      this.isPhotoEditLoading = false;
      this.userHelper.logoutUser();
      return;
    }
    if (verificationResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isPhotoEditLoading = false;
      throw Error(this.userFileUploadService.errorMessage);
    }

    if (verificationResult === true) {
      this.previewImage.verificationStatus = this.verificationStatus.pending;
      this.previewImage.verificationInstitute = new Institute();
      this.previewImage.verificationInstitute.id = this.previewImage.selectedInstitute.id;
      this.previewImage.verificationInstitute.name = this.previewImage.selectedInstitute.name;
    }
    this.isPhotoEditLoading = false;
  }

  private async loadInstitutes(): Promise<void> {
    this.areInstitutesLoading = true;

    const institutesResult = await this.instituteService.getInstitutes();
    if (institutesResult === 401) {
      this.areInstitutesLoading = false;
      this.userHelper.logoutUser();
      return;
    }
    if (institutesResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.areInstitutesLoading = false;
      throw Error(this.instituteService.errorMessage);
    }
    this.institutes = institutesResult as Institute[];

    this.areInstitutesLoading = false;
  }

  private async updateFile(newName: string): Promise<void> {
    this.isPhotoEditLoading = true;
    const updatedPreviewImage = structuredClone(this.previewImage);
    updatedPreviewImage.name = newName;
    const updateResult = await this.userFileUploadService.updateFile(this.user, updatedPreviewImage);

    if (updateResult === 401) {
      this.isPhotoEditLoading = false;
      this.userHelper.logoutUser();
      return;
    }
    if (updateResult === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.isPhotoEditLoading = false;
      throw Error(this.userFileUploadService.errorMessage);
    }

    this.previewImage.name = newName;
    this.hasRequestFailed = false;
    this.isPhotoEditLoading = false;
  }
}
