import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { RequestStatus } from 'app/models/request-status.model';
import { UserLoginParameter } from 'app/models/user-login-parameter.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class LoginUserService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async loginUser(userLoginParameter: UserLoginParameter): Promise<User | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(userLoginParameter);
      const returnObservable = await this.http.post<RequestStatus>(environment.apiUrlUserLogin, body, options);

      const requestStatus: RequestStatus = await lastValueFrom(returnObservable);

      const user: User = requestStatus.data[0];

      return user;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
