<ion-card>
  <div class="stretcher-div"></div>
  <ion-card-header class="ion-text-center">
    <h2>Karrierevorschläge</h2>
  </ion-card-header>
  <ion-card-content class="ion-text-start">
    <ion-row>
      <ion-col>
        Finde anhand deines aktuellen Bildungsabschlusses den passenden Karrierepfad für dich!
      </ion-col>
    </ion-row>
  </ion-card-content>
  <ion-card-content>
    <form [formGroup]="formGroup">
      <ion-row class="country-wide-row">
        <ion-col size="11" class="ion-text-end" size-md="5">
          <ion-label class="highlighted-bold-text" (click)="toggleIsCountryWide()">
            Deutschlandweite Suche
          </ion-label>
        </ion-col>
        <ion-col size="1" class="display-flex justify-content-end">
          <ion-checkbox class="highlighted-checkbox" (ionChange)="changeIsCountryWide($any($event.target).checked)"
            labelPlacement="start" formControlName="isCountryWide"></ion-checkbox>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="7" size-md="4">
          <ion-input id="zip-input-browse" class="form-field" placeholder="PLZ" formControlName="zipCodeInput"
            (ionChange)="changeSelectedZip($any($event.target).value)">
          </ion-input>
        </ion-col>
        <ion-col size="5" size-md="2">
          <ion-select interface="action-sheet" label-placement="floating" class="form-field" placeholder="Umkreis"
            formControlName="distanceSelect" (ionChange)="changeSelectedDistance($any($event.target).value)">
            <ion-select-option *ngFor="let distanceValueItem of distanceValues" [value]="distanceValueItem">
              {{distanceValueItem}}km</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col class="display-grid">
          <ion-select interface="action-sheet" label="{{requirementQualificationTypeText}}*" label-placement="floating"
            class="form-field ion-text-start" formControlName="qualificationTypeSelect"
            (ionChange)="changeSelectedQualificationType($any($event.target).value)">
            <ion-select-option *ngFor="let qualificationTypeItem of qualificationTypes" [value]="qualificationTypeItem">
              {{qualificationTypeItem.name}}
            </ion-select-option>
          </ion-select>
          <ion-icon *ngIf="selectedRequirementQualificationType !== null" name="close" class="select-removal-icon"
            (click)="changeSelectedQualificationType(null)"></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="display-grid" size="12" size-md="6">
          <ion-select interface="action-sheet" label="{{requirementQualificationCategoryText}}"
            label-placement="floating" class="form-field ion-text-start"
            formControlName="requirementQualificationCategorySelect"
            (ionChange)="changeSelectedRequirementQualificationCategory($any($event.target).value)">
            <ion-select-option *ngFor="let qualificationCategoryItem of qualificationCategories"
              [value]="qualificationCategoryItem">
              {{qualificationCategoryItem.name}}
            </ion-select-option>
          </ion-select>
          <ion-icon *ngIf="selectedRequirementQualificationCategory !== null" name="close" class="select-removal-icon"
            (click)="changeSelectedRequirementQualificationCategory(null)"></ion-icon>
        </ion-col>
        <ion-col class="display-grid" size="12" size-md="6">
          <ion-select interface="action-sheet" label="{{targetQualificationCategoryText}}" label-placement="floating"
            class="form-field ion-text-start" formControlName="targetQualificationCategorySelect"
            (ionChange)="changeSelectedTargetQualificationCategory($any($event.target).value)">
            <ion-select-option *ngFor="let qualificationCategoryItem of qualificationCategories"
              [value]="qualificationCategoryItem">
              {{qualificationCategoryItem.name}}
            </ion-select-option>
          </ion-select>
          <ion-icon *ngIf="selectedTargetQualificationCategory !== null" name="close" class="select-removal-icon"
            (click)="changeSelectedTargetQualificationCategory(null)"></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row class="hide-user-agreement-{{isLoggedIn}}">
        <ion-col class="ion-align-items-center display-flex">
          <ion-label class="highlighted-text ion-margin-end ion-margin-start"
            [innerHTML]="sanitizer.bypassSecurityTrustHtml(userAgreementQuestion)">
          </ion-label>
          <ion-checkbox [value]="isLoggedIn" class="highlighted-checkbox ion-margin-end" formControlName="userAgreementCheckbox"
            (ionChange)="changeIsUserAgreementAgreed($any($event.target).checked)">
          </ion-checkbox>
        </ion-col>
      </ion-row>

      <ion-row class="ion-margin-top">
        <ion-col size="4" offset="8">
            <ion-button class="search-button" expand="block" (click)="searchCourseTemplateTrees()"
              [disabled]="formGroup.valid === false || areTreesLoading === true">
              <img class="button-icon icon-magnifying-glass-outline icon-color-white" alt="Suche starten">
            </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="searchFailed === true">
        <ion-col class="error-text">
          {{standardErrorText}}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-text class="color-magenta">*Pflichtfeld</ion-text>
        </ion-col>
      </ion-row>
    </form>
  </ion-card-content>
</ion-card>
