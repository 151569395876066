import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE, WATCH_LIST_LIMIT_ERROR_CODE } from 'app/models/constants-status';
import { RequestStatus } from 'app/models/request-status.model';
import { StorableCourseTemplateTreeBranch } from 'app/models/storable-course-template-tree-branch.model';
import { StoredCourseTemplateTreeBranch } from 'app/models/stored-course-template-tree-branch.model';
import { Template } from 'app/models/template.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class UserWatchlistService {
  public errorMessage = '';

  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async loadFavoritedCourseTemplateTreeBranches(user: User): Promise<StoredCourseTemplateTreeBranch[] | number> {
    try {
      if (user === null) {
        return [];
      }
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const favoritedCourseTemplateTreeBranchesObservable = this.http.get<RequestStatus>(
        environment.apiUrlUserFavoritedCourseTemplateTrees.replace('{{user_id}}', user.id.toString()),
        options,
      );
      let favoritedCourseTemplateTreeBranches: StoredCourseTemplateTreeBranch[] = [];

      const requestStatus = await lastValueFrom(favoritedCourseTemplateTreeBranchesObservable);

      favoritedCourseTemplateTreeBranches = requestStatus.data;

      return favoritedCourseTemplateTreeBranches;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async loadStoredCourseTemplateTreeBranches(user: User): Promise<StoredCourseTemplateTreeBranch[] | number> {
    try {
      if (user === null) {
        return [];
      }
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const storedCourseTemplateTreeBranchesObservable = this.http.get<RequestStatus>(
        environment.apiUrlUserStoredCourseTemplateTrees.replace('{{user_id}}', user.id.toString()),
        options,
      );
      let storedCourseTemplateTreeBranches: StoredCourseTemplateTreeBranch[] = [];

      const requestStatus = await lastValueFrom(storedCourseTemplateTreeBranchesObservable);

      storedCourseTemplateTreeBranches = requestStatus.data;

      return storedCourseTemplateTreeBranches;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async storeCourseTemplateTreeBranch(
    user: User,
    storedCourseTemplateTreeBranches: StorableCourseTemplateTreeBranch[],
  ): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const body = '{"courseTemplateTrees": ' + JSON.stringify(storedCourseTemplateTreeBranches) + '}';
      const responseObservable = this.http.post<RequestStatus>(
        environment.apiUrlUserStoredCourseTemplateTrees.replace('{{user_id}}', user.id.toString()),
        body,
        options,
      );
      const response = await lastValueFrom(responseObservable);

      if (response.errorCode === WATCH_LIST_LIMIT_ERROR_CODE) {
        return false;
      }

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async deleteStoredCourseTemplateTreeBranch(
    user: User,
    courseTreeId: number,
    typeId: number,
  ): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const responseObservable = this.http.delete(
        environment.apiUrlUserStoredCourseTemplateTrees.replace('{{user_id}}', user.id.toString()) +
          '/' +
          courseTreeId +
          '/' +
          typeId,
        options,
      );

      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async storeCourse(user: User, courseId: number): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const responseObservable = this.http.put(
        environment.apiUrlUserStoredCourses.replace('{{user_id}}', user.id.toString()) + '/' + courseId,
        null,
        options,
      );

      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async loadStoredCourses(user: User): Promise<Template[] | number> {
    try {
      if (user === null) {
        return [];
      }
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const storedCoursesObservable = this.http.get<RequestStatus>(
        environment.apiUrlUserStoredCourses.replace('{{user_id}}', user.id.toString()),
        options,
      );
      let storedCourseTemplateIds: Template[] = [];

      const requestStatus = await lastValueFrom(storedCoursesObservable);

      storedCourseTemplateIds = requestStatus.data;

      return storedCourseTemplateIds;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async deleteStoredCourse(user: User, courseId: number): Promise<boolean | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const responseObservable = this.http.delete(
        environment.apiUrlUserStoredCourses.replace('{{user_id}}', user.id.toString()) + '/' + courseId,
        options,
      );

      await lastValueFrom(responseObservable);

      return true;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
