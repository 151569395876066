<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
  *ngIf="isLoading === true"></ion-img>
<ion-card *ngIf="courses.length === 0">
  <div class="stretcher-div"></div>
  <ion-card-content class="error-text">
    <ng-container *ngIf="hasSearched === true">
      Es konnten leider keine Kurse ermittelt werden.
    </ng-container>
    <ng-container *ngIf="searchFailed === true">
      Bei der Suche ist etwas schiefgelaufen. Bitte überprüfe die
      eingegebenen Werte oder versuche es später
      erneut.
    </ng-container>
  </ion-card-content>
</ion-card>
<ion-card
  *ngFor="let courseTemplateItem of courseTemplates">
  <div class="stretcher-div"></div>
  <ion-row>
    <ion-col size="10">
      <ion-card-header>
        <h4>{{ courseTemplateItem.name }}</h4>
      </ion-card-header>
    </ion-col>
    <ion-col class="margin-padding-0" size="2">
      <div class="ion-text-end">
        <ion-button class="fav-button" (click)="toggleCourseFavoriteButtonClicked(courseTemplateItem.id)"
          [disabled]="isLikingDisabled(courseTemplateItem.id)">
          <i *ngIf="courseHelper.isFavoriteCourse(courseTemplateItem.id, selectedCourseTemplateIds) === false"
            class="button-icon icon-bookmark-2-outline icon-color-white" aria-label="lesezeichen setzen"></i>
          <i *ngIf="courseHelper.isFavoriteCourse(courseTemplateItem.id, selectedCourseTemplateIds) === true"
            class="button-icon icon-bookmark-2 icon-color-white" aria-label="lesezeichen entfernen"></i>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
  <app-four-star-rating [courseTemplate]="courseTemplateItem"
    [userCourseRegistrations]="userCourseRegistrations" [user]="user"></app-four-star-rating>
  <ion-row *ngFor="let courseItem of courseTemplateItem.courses">
    <ion-col>
      <app-course-information [course]="courseItem" [userCourseRegistrations]="userCourseRegistrations" [user]="user"
        [isLoading]="isLoading" (isLoggedInEvent)="isLoggedIn"
        (registerForTemplateEvent)="setTemplateToCannotRegister($event)">
      </app-course-information>
    </ion-col>
  </ion-row>
</ion-card>
