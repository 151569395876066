import { Injectable } from '@angular/core';

@Injectable()
export class JsonHelper {
  public isStringJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
