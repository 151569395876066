// error codes
export const STANDARD_ERROR_CODE = 1001;
export const USER_DATA_INPUT_ERROR_CODE = 1002;
export const SUCCESS_ERROR_CODE = 1003;
export const NEUTRAL_ERROR_CODE = 1004;
export const SYSTEM_ERROR_CODE = 5000;
export const USER_DOESNT_EXIST_CODE = 100;
export const PASSWORD_IS_WRONG_CODE = 101;
export const WATCH_LIST_LIMIT_ERROR_CODE = 104;

// error texts
export const STANDARD_ERROR_TEXT = 'Ein interner Fehler ist aufgetreten. Bitte versuche es später erneut.';
export const USER_DATA_LOGIN_INPUT_ERROR_TEXT = 'Fehlerhafte Nutzerloginparameter';
export const USER_DOESNT_EXIST_TEXT = 'Die angegebene Email Adresse existiert nicht';
export const PASSWORD_IS_WRONG_TEXT = 'Das angegebene Passwort ist falsch';
export const CHUNK_LOADING_FAILED_ERROR = /Loading chunk [\d]+ failed/;
export const INVALID_CAREER_PATH_TEXT = 'Karrierepfad nicht mehr verfügbar';
