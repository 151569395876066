export class CourseTemplateTreeBranch {
  public depth: number;
  public id: number;
  public name: string;
  public nextBranch: CourseTemplateTreeBranch;
  public templateIds: number[];
  public startingPosition: string;
  // identifies a stored branch in the database
  public storageId: number;
}
