import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-watch-list-page',
  templateUrl: './watch-list.page.html',
  styleUrls: ['./watch-list.page.scss'],
})
export class WatchListPage implements OnInit {
  public isActiveTab = false;

  constructor() {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.isActiveTab = true;
  }

  ionViewWillLeave() {
    this.isActiveTab = false;
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }
}
