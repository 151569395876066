import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Course } from '../models/course.model';
import { CourseParameter } from '../models/course_parameter.model';
import { RequestStatus } from './../models/request-status.model';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetCoursesService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}
  public async searchCourses(courseParameter: CourseParameter): Promise<Course[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(courseParameter);
      const courseObservable = this.http.post<RequestStatus>(environment.apiUrlCourses, body, options);
      const courses: Course[] = [];

      const requestStatus = await lastValueFrom(courseObservable);

      requestStatus.data.forEach((course) => {
        if (course.description === null) {
          course.description = '';
        }
        courses.push(course);
      });

      return courses;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
