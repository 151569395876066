import { Address } from './address.model';
import { CourseTemplate } from './course-template';
import { Fundings } from './fundings.model';
import { Institute } from './institute.model';
import { LearnMethod } from './learn-method';
import { Personnel } from './personnel.model';
import { QualificationCategory } from './qualification-category.model';

export class Course {
  public id: number;
  public institute: Institute;
  public name: string;
  public internalNumber: string;
  public occupancyStatus: number;
  public formattedPrice?: string;
  public priceDescription: string;
  public url: string;
  public lastRegistrationDate: string;
  public startAt: string;
  public endAt: string;
  public formattedRange: string;
  public description: string;
  public learnMethod: LearnMethod;
  public courseTemplate: CourseTemplate;
  public targetQualificationCategory: QualificationCategory;
  public address?: Address;
  public personnel: Personnel;
  public fundings: Fundings[];
  public isRegistered?: boolean;
  public registrationTypeId?: number;
}
