<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-row class="base-content-top-margin">
        <ion-col size-md="8" offset-md="2">
          <div class="center-element">
            <ion-card>
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Nutzungsbedingungen der HUBGrade-App</h2>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col>
                    <p>Anbieter der HUBGrade-App ist der ZWH e. V. (nachfolgend „Anbieter“ genannt).
                      Die nachfolgenden Regelungen legen den Rechtsrahmen für die Nutzung unserer App fest (nachfolgend
                      „Nutzungsbedingungen“ genannt). Bitte lesen Sie diese Nutzungsbedingungen deshalb aufmerksam
                      durch.
                    </p>
                    <h3><strong>Präambel</strong></h3>
                    <p>Das Angebot „HUBGrade“ unterstützt bundesweit bei der zielgerichteten Suche nach Aus- und
                      Weiterbildungen im Handwerk. Nutzergruppen des Angebotes sind Schüler*innen, Handwerker*innen sowie
                      Anbieter von Bildungsangeboten, insbesondere Kammern und Bildungsorganisationen, im Folgenden
                      „Bildungsinstitute“ genannt, denen die HUBGrade-Website, -App und ein Verwaltungsprogramm für
                      Partner zur Verfügung stehen. <br />
                      Ein besonderer Mehrwert des Angebotes liegt in der nie zuvor dagewesenen Transparenz bei der Suche
                      nach individuellen Weiterbildungsmöglichkeiten: Die Vielzahl aktueller Bildungsangebote der
                      Handwerkskammern, Kompetenzzentren und Bildungsinstitute wird in einem einzigen Portal gebündelt
                      und steht Interessierten direkt buchbar zur Verfügung. <br />
                      Das Recommender-System der HUBGrade-App empfiehlt persönliche Karrieremöglichkeiten sowie konkrete
                      Bildungsangebote unter Berücksichtigung individueller Voraussetzungen und des Bildungsniveaus
                      des*der Nutzenden. <br />
                      Nutzende der App können sich somit zeit- und ortsunabhängig digital über ihre kompletten
                      Karrieremöglichkeiten informieren, die grafisch in Form von Karrierewegen dargestellt werden.
                      Schritt für Schritt werden hierbei die jeweils nächsthöheren Bildungsmöglichkeiten angezeigt, die
                      zusätzlich im eigenen Portfolio speicherbar sind. <br />
                    </p>
                    <h3>
                      <strong>§ 1 <br />
                        Geltungsbereich, Begriffsbestimmungen
                      </strong>
                    </h3>
                    <p>Für die Nutzung der HUBGrade-App (nachfolgend „App“ genannt) gelten ausschließlich die
                      nachfolgenden Nutzungsbedingungen in der jeweils gültigen Fassung.
                      Abweichende Bedingungen des*der Nutzenden werden nicht anerkannt, es sei denn, der Anbieter stimmt
                      ihrer Geltung schriftlich zu.
                    </p>
                    <h3>
                      <strong>§ 2<br />
                        Anmeldung, Registrierung und Nutzerkonto
                      </strong>
                    </h3>
                    <ol>
                      <li>
                        Die Nutzung der App ist ohne Anmeldung und Registrierung möglich. Möchte der*die Nutzende
                        den vorgeschlagenen Bildungsweg speichern oder die entsprechenden Fortbildungen bei den
                        anbietenden Bildungsinstituten anfragen bzw. buchen, muss er*sie sich ein Nutzerkonto anlegen.
                        Für die notwendige Registrierung muss er*sie Zugangsdaten angeben und sein*ihr Einverständnis
                        mit diesen Nutzungsbedingungen bestätigen.
                      </li>
                      <li>
                        Nach erfolgreicher Registrierung erhält der*die Nutzende eine E-Mail mit einem Anmeldelink.
                        Nach Aufruf des Links gelangt er*sie ins "Zentrales Log-in-System" und muss ein Passwort vergeben.
                      </li>
                      <li>
                        Nutzende können ein auf der Plattform befindliches Weiterbildungsangebot buchen, indem sie
                        beim entsprechenden Kurs ein Formular mit den von dem Bildungsinstitut benötigten Daten
                        ausfüllen und abschicken. Anschließend erhalten sie Rückmeldung direkt vom Bildungsinstitut.
                      </li>
                    </ol>
                    <h3>
                      <strong>§ 3<br />
                        Abmeldung/Deaktivierung des Nutzerkontos
                      </strong>
                    </h3>
                    <ol>
                      <li>
                        Das Löschen des Kontos kann von den Nutzenden im "Zentrales Log-in-System" jederzeit selbstständig
                        durchgeführt werden.
                      </li>
                      <li>
                        Das Konto des*der Nutzenden wird sofort deaktiviert und innerhalb von 30 Tagen endgültig aus der
                        Datenbank von HUBGrade gelöscht.
                      </li>
                    </ol>
                    <h3>
                      <strong>§ 4<br />
                        Pflichten der Nutzenden
                      </strong>
                    </h3>
                    <ol>
                      <li>
                        Die Nutzenden haben selbst dafür zu sorgen, dass sie über die technischen Voraussetzungen für
                        den Zugang zur und die Nutzung der App verfügen. Darüber hinaus müssen die Nutzenden die
                        Verwendung des für die jeweilige Sitzung erforderlichen Local Storage (Lokaler Speicher) und
                        Session Storage (Sitzungsspeicher) akzeptieren. Diese sind kleine Textdateien, die auf dem
                        Endgerät abgelegt werden. Der Session Storage leert sich nach jeder Sitzung (Schließung des
                        Browserfensters) und der Local Storage leert sich, sobald die Nutzenden ihren Browser-Cache
                        leeren. Andernfalls können die Funktionen der App ggf. nicht vollumfänglich genutzt werden.
                      </li>
                      <li>
                        Die Nutzenden müssen aktuelle Antiviren- und Antimalwaresoftware verwenden, um eine mögliche
                        Infizierung der App zu vermeiden. Sie verpflichten sich, die gängigen Sicherheitseinstellungen
                        des Browsers zu nutzen.
                      </li>
                      <li>
                        Darüber hinaus ist es den Nutzenden untersagt, Inhalte jeglicher Art zu verwenden und auf der
                        Plattform einzustellen, die gegen Rechte Dritter, d. h. insbesondere gegen Bestimmungen des
                        Straf- oder Ordnungswidrigkeitsrechts, sowie gegen Urheber-, Marken- oder Wettbewerbsrechte
                        verstoßen und/oder die einen beleidigenden, rassistischen, diskriminierenden, volksverhetzenden,
                        gewaltverherrlichenden, belästigenden, pornografischen oder ähnlichen Inhalt haben. Dies
                        betrifft ebenso die Kommunikation innerhalb der App.
                      </li>
                      <li>
                        Den Nutzenden ist es untersagt, Maßnahmen zu ergreifen, die geeignet sind, den Betrieb der App
                        einzuschränken oder zu unterbrechen, insbesondere sind die Umgehung von Sicherheitsmaßnahmen,
                        die Verbreitung von Viren oder anderer Schadsoftware oder das automatisierte Abfangen von Daten
                        untersagt.
                      </li>
                      <li>
                        Den Nutzenden ist es untersagt, rechtliche Hinweise, die im Zusammenhang mit der App angezeigt
                        werden, zu entfernen, unkenntlich zu machen oder zu verändern.
                      </li>
                      <li>
                        Die Nutzenden sind verpflichtet, die persönlichen Zugangsdaten geheim zu halten und nicht an
                        Dritte weiterzugeben. Sollten die Nutzenden eine Verwendung ihrer Zugangsdaten durch Dritte
                        feststellen, sind sie verpflichtet, dies dem Anbieter umgehend an die folgende E-Mail-Adresse zu
                        melden: hubgrade&#64;zwh-support.de.
                      </li>
                    </ol>
                    <h3>
                      <strong>§ 5<br />
                        Rechte des Anbieters bei Verstößen
                      </strong>
                    </h3>
                    <ol>
                      <li>
                        Bei Zuwiderhandlungen des*der Nutzenden gegen die vorstehend genannten Pflichten ist der
                        Anbieter berechtigt, ihn*sie mit sofortiger Wirkung von der Nutzung der App auszuschließen.
                      </li>
                      <li>
                        Der Anbieter behält sich ferner vor, Schadensersatzansprüche gegen Nutzende bei Verstößen gegen
                        die Bestimmungen geltend zu machen.
                      </li>
                    </ol>
                    <h3>
                      <strong>§ 6<br />
                        Haftung
                      </strong>
                    </h3>
                    <ol>
                      <li>Der Anbieter ist nicht verantwortlich für die in der App angezeigten Bildungswege bzw. die
                        Verfügbarkeit der dort angebotenen Aus-, Fort- und Weiterbildungen. Diese beruhen auf den
                        Angaben
                        der Bildungsinstitute. Der Anbieter übernimmt für diese Angaben keine Haftung. Der Anbieter hat
                        die App sorgfältig und nach dem neuesten Stand der Technik erstellt. Dennoch kann es in
                        Ausnahmefällen zu einer falschen Zusammenstellung der für den Bildungsabschluss notwendigen
                        Aus-,
                        Fort- und Weiterbildungen kommen. Dafür übernimmt der Anbieter keine Haftung. </li>
                      <li>Ansprüche der Nutzenden auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind
                        Schadensersatzansprüche der Nutzenden aus der Verletzung des Lebens, des Körpers oder der
                        Gesundheit und aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die
                        Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen
                        Pflichtverletzung
                        des Anbieters, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche
                        Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Vertragsziels notwendig ist.
                        Bei
                        der Verletzung wesentlicher Vertragspflichten haftet der Anbieter nur für den vertragstypischen,
                        vorhersehbaren Schaden, wenn dieser fahrlässig verursacht wurde, es sei denn, es handelt sich um
                        Schadensersatzansprüche der Nutzenden aus einer Verletzung des Lebens, des Körpers oder der
                        Gesundheit.</li>
                      <li>Die Einschränkungen des Abs. 1 gelten auch zugunsten der gesetzlichen Vertreter und
                        Erfüllungsgehilfen des Anbieters, wenn Ansprüche direkt gegen diese geltend gemacht werden.</li>
                    </ol>
                    <h3>
                      <strong>§ 7<br />
                        Schlussbestimmungen
                      </strong>
                    </h3>
                    <ol>
                      <li>Es findet ausschließlich das Recht der Bundesrepublik Deutschland Anwendung.</li>
                      <li>Der Anbieter behält sich vor, für einzelne Leistungen ergänzende Bedingungen zu vereinbaren,
                        wenn hierzu ein triftiger Grund besteht und die Änderung den Nutzenden zumutbar ist. Ein
                        triftiger Grund zur Änderung besteht, sofern Umsetzungsbedarf aufgrund bestimmter gesetzlicher
                        Vorgaben oder höchstrichterlicher Rechtsprechung besteht oder eine Neu- bzw. Weiterentwicklung
                        und/oder Umstrukturierung der technologischen Basis mit grundlegenden Funktionsänderungen der
                        App erfolgt. Auf eine vom Anbieter beabsichtigte Änderung der Nutzungsbedingungen werden die
                        Nutzenden vor dem geplanten Änderungszeitpunkt in Textform hingewiesen. Die Zustimmung gilt als
                        erteilt, wenn der Widerspruch nicht bis zum geplanten Änderungszeitpunkt gegenüber dem Anbieter
                        angezeigt wird. Die Nutzenden werden vom Anbieter auf diese Konsequenz, d. h., dass das
                        Schweigen als Zustimmung zur Änderung gedeutet wird, bei der Übermittlung der beabsichtigten
                        Änderungen nochmals besonders hingewiesen.</li>
                    </ol>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
