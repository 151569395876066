import { AfterViewInit, Directive, Input } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Directive({
  selector: '[appScrollBottom]',
})
export class ScrollBottomDirective implements AfterViewInit {
  @Input('appScrollBottom') content: IonContent;
  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => this.scrollDown(), 500);
  }

  private scrollDown(): void {
    this.content.scrollToBottom(500);
  }
}
