<ion-row class="menu-content-margin">
  <ion-col class="center-element" size="1" offset-md="1">
      <ion-card aria-label="zum Profil" class="home-menu-card" href="#/tabs/profil">
        <ion-card-content>
          <i class="home-icon icon-person-outline icon-color-dark-blue-1" aria-label="profil-öffnen"></i>
        </ion-card-content>
      </ion-card>
      <ion-card aria-label="zur Kurssuche" class="home-menu-card" href="#/tabs/suche">
        <ion-card-content>
          <i class="home-icon icon-magnifying-glass-outline icon-color-dark-blue-1" aria-label="suche-öffnen"></i>
        </ion-card-content>
      </ion-card>
      <ion-card aria-label="zur Merkliste" class="home-menu-card" href="#/tabs/merkliste">
        <ion-card-content>
          <i class="home-icon icon-bookmark-2-outline icon-color-dark-blue-1" aria-label="merkliste-öffnen"></i>
        </ion-card-content>
      </ion-card>
      <ion-card aria-label="zur Karrieresuche" class="home-menu-card" href="#/tabs/karrierevorschlage">
        <ion-card-content>
          <i class="home-icon icon-link-website icon-color-dark-blue-1" aria-label="karriere-vorschläge-öffnen"></i>
        </ion-card-content>
      </ion-card>
  </ion-col>
</ion-row>
