export class StorableCourseTemplateTreeBranch {
  public zip: string;
  public distance: number;
  public countryWide: boolean;
  public requirementQualificationTypeId: number;
  public requirementQualificationCategoryId: number;
  public targetQualificationCategoryId: number;
  public templateIds: number[];
  public storageId?: number;
}
