import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { StorageHelper } from 'app/helpers/storage-helper';
import { FUNDING_OPTIONS, LEARN_METHODS, SEARCH_PARAMETER_TERMS } from 'app/models/constants-app-texts';
import { PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME, SEARCH_PARAMETER_KEYS } from 'app/models/constants-logical-names';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { QualificationType } from 'app/models/qualification-type.model';
import { Qualification } from 'app/models/qualification.model';
import { GetQualificationsService } from 'app/services/get-qualifications.service';
import { CourseHelper } from '../../../helpers/course-helper';
import { Course } from '../../../models/course.model';
import { CourseParameter } from '../../../models/course_parameter.model';

@Component({
  selector: 'app-search',
  templateUrl: 'search.page.html',
  styleUrls: ['search.page.scss'],
})
export class SearchPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  public isSearchLoading = false;
  public areQualificationsLoading = false;
  public isActiveTab = false;
  public searchFailed = false;
  public hasSearched = false;
  public qualificationCategories: QualificationCategory[] = [];
  public qualificationTypes: QualificationType[] = [];
  public courses: Course[] = [];
  public qualifications: Qualification[] = [];
  public chipSearchItems: { key: string; term: string; value: string }[] = [];
  public currentSearchParameter = new CourseParameter();

  private readonly learnMethod = LEARN_METHODS;
  private readonly fundingOption = FUNDING_OPTIONS;
  private hasLoadedAllCourses = false;

  constructor(
    private courseHelper: CourseHelper,
    private storageHelper: StorageHelper,
    private qualificationService: GetQualificationsService,
  ) {}

  async ngOnInit() {
    await this.getQualifications();
  }

  ionViewWillEnter() {
    this.isActiveTab = true;
  }

  ionViewWillLeave() {
    this.isActiveTab = false;
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public isDeletableChip(chipItem: { key: string; term: string; value: string }): boolean {
    const tempChipSearchItems = structuredClone(this.chipSearchItems);
    tempChipSearchItems.splice(this.chipSearchItems.indexOf(chipItem), 1);

    if (
      tempChipSearchItems.find((x) => x.key === SEARCH_PARAMETER_KEYS.distance) ||
      tempChipSearchItems.find((x) => x.key === SEARCH_PARAMETER_KEYS.fullTextSearch) ||
      tempChipSearchItems.find((x) => x.key === SEARCH_PARAMETER_KEYS.qualificationCategory)
    ) {
      return true;
    }
    return false;
  }

  public removeSearchChip(chipItem: { key: string; term: string; value: string }): void {
    if (this.isDeletableChip(chipItem) === true) {
      this.chipSearchItems.splice(this.chipSearchItems.indexOf(chipItem), 1);

      // wenn zip und plz entfernt werden, auf deutschlandweit stellen
      if (chipItem.key === SEARCH_PARAMETER_KEYS.distance) {
        this.currentSearchParameter.distance = undefined;
        this.currentSearchParameter.zip = undefined;

        this.storageHelper.setSessionStorage(
          PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME,
          JSON.stringify(this.currentSearchParameter),
        );
        this.searchCourses(this.currentSearchParameter);
        return;
      }

      this.currentSearchParameter[chipItem.key] = undefined;

      this.storageHelper.setSessionStorage(
        PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME,
        JSON.stringify(this.currentSearchParameter),
      );
      this.searchCourses(this.currentSearchParameter);
      return;
    }
  }

  public async loadMoreCourses(): Promise<void> {
    if (this.hasLoadedAllCourses === true || this.isSearchLoading === true) {
      return;
    }
    this.isSearchLoading = true;
    this.currentSearchParameter.startIndex =
      this.currentSearchParameter.startIndex + this.currentSearchParameter.maxResults;

    const courseSearchResult = await this.courseHelper.searchCourses(this.currentSearchParameter);
    if (courseSearchResult === SYSTEM_ERROR_CODE) {
      this.hasSearched = true;
      this.searchFailed = true;
      this.courses = [];
      this.isSearchLoading = false;
      throw Error(this.courseHelper.errorCollection);
    }
    this.searchFailed = false;
    if ((courseSearchResult as Course[]).length === 0) {
      this.hasLoadedAllCourses = true;
      this.isSearchLoading = false;
      return;
    }

    this.courses = this.courses.concat(courseSearchResult as Course[]);
    this.isSearchLoading = false;
  }

  public async searchCourses(selectedCourseParameter: CourseParameter): Promise<void> {
    this.hasLoadedAllCourses = false;
    this.isSearchLoading = true;

    const courseSearchResult = await this.courseHelper.searchCourses(selectedCourseParameter);
    if (courseSearchResult === SYSTEM_ERROR_CODE) {
      this.hasSearched = true;
      this.searchFailed = true;
      this.courses = [];
      this.isSearchLoading = false;
      throw Error(this.courseHelper.errorCollection);
    }
    this.searchFailed = false;
    this.courses = courseSearchResult as Course[];
    this.hasSearched = true;
    await this.fillChips();
    this.isSearchLoading = false;
  }

  private async getQualifications(): Promise<void> {
    this.areQualificationsLoading = true;

    const qualificationsResult = await this.qualificationService.getQualifications();
    if (qualificationsResult === SYSTEM_ERROR_CODE) {
      this.areQualificationsLoading = false;
      throw Error(this.qualificationService.errorMessage);
    }
    this.qualifications = qualificationsResult as Qualification[];
    this.qualificationCategories = this.qualificationService.getCategories();
    this.qualificationTypes = this.qualificationService.getTypes();

    this.areQualificationsLoading = false;
  }

  private async fillChips(): Promise<void> {
    this.chipSearchItems = [];
    this.currentSearchParameter = JSON.parse(
      this.storageHelper.getSessionStorage(PREVIOUS_COURSE_PARAMETER_LOGICAL_NAME),
    );

    if (this.currentSearchParameter === null) {
      return;
    }

    if (this.currentSearchParameter.zip !== undefined) {
      this.chipSearchItems.push({
        key: SEARCH_PARAMETER_KEYS.distance,
        term: SEARCH_PARAMETER_TERMS.distance,
        value: this.currentSearchParameter.zip + ' ' + this.currentSearchParameter.distance + 'km',
      });
    }

    if (this.currentSearchParameter.qualificationCategoryId !== undefined) {
      this.chipSearchItems.push({
        key: SEARCH_PARAMETER_KEYS.qualificationCategory,
        term: SEARCH_PARAMETER_TERMS.qualificationCategory,
        value: this.qualificationCategories.find((x) => x.id === this.currentSearchParameter.qualificationCategoryId)
          .name,
      });
    }

    if (this.currentSearchParameter.qualificationTypeId !== undefined) {
      this.chipSearchItems.push({
        key: SEARCH_PARAMETER_KEYS.qualificationType,
        term: SEARCH_PARAMETER_TERMS.qualificationType,
        value: this.qualificationTypes.find((x) => x.id === this.currentSearchParameter.qualificationTypeId).name,
      });
    }

    if (this.currentSearchParameter.fullTextSearch !== undefined) {
      this.chipSearchItems.push({
        key: SEARCH_PARAMETER_KEYS.fullTextSearch,
        term: SEARCH_PARAMETER_TERMS.fullTextSearch,
        value: this.currentSearchParameter.fullTextSearch,
      });
    }

    if (this.currentSearchParameter.learnMethodId !== undefined) {
      this.chipSearchItems.push({
        key: SEARCH_PARAMETER_KEYS.learnMethodId,
        term: SEARCH_PARAMETER_TERMS.learnMethod,
        value: this.learnMethod[this.currentSearchParameter.learnMethodId],
      });
    }

    if (this.currentSearchParameter.fundable !== undefined) {
      this.chipSearchItems.push({
        key: SEARCH_PARAMETER_KEYS.fundable,
        term: SEARCH_PARAMETER_TERMS.fundable,
        value: this.fundingOption[this.currentSearchParameter.fundable],
      });
    }
  }
}
