// static values
export const DEFAULT_COURSE_SEARCH_STARTING_POSITION = 0;
export const DEFAULT_COURSE_SEARCH_MAX_RESULTS_AMOUNT = 5;
export const DISTANCE_VALUES = [5, 10, 20, 30, 40, 50, 75, 100, 200];
export const WATCH_LIST_LIMIT = 20;
export const VERIFICATION_STATUS = {
  none: 0,
  pending: 1,
  verified: 2,
  declined: 3,
};
export const REGISTRATION_TYPE_ID = {
  noRegistration: 1,
  registrationPossible: 2,
  registrationRequestPossible: 3,
};
export const COLOR_NAMES = {
  magenta: 'magenta',
  blueDarkish: 'blue-darkish',
};
export const GENDER_VALUES: { id: number; name: string }[] = [
  { id: 1, name: 'Mann' },
  { id: 2, name: 'Frau' },
  { id: 3, name: 'Divers' },
];
export const CAREER_SEARCH_STANDARD_PARAMETER_VALUES = {
  maxResults: 100,
  startIndex: 0,
};
